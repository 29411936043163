@import "~antd/dist/antd.less";

html {
  height: 100%;
  scroll-behavior: smooth;
  // overflow-x: hidden;
}
body {
  padding: 0;
  margin: 0;
  height: 100%;
  // width: 1090px;
}

* {
  font-family: "Montserrat", sans-serif;
  // font-weight: 500;
}

@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@bg-white: #ffffff;

// .logo {
//   float: left;
//   width: 120px;
//   height: 75px;
//   // margin-top: 12px;
// }

// .ant-menu-submenu {
//   font-weight: 700;
// }

// .ant-dropdown-menu-item {
//   font-weight: 700;
//   :hover {
//     color: @brand-primary;
//   }
// }


@media screen and (max-width: 1090px) {
  // body {
  //   width: 1090px;
  // }
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;