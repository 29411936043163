@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;

.lPageFour_bg {
  display: grid;
  position: relative;
  width: 100%;
  min-height: auto;
  padding: 30px 15px;
}

.lPageFour_Heading {
  text-align: center;
  font-weight: 900;
  font-size: 36px;
  line-height: 1.4;
  margin-top: 20px;
}

.lPageFour_hBar_wrapper {
  padding: 0 15px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 10px;
  margin-top: 10px;
}

.lPageFour_Bg {
  position: absolute;
  // align-self: center;
  top: 176px;
  width: 98.7vw;
  height: 250px;
  background-color: #e6fcf3;
  // margin-top: -7px;
}

.pageFour_cardsDiv {
  max-width: 1300px;
  justify-self: center;
}

.lPageFour_card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 240px;
  height: 260px;
  padding: 6px;
  box-shadow: 0 4px 4px 2px rgba(0, 0, 0, 0.2);
  border-radius: 18px;
  border: 1px solid @brand-primary;
  transform: scale3d(1.1, 1.1, 1);
  background-color: white;
  margin: -30px 20px 0px 20px;
}

.lPageFour_card_cContent {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: -1px;
  margin-right: -1px;
  border-radius: 0px 17px 0px 0px;
  border: 18px solid @brand-primary;
  border-left: 18px solid @brand-white;
  border-bottom: 18px solid @brand-white;
  width: 0;
}

.lPageFour_card_img {
  width: 130px;
  height: 130px;
}

.lPageFour_card_heading {
  font-size: 30px;
  font-weight: 900;
  color: @brand-primary;
  margin-top: 10px;
}

.lPageFour_card_sHeading {
  font-size: 22px;
  font-weight: 700;
  color: @brand-ternary;
}

.lPageFour_hBar {
  width: 705px;
  height: 5px;
  background-color: #9fe1c6;
  margin-top: -12px;
}

.lPageFour_hEl {
  width: 50px;
  height: 5px;
  position: relative;
  background-color: @brand-primary;
  animation: lPageFour 2s infinite alternate linear;
}

@keyframes lPageFour {
  0% {
    left: 0;
  }

  25% {
    left: 163.75px;
  }

  50% {
    left: 327.5px;
  }

  75% {
    left: 491.25px;
  }

  100% {
    left: 655px;
  }
}

.lPageFour_margin_row {
  margin: 30px 0;
}

@media (min-width: 992px) and (max-width: 1075px) {
  .lPageFour_card {
    width: 220px;
    height: 240px;
  }

  .lPageFour_card_cContent {
    border-radius: 0px 12px 0px 0px;
    border: 16px solid @brand-primary;
    border-left: 16px solid @brand-white;
    border-bottom: 16px solid @brand-white;
  }

  .lPageFour_card_img {
    width: 120px;
    height: 1200px;
  }

  .lPageFour_card_heading {
    font-size: 30px;
    margin-top: 10px;
  }

  .lPageFour_card_sHeading {
    font-size: 20px;
  }
}

// @media (min-width: 768px) and (max-width: 992px) {
//   .lPageFour_Heading {
//     font-size: 33px;
//   }

//   .lPageFour_hBar {
//     width: 570px;
//   }

//   @keyframes lPageFour {
//     0% {
//       left: 0;
//     }
//     25% {
//       left: 130px;
//     }
//     50% {
//       left: 260px;
//     }
//     75% {
//       left: 390px;
//     }
//     100% {
//       left: 520px;
//     }
//   }

//   .lPageFour_card {
//     width: 180px;
//     height: 190px;
//   }
//   .lPageFour_card_cContent {
//     border-radius: 0px 12px 0px 0px;
//     border: 16px solid @brand-primary;
//     border-left: 16px solid @brand-white;
//     border-bottom: 16px solid @brand-white;
//   }
//   .lPageFour_card_img {
//     width: 100px;
//     height: 100px;
//   }
//   .lPageFour_card_heading {
//     font-size: 27px;
//   }

//   .lPageFour_card_sHeading {
//     font-size: 16px;
//   }
// }

// @media (min-width: 576px) and (max-width: 768px) {
//   .lPageFour_Heading {
//     font-size: 27px;
//     line-height: 1.3;
//   }

//   .lPageFour_hBar {
//     width: 410px;
//   }

//   @keyframes lPageFour {
//     0% {
//       left: 0;
//     }
//     25% {
//       left: 90px;
//     }
//     50% {
//       left: 180px;
//     }
//     75% {
//       left: 270px;
//     }
//     100% {
//       left: 360px;
//     }
//   }

//   .lPageFour_card {
//     width: 150px;
//     height: 150px;
//   }

//   .lPageFour_card_cContent {
//     border-radius: 0px 12px 0px 0px;
//     border: 16px solid @brand-primary;
//     border-left: 16px solid @brand-white;
//     border-bottom: 16px solid @brand-white;
//   }
//   .lPageFour_card_img {
//     width: 100px;
//     height: 100px;
//   }
//   .lPageFour_card_heading {
//     font-size: 27px;
//   }

//   .lPageFour_card_sHeading {
//     font-size: 16px;
//   }
// }

@media screen and (max-width: 576px) {
  .lPageFour_Bg {
    // background-color: white;
    height: 185px;
    top: 215px;
  }

  .lPageFour_Heading {
    font-size: 27px;
    line-height: 1.3;
    margin-top: 0px;
  }

  .lPageFour_hBar {
    width: 270px;
    height: 3px;
  }

  @keyframes lPageFour {
    0% {
      left: 0px;
    }

    25% {
      left: 55px;
    }

    50% {
      left: 110px;
    }

    75% {
      left: 165px;
    }

    100% {
      left: 220px;
    }
  }

  .pageFour_cardsDiv {
    margin-top: -15px;
  }

  .lPageFour_card {
    width: 130px;
    height: 135px;
    border-radius: 8px;
    border-width: 1px;
    margin: -8px -5px;
  }

  .lPageFour_card_cContent {
    border-radius: 0px 11px 0px 0px;
    border: 16px solid @brand-primary;
    border-left: 16px solid @brand-white;
    border-bottom: 16px solid @brand-white;
  }

  .lPageFour_card_img {
    width: 60px;
    height: 60px;
    margin-bottom: -8px;
  }

  .lPageFour_card_heading {
    font-size: 18px;
  }

  .lPageFour_card_sHeading {
    font-size: 11.5px;
  }

  .lPageFour_margin_row {
    margin: 5px 0;
  }

  .lPageFour_hBar_wrapper {
    margin-bottom: 0px;
  }

  .lPageFour_hEl {
    height: 3px;
  }
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;