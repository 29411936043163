@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;

.aboutUs {
    position: relative;
    margin-bottom: -70px;
    z-index: 8;
    background-color: white;
}

.pageTwoThreeFour {
    background-image: url("../../Assets/X-Brandiung-gradient.webp");
    background-position: center
}

@media (max-width: 576px) and (min-width: 992px) {
    .pageTwoThreeFour {
        background-image: url("../../Assets/X-Brandiung-gradient.webp");
        background-size: cover;
        // background-position:top;
        background-size: 140% 100%;
    }
}

@media screen and (max-width: 576px) {
    .pageTwoThreeFour {
        background-image: url("../../Assets/X-Brandiung-gradient.webp");
        background-size: cover;
        // background-position:top;
        background-size: 140% 100%;
    }

    .aboutUs {
        margin-bottom: -20px;
        // z-index: 1;
    }
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;