@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;
@duration1: 2s;
@delay1: 1s;

.PageOneDiv{
  margin-top: -0.3%;
}

.homePageOne_bg {
  width: 100%;
  height: calc(100vh - 130px);
  position: relative;
  padding: 50px 50px 0px;
  background-image: url("../../../Assets/Animation-Background.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.MobileAnimationClass{
  display: none;
}

.Page1_Multiplayer_Games {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  font-size: 18px;
  min-width: 275px;
  border-radius: 6px;
  height: 54px;
  border-bottom: 4px solid#c0bebe;
  color: @brand-primary;
  background-color: @brand-white;
  margin-bottom: 10%;
  margin-top: 0%;
  position: relative;
  animation-name: Multiplayer_A;
  animation-duration: @duration1;
  animation-fill-mode: forwards;
  opacity: 0;
}

.Page1_HTML_Games {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  font-size: 18px;
  min-width: 275px;
  border-radius: 6px;
  height: 54px;
  border-bottom: 4px solid#c0bebe;
  color: @brand-primary;
  background-color: @brand-white;
  margin-bottom: 10%;
  margin-top: -5%;
  position: relative;
  animation-name: HTML_A;
  animation-delay: @delay1;
  animation-duration: @duration1;
  animation-fill-mode: forwards;
  opacity: 0;
}

.Page1_Localized_Games {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  font-size: 18px;
  min-width: 275px;
  border-radius: 6px;
  height: 54px;
  border-bottom: 4px solid#00695c;
  color: @brand-white;
  background-color: @brand-quaternary;
  animation-name: Localized_A;
  animation-delay: @delay1 * 2;
  animation-fill-mode: forwards;
  animation-duration: @duration1;
  opacity: 0;
}

.pageOne_monoster {
  display: flex;
  margin-right: 210px;
  // transform: translateX(-150px);
  animation-name: Monster_A;
  animation-duration: 8s;
  animation-delay: 4s;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  transform: translate(1450px);
}

@keyframes Monster_A {
  0% {
    -webkit-transform: translate(-100px, 20px);
    .Page1_HTML_Games{
      background-color: red;
    }
  }

  5% {
    -webkit-transform: translate(100px, 320px);
  }

  20% {
    -webkit-transform: translate(100px, 320px);
  }

  25% {
    -webkit-transform: translate(500px, -10px);
  }

  40% {
    -webkit-transform: translate(650px, 150px);
  }

  60% {
    -webkit-transform: translate(650px, 150px);
  }

  65% {
    -webkit-transform: translate(800px, -10px);
  }

  75% {
    -webkit-transform: translate(1100px, 330px);
  }

  95% {
    -webkit-transform: translate(1100px, 330px);
  }

  100% {
    -webkit-transform: translate(1400px, 0px);
  }
}

@keyframes Multiplayer_A {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-1000px);
  }

  100% {
    opacity: 1;
  }
}

@keyframes HTML_A {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-500px);
  }

  100% {
    opacity: 1;
  }
}

@keyframes Localized_A {
  0% {
    opacity: 0;
    -webkit-transform: translateX(500px);
  }

  100% {
    opacity: 1;
  }
}



@media screen and (max-width: 576px) {

  .DesktopAnimationClass{
    display: none;
  }

  .MobileAnimationClass{
    display:block;
  }

  .PageOneDiv{
    margin-top: -5.8%;
  }

  .Page1_HTML_Games,
  .Page1_Localized_Games {
    // font-weight: 700;
    // font-size: 17px;
    // min-width: 250px;
    // padding: 15px;
    margin-top: 24px;
    margin-left: -10px;
  }

  .Page1_Multiplayer_Games {
    justify-content: center;
  }

  .pageOne_monoster {
    display: none;
    // margin-right: 10px;
  }
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;