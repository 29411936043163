@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;

// .pageTwo_BodyCardChild {
//   margin-bottom: 50px;
// }

.pPageTwo_Background {
  display: grid;
  padding-top: 29px;
  padding-bottom: 24px;
}

.pf_page_two_ourgames {
  font-weight: 900;
  font-size: 44px;
}

.pf_pageTwo_hBar {
  width: 365px;
  height: 5px;
  background-color: #9fe1c6;
  margin-top: -6px;
  // margin-bottom: -50px;
}

.pageTwo_BodyCard {
  max-width: 1184px;
  justify-content: center;
}

.pageTwo_HeadCardDesktop {
  max-width: 1184px;
  align-self: center;
}

.button-group {
  justify-self: center;
  justify-content: space-between;
  display: flex;
  width: 1050px;
  padding: 8px;
  // background-color: red;
  margin-top: 22px;
}

.button-group button {
  width: 120px;
  height: 38px;
  color: @brand-white;
  background-color: #21ce86;
  font-size: 21px;
  font-weight: 700;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 4px, rgba(0, 0, 0, 0.2) 0px 7px 13px -3px, rgba(0, 0, 0, 0.1) 0px -3px 0px inset;
  border-radius: 6px;
  border: @brand-white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}


.button-group button:hover,
.button-group button.selected {
  background-color: @brand-white !important;
  color: #21ce86;
  border-bottom: 3px #c0bebe;
}


.pf_pageTwo_games_conttainer {
  display: flex;
  justify-content: center;
  padding: 0 40px;
}

.pf_pageTwo_img_card_xl {
  contain: content;
  display: flex;
  justify-content: center;
  border: 2px solid #f6f6f6;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.pf_pageTwo_img_card_sm {
  contain: content;
  display: flex;
  justify-content: center;
  border: 2px solid #f6f6f6;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.pf_pageTwo_explore_more {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 800;
  font-size: 24px;
  // min-width: 250px;
  border-radius: 6px;
  // height: 54px;
  border-bottom: 4px solid#26a671;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  // box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.4);
  color: @brand-white;
  background-color: @brand-primary;
  padding: 5px 25px 2px 25px;
  // text-align: ;
  margin: 10px 15px 15px 15px;
}

.pf_page_two_hEl {
  width: 50px;
  height: 5px;
  position: relative;
  background-color: @brand-primary;
  // animation-name: pageTwo;
  // animation-duration: 1.3s;
  // animation-iteration-count: infinite;
  // animation-direction: alternate;
  animation: pageTwo 2s infinite alternate linear;
}

@keyframes pageTwo {
  0% {
    left: 0px;
  }

  100% {
    left: 310px;
  }
}

@media (max-width: 576px) and (min-width: 992px) {
  .pf_pageTwo_img_card_sm {
    max-width: 180px;
    max-height: 180px;
  }

}

@media screen and (max-width: 576px) {
  .pf_pageTwo_hBar {
    width: 230px;
    height: 3px;
    margin-bottom: 24px;
    margin-top: -10px;
  }

  .pf_page_two_hEl {
    height: 3px;
  }

  .pf_page_two_hEl {
    animation: pageTwomob 2s infinite alternate linear;
  }

  @keyframes pageTwomob {
    0% {
      left: 0px;
    }

    100% {
      left: 180px;
    }
  }

  .pf_page_two_ourgames {
    font-weight: 900;
    font-size: 28px;
  }

  .button-group_mobile {
    // background-color: #029989;
    width: 350px;
    margin-top: -12px;
    align-self: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .button-group_mobile button {
    width: calc(23.33% - 8px);
    margin-bottom: 10px;
    height: 26px;
    color: @brand-white;
    background-color: #21ce86;
    font-size: 13px;
    font-weight: 700;
    border-bottom: 3px solid #26a671;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 2px;
    border-radius: 4px;
  }
  
  .button-group button:hover,
  .button-group button.selected {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 2px !important;
    background-color: @brand-white !important;
    color: #21ce86;
    border-bottom: 3px solid #c0bebe !important;
  }

  /* Define styles for the base button */
  .btn-primary1 {
    // border: 2px solid #ff0000;
    left: 25px;
    margin-right: 25px;
  }

  .btn-primary2 {
    // border: 2px solid #007bff;
    margin-right: 8px;
  }

  .btn-primary3 {
    right: 25px;
  }

  @media screen and (max-width: 768px) {
    .button-group_mobile button {
      width: 100%;
      margin-bottom: 10px;
    }
  }


  .pf_pageTwo_explore_more {
    font-size: 17px;
    font-weight: 700;
    padding: 5px 18px 3px 18px;
    height: auto;
    margin-top: 0px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  }

  .pf_pageTwo_games_conttainer {
    padding: 0 6px;
  }

  @keyframes pageTwo {
    0% {
      left: 0px;
    }

    25% {
      left: 32.5px;
    }

    50% {
      left: 65px;
    }

    75% {
      left: 97.5px;
    }

    100% {
      left: 130px;
    }
  }
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;