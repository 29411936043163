@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;

.lPageSix_bg {
  display: grid;
  position: relative;
  width: 100%;
  min-height: auto;
  padding: 40px 30px 40px 30px;
  // background-color: #e6fcf3;
  background-image: url("../../../Assets/X-Brandiung-gradient.webp");
  background-repeat: repeat;
  // background-size: contain;
  // background-position: top;
  // background-size: 150% 250%;
  background-position: center;
}

.pageSix_questionCardDiv {
  // width: 1200px;
  // max-width: 1300px;
  // justify-self: center;
  margin-bottom: 20px;
}

.lPageSix_Heading {
  text-align: center;
  font-weight: 900;
  font-size: 36px;
}

.lPageSix_hBar_wrapper {
  padding: 0 15px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 45px;
  margin-top: 10px;
}

.lPageSix_hBar {
  width: 635px;
  height: 5px;
  background-color: #9fe1c6;
}

.lPageSix_hEl {
  width: 50px;
  height: 5px;
  position: relative;
  background-color: @brand-primary;
  animation: lPageSix 2s infinite alternate linear;
}

@keyframes lPageSix {
  0% {
    left: 0;
  }

  25% {
    left: 146.25px;
  }

  50% {
    left: 292.5px;
  }

  75% {
    left: 438.75px;
  }

  100% {
    left: 585px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .lPageSix_Heading {
    font-size: 33px;
    line-height: 1.4;
  }

  .lPageSix_hBar {
    width: 450px;
  }

  @keyframes lPageSix {
    0% {
      left: 0;
    }

    25% {
      left: 100px;
    }

    50% {
      left: 200px;
    }

    75% {
      left: 300px;
    }

    100% {
      left: 400px;
    }
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .lPageSix_Heading {
    font-size: 30px;
    line-height: 1.4;
  }

  .lPageSix_hBar {
    width: 410px;
  }

  @keyframes lPageSix {
    0% {
      left: 0;
    }

    25% {
      left: 90px;
    }

    50% {
      left: 180px;
    }

    75% {
      left: 270px;
    }

    100% {
      left: 360px;
    }
  }
}

@media screen and (max-width: 576px) {
  .pageSix_questionCardDiv {
    width: auto;
    justify-self: center;
  }
  .lPageSix_bg {
    padding: 30px 0px 0px 0px;
    background-image: none;
  }

  .lPageSix_Heading {
    font-size: 27px;
    line-height: 1.3;
  }

  .lPageSix_hBar {
    width: 290px;
    height: 3px;
  }

  .lPageSix_hEl {
    height: 3px;
  }

  .lPageSix_hBar_wrapper {
    margin-bottom: 30px;
  }

  @keyframes lPageSix {
    0% {
      left: 0px;
    }

    25% {
      left: 60px;
    }

    50% {
      left: 120px;
    }

    75% {
      left: 180px;
    }

    100% {
      left: 240px;
    }
  }
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;