@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;

.pageSix_Industries_bg {
  display: none;

}

.pageSix_Industries_bg_Desktop {
  // display: grid;
  max-width: 1300px;
  justify-self: center;
}

.aPageSix_marginTop {
  padding: 40px 0;
  background-color: #eafdf5;
  display: grid;
}

.aPageSix_card {
  width: 245px;
  height: 245px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: @brand-white;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 10px;
  // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 8px 0 rgba(0, 0, 0, 0.2),
  //   0 4px 8px 0 rgba(0, 0, 0, 0.2);
  // box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  position: relative;
  animation: aPageSix 1s ease-out;
  // margin-top: 10px;
  // margin-bottom: 10px;
  margin: 5px 20px;
}

@keyframes aPageSix {
  0% {
    transform: scale(0);
    // transform: translateX(-500%);
  }

  100% {
    transform: scale(1);
    // transform: translateX(0%);
  }
}

.aPageSix_card_content {
  font-size: 20px;
  font-weight: 800;
  // margin-top: -22px;
  // font-weight: bold;
}

.aPageSix_img {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  // background-color: #f1f1f1;
}

@media screen and (max-width: 576px) {

  .aPageSix_marginTop {
    background: none;
    padding: 0px 0px 20px;
  }

  .aPageSix_card {
    width: 150px;
    height: 140px;
    padding: 10px;
    border-radius: 4px;
    animation: aPageSix 1s ease-out;
    margin: 15px 0px;
  }

  .aPageSix_card_content {
    font-size: 12px;
    font-weight: 700;
    // margin-top: -10px;
    text-align: center;
  }

  .aPageSix_img {
    width: 90px;
    height: 90px;
  }

  .pageSix_Industries_bg_Desktop {
    // display: none;
  }

  .pageSix_Industries_bg {
    display: none;
  }

  .pageSix_Industries_card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 34px;
  }

  .pageSix_Industries_text {
    // background-color: red;
    // align-self: center;
    color: white;
    font-size: 24px;
    font-weight: 800;
    // text-align: center;
    text-shadow: 0px 2px 1px rgba(55, 0, 0, 0.31);
  }

  .pageSix_Industries_image {
    // background-color: rgb(17, 0, 255);
    // padding: 20px;
  }
  .pageSix_Industries_image_div {
    background-color: white;
    border-radius: 14px;
    // box-shadow:
    // -1px 1px 1px #7a7a7a,
    // -2px 2px 1px #7a7a7a,
    // -3px 3px 1px #7a7a7a,
    // -4px 4px 1px #7a7a7a,
    // -5px 5px 1px #7a7a7a,
    // -6px 6px 1px #7a7a7a,
    // -7px 7px 1px #7a7a7a,
    // -8px 8px 1px #7a7a7a,
    // -9px 9px 1px #7a7a7a,
    // -10px 10px 1px #7a7a7a,
    // -11px 11px 1px #7a7a7a,
    // -12px 12px 1px #7a7a7a,
    // -13px 13px 1px #7a7a7a,
    // -14px 14px 1px #7a7a7a,
    // -15px 15px 1px #7a7a7a,
    // -16px 16px 1px #7a7a7a,
    // -17px 17px 1px #7a7a7a,
    // -18px 18px 1px #7a7a7a,
    // -19px 19px 1px #7a7a7a,
    // -20px 20px 1px #7a7a7a,
    // -21px 21px 1px #7a7a7a,
    // -22px 22px 1px #7a7a7a,
    // -23px 23px 1px #7a7a7a,
    // -24px 24px 1px #7a7a7a,
    // -25px 25px 1px #7a7a7a,
    // -26px 26px 1px #7a7a7a,
    // -27px 27px 1px #7a7a7a,
    // -28px 28px 1px #7a7a7a,
    // -29px 29px 1px #7a7a7a,
    // -30px 30px 1px #7a7a7a;

    // box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    height: 100px;
  }
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;