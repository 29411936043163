@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;

.carPageOne_bg {
  // position: relative;
  display: grid;
  // justify-content: center;
  width: 100%;
  height: 500px;
  // min-height: 615px;
  padding: 15px;
  background-image: url("../../../Assets/common-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 100% 100%;
  margin-top: -0.3%;
  // background: @brand-primary;
  // border-bottom: 5px solid #009688;
}

.carPageOne_body {
  display: flex;
  justify-self: center;
  max-width: 1300px;
  // background-color: yellow;
}

.carleftSection {
  margin-right: 10px;
}

.carrightSection {
  margin-bottom: -30px;
  align-items: baseline;
  position: relative;
  left: 48px;
}

.carPageOne_Italic {
  font-style: italic;
  font-weight: 500;
  color: @brand-white;
  font-size: 27px;
  margin-left: 5px;
}

.carPageOne_title_one {
  font-weight: 900;
  color: @brand-white;
  font-size: 53px;
  line-height: 1.2;
  margin-left: 0px;
}

.crPageOne_title_one {
  font-weight: 900;
  color: @brand-white;
  font-size: 65px;
  line-height: 1.2;
  margin-left: 10px;
}

.crPageOne_title_two {
  font-weight: 900;
  color: @brand-ternary;
  font-size: 65px;
  line-height: 1.2;
  margin-top: 6px;
}

.carPageOne_title_two {
  font-weight: 900;
  color: @brand-ternary;
  font-size: 53px;
  line-height: 1.2;
  margin-top: 6px;
}

.carPageOne_title_three {
  font-weight: 400;
  color: @brand-white;
  font-size: 21.5px;
  line-height: 1.3;
  margin-top: 6px;
  margin-left: 5px;
  // text-align:justify;
  max-width: 698px;
}

.carPageOne-box {
  display: flex;
  justify-content: center;
  // justify-self: left;
  align-items: center;
  border-radius: 8px;
  width: max-content;
  padding: 14px 15px 10px;
  margin-top: 50px;
  margin-left: 10px;
  border-bottom: 2px solid#c0bebe;
  background-color: @brand-white;
  // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;
}

.carPageOne-box_text {
  font-weight: 800;
  font-size: 22px;
  margin-top: -5px;
  color: @brand-primary;
  cursor: pointer;
}

.carleftSection {
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-top: -12px;
}

// @media (min-width: 1100px) and (max-width: 1290px) {
//   .carPageOne_Italic {
//     font-size: 24px;
//   }

//   .carPageOne_title_one {
//     font-size: 36px;
//   }

//   .carPageOne_title_two {
//     font-size: 36px;
//   }

//   .carPageOne_title_three {
//     font-size: 17px;
//     width: 650px
//   }

//   .carPageOne-box {
//     width: 190px;
//     margin-top: 37px;
//   }

//   .carPageOne-box_text {
//     // font-size: 24px;
//   }

//   .carleftSection {
//     margin-left: 50px;
//   }

//   .carPageOne_bg {
//     height: 430px;
//   }
// }

@media screen and (max-width: 576px) {

  .carPageOne_bg {
    margin-top: -6%;
    height: 530px;
    background-image: url("../../../Assets/background.webp");
    // background-size: cover;
    flex-direction: column;
    justify-content: end;
  }

  .carPageOne_body {
    flex-direction: column;
    align-self: end;
    margin-bottom: 10px;
  }

  .carleftSection {
    margin-bottom: -40px;
  }

  .carrightSection {
    margin-bottom: -20px;
    width: 280px;
    align-self: center;
    margin-top: 20px;
    position: relative;
    left: 0px;
  }

  .carPageOne-box_text {
    font-size: 14px;
    font-weight: 800;
  }

  .carPageOne-box {
    justify-self: center;
    align-self: center;
    // width: 190px;
    margin-top: 12px;
    margin-bottom: 70px;
    padding: 10px 10px 4px 10px;
    border-radius: 4px;
    border-bottom: 3.5px solid #c0bebe;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 5px;
    // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    // box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;
  }

  .carPageOne_Italic {
    font-size: 19px;
    font-weight: 600;
    text-align: center;
  }

  .carPageOne_title_one {
    font-size: 34px;
    text-align: center;
    font-weight: 800;
    color: #ffffff;
  }

  .carPageOne_title_two {
    text-align: justify;
    color: @brand-white;
    font-size: 34px;
    font-weight: 800;
    color: #185563;
  }

  .carPageOne_title_three {
    margin-top: 0px;
    text-align: center;
    font-size: 14.5px;
    // width: 200px;
    // background-color: coral;s
    padding: 7px;
    line-height: 1.45;
    // justify-self: center;
    font-weight: 500
  }

  .carPageOne_img {
    max-width: 330px;
    max-height: 280px;
  }
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;