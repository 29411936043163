@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;

//////////////////////

.pageTwo_Background {
  display: grid;
  // background-color: yellow;
}

.allCardsContent {
  // max-width: 1300px;
  // background-color: red;
}

.pageTwo_AllGamesCard {
  padding: 10px;
  // background-color: #444444;
  // max-width: 1300px;
  justify-self: center;
  margin-top: 12px;
}

.pageTwo_HeadCardDesktop {
  // background-color: #222222;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  padding: 10px 10px 0px 10px;
  justify-content: space-evenly;
  // width: 100vw;
}

.pageTwo_HeadCardMobile {
  // background-color: #222222;
  display: none;
}

.pageTwo_BodyCard {
  // background-color: #999999;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  padding: 0px 10px 10px 10px;
  justify-content: space-around;
  // width: 100vw;
  max-width: 1200px;
}

.pageTwo_BodyCardChild_kids {
  background-color: @brand-white;
  // background: linear-gradient(to right, black 50%, white 50%);
  padding: 14px;
  margin: 6px 6px 12px 6px;
  border-radius: 6px;
  width: min-content;
  color: @brand-primary;
  // font-size: 16px;
  font-weight: 800;
  text-align: center;
  /* "to left" / "to right" - affects initial color */
  // background: linear-gradient(to left top, @brand-white 95%, @brand-primary 45%) right;
  background-size: 110%;
  transition: 0.1s ease-out;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.pageTwo_BodyCard_home {
  // background-color: #999999;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  padding: 0px 100px 20px 100px;
  justify-content: space-around;
  // width: 100vw;
  max-width: 1297px;
  
}
.bodyCardImage_home {
  width: 225px
}


.setHeaderFont {
  font-size: 21px;
}

.setBodyFontTenPage {
  font-size: 20px;
}

.pageTwo_BodyCardChild_kids:hover {

  background-position: left;
  transition: all 0.1s linear;
  background-color: @brand-primary;
  color: @brand-white;
}

.bodyCardImage {
  border-radius: 7px;
}

.pageTwo_GameTitle_kids {
  // color: black;
  margin-top: 10px;
}

// .pageTwo_BodyCardChild_kids {
//   width: 300px;
//   height: 300px;
//   overflow: hidden;
//   margin: 0 auto;
// }

.pageTwo_BodyCardChild_kids img {
  // width: 360;
  transition: 0.1s all ease-in-out;
}

.pageTwo_BodyCardChild_kids:hover img {
  transform: scale(1.02);
}

//////////////////////

.pageOne_xBrand_kidse {
  position: absolute;
  right: 80px;
  margin-top: -10px;
  transform: scale(0.9);
}

.pageOne_xBrand2_kidse {
  position: absolute;
  right: 0px;
  margin-top: 70px;
  transform: scale(0.7);
  // top: 650px
}

.pf_page_two_ourgames_home {
  font-weight: 900;
  font-size: 42px;
  // margin-top: 10px;
}

.pageTwo_hBar {
  margin-top: -6px;
  width: 340px;
  height: 5px;
  margin-bottom: 10px;
  background-color: #9fe1c6;
}

.pageTwo_games_conttainer {
  display: flex;
  justify-content: center;
  padding: 0 40px;
  background-color: black;
}

.pageTwo_img_card_xl {
  contain: content;
  display: flex;
  justify-content: center;
  border: 2px solid #f6f6f6;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.pageTwo_img_card_sm {
  contain: content;
  display: flex;
  justify-content: center;
  border: 2px solid #f6f6f6;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.pageTwo_explore_moreTen {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 800;
  font-size: 24px;
  min-width: 250px;
  border-radius: 6px;
  // height: 54px;
  border-bottom: 4px solid#26a671;
  // box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.4);
  box-shadow: rgba(18, 8, 8, 0.24) 0px 3px 8px;
  color: @brand-white;
  background-color: @brand-primary;
  margin-top: 4px;
  margin-bottom: 30px;
  padding-top: 6px;
  padding-bottom: 6px;
  // transform: scale(1.14);
}

.pf_pageTwo_hBar_Home {
  width: 580px;
  height: 5px;
  background-color: #9fe1c6;
  margin-top: -6px;
  // margin-bottom: -50px;
}

.pf_page_two_hEl_Home {
  width: 50px;
  height: 5px;
  position: relative;
  background-color: @brand-primary;
  // animation-name: pageTwo_InsideKidsE;
  // animation-duration: 1.3s;
  // animation-iteration-count: infinite;
  // animation-direction: alternate;
  animation: pageTwo_Home 3s infinite alternate linear;
}

@keyframes pageTwo_Home {
  0% {
    left: 0px;
  }

  100% {
    left: 540px;
  }
}


@media (max-width: 576px) and (min-width: 992px) {
  .pageTwo_img_card_sm {
    max-width: 180px;
    max-height: 180px;
  }
}

// @media screen and (max-width: 1282px) {
//   .bodyCardImage {
//     width: 350px;
//   }
// }
@media screen and (max-width: 576px) {

  .pageTwo_AllGamesCard {
    margin-top: 8px;
  }

  .pageTwo_HeadCardDesktop {
    display: none;
  }

  .bodyCardImage_home {
    width: 230px
  }
  .pageTwo_BodyCard_home {
    // background-color: #999999;
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    padding: 0px 10px 10px 10px;
    justify-content: space-around;
    // width: 100vw;
    max-width: 1297px;
    
  }

  .pageTwo_HeadCardMobile {
    // background-color: #222222;
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    padding: 10px 10px 0px 10px;
    justify-content: space-around;
    // width: 100vw;
    padding-bottom: 0px;
    margin-top: -30px;
  }

  .pageTwo_BodyCard {
    padding-top: 0px;
  }

  .pageTwo_hBar {
    width: 240px;
    margin-bottom: 54px;
    margin-top: -2px;
  }

  .page_two_ourgames {
    font-weight: 900;
    font-size: 28px;
    margin-top: 0px;
  }

  .pageTwo_explore_moreTen {
    font-size: 20px;
    // height: 0px;
    padding-top: 4px;
    padding-bottom: 4px;
    min-width: 210px;
    font-weight: 700;
    margin-top: -4px;
    margin-bottom: 16px;
  }

  .pageTwo_games_conttainer {
    padding: 0 20px;
    margin-top: -10px;
  }

  .pageOne_xBrand_kidse {
    display: none;
  }

  .pageOne_xBrand2_kidse {
    display: none;
  }

  .pageTwo_hBar {
    width: 180px;
    height: 3px;
  }

  .page_two_hEl {
    height: 3px;
  }

  

  .pf_page_two_hEl_Home {
    width: 50px;
    height: 3px;
    position: relative;
    background-color: @brand-primary;
    // animation-name: pageTwo_InsideKidsE;
    // animation-duration: 1.3s;
    // animation-iteration-count: infinite;
    // animation-direction: alternate;
    animation: pageTwo_Home 2s infinite alternate linear;

  }

  @keyframes pageTwo_Home {
    0% {
      left: 0px;
    }

    25% {
      left: 66.25px;
    }

    100% {
      left: 214px;
    }
  }

  .pf_pageTwo_hBar_Home {
    width: 264px;
    height: 3px;
    background-color: #9fe1c6;
    margin-top: -6px;
    margin-left: 4px;
    // margin-bottom: -50px;
    margin-bottom: 20px;
    
  }

  .pf_page_two_ourgames_home {
    // font-weight: 900;
    // font-size: 23px;
    // margin-top: 0px;
    width: 300px;
    font-weight: 900;
    font-size: 28px;
    line-height: 32px;
    margin-left: 32px;
    // margin-top: 0px;
  }

  .mobileview {
    width: 300px;
    font-weight: 900;
    font-size: 30px;
    line-height: 44px;
    margin-left: 74px;
  }


  .pageTwo_Background {
    width: 100%;
    // height: calc(100vh - 130px);
    position: relative;
    // padding: 50px 50px 0px;
    background-image: url('../../../Assets/X-Brandiung-gradient.webp');
    background-repeat: no-repeat;
    background-size: 200% 100%;
    // background-color: black;
    background-position: center;
  }

  @keyframes pageTwoX {
    0% {
      left: 0px;
    }

    25% {
      left: 32.5px;
    }

    50% {
      left: 65px;
    }

    75% {
      left: 97.5px;
    }

    100% {
      left: 130px;
    }
  }
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;