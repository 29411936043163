@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;

.sPageSevenMobile_Body {
  display: none;
}

.sPageSeven_bg {
  position: relative;
  width: 100%;
  min-height: auto;
  // padding: 120px 20px 15px 40px;
  background: #eafdf5;
}

.sPageSeven_Italic {
  font-style: italic;
  font-weight: 800;
  color: @brand-primary;
  font-size: 38px;
  line-height: 1.2;
}

.sPageSeven_title_one {
  font-weight: 900;
  color: @brand-secondary;
  font-size: 40px;
  line-height: 1.1;
  width: 450px;
}

.sPageSeven_title_two {
  font-weight: 600;
  font-size: 18px;
}

.sPageSeven_img {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 380px;
  left: -340px;
  height: 450px;
  z-index: 1;
}

.sPageSeven_hBar_wrapper {
  justify-content: flex-start;
  align-items: center;
  display: flex;
  margin-bottom: 10px;
  margin-top: 10px;
}

.sPageSeven_hBar {
  width: 400px;
  height: 5px;
  background-color: #9fe1c6;
}

.sPageSeven_hEl {
  width: 50px;
  height: 5px;
  position: relative;
  background-color: @brand-primary;
  animation: sPageSeven 2s infinite alternate linear;
}

@keyframes sPageSeven {
  0% {
    width: 0;
  }

  25% {
    width: 110px;
  }

  50% {
    width: 220px;
  }

  75% {
    width: 330px;
  }

  100% {
    width: 420px;
  }
}

@media screen and (max-width: 1200px) {
  .sPageSeven_bg {
    padding: 120px 15px 15px 10px;
  }

  .sPageSeven_Italic {
    font-size: 30px;
  }

  .sPageSeven_title_one {
    font-size: 36px;
  }

  .sPageSeven_title_two {
    font-size: 17px;
  }

  .sPageSeven_hBar {
    width: 360px;
  }

  @keyframes sPageSeven {
    0% {
      width: 0;
    }

    25% {
      width: 90px;
    }

    50% {
      width: 180px;
    }

    75% {
      width: 270px;
    }

    100% {
      width: 360px;
    }
  }

  .sPageSeven_img {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .sPageSeven_Italic {
    font-size: 27px;
  }

  .sPageSeven_title_one {
    font-size: 30px;
  }

  .sPageSeven_title_two {
    font-size: 16px;
  }

  .sPageSeven_hBar {
    width: 320px;
  }

  @keyframes sPageSeven {
    0% {
      width: 0;
    }

    25% {
      width: 80px;
    }

    50% {
      width: 160px;
    }

    75% {
      width: 240px;
    }

    100% {
      width: 320px;
    }
  }

  .sPageSeven_img {
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .sPageSeven_bg {
    padding: 20px 15px 15px 10px;
  }

  .sPageSeven_Italic {
    text-align: center;
    font-size: 30px;
  }

  .sPageSeven_title_one {
    text-align: center;
    font-size: 36px;
  }

  .sPageSeven_title_two {
    text-align: center;
    font-size: 17px;
  }

  .sPageSeven_hBar_wrapper {
    justify-content: center;
  }

  .sPageSeven_hBar {
    width: 360px;
  }

  @keyframes sPageSeven {
    0% {
      width: 0;
    }

    25% {
      width: 90px;
    }

    50% {
      width: 180px;
    }

    75% {
      width: 270px;
    }

    100% {
      width: 360px;
    }
  }
}

@media screen and (max-width: 576px) {
  .sPageSeven_bg {
    // display: none;
    padding: 0px 15px 20px 10px;
    margin-top: 5px;
    margin-bottom: -70px;
    position: relative;
    z-index: 8;
  }

  .sPageSevenMobile_Body {
    display: grid;
    padding: 0px 5px 20px 8px;
    margin-bottom: -20px;
    position: relative;
    z-index: 8;
    // height: 700px;
  }

  .pageSevenDesk {
    display: none;
  }

  .sPageSeven_Italic {
    text-align: center;
    font-size: 27px;
    margin-top: 30px;
  }

  .sPageSeven_title_one {
    text-align: center;
    font-size: 28px;
    width: auto;
  }

  .sPageSeven_title_two {
    text-align: center;
    font-size: 13px;
    padding: 0px 16px;
  }

  .sPageSeven_hBar_wrapper {
    justify-content: center;
  }

  .sPageSeven_hBar {
    width: 293px;
    height: 3px;
    margin-top: -10px;
  }

  .sPageSeven_hEl {
    height: 3px;
  }

  @keyframes sPageSeven {
    0% {
      width: 0;
    }

    25% {
      width: 73.25px;
    }

    50% {
      width: 146.5px;
    }

    75% {
      width: 219.75px;
    }

    100% {
      width: 293px;
    }
  }
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;