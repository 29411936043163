@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;

////////////////////////////

.footer_bg {
  display: grid;
  position: relative;
  // z-index: -1;
  width: 100%;
  min-height: 400px;
  padding: 120px 30px 30px 60px;
  background-image: url("../../Assets/Footer-Background.webp");
  background-repeat: no-repeat;
  background-size: cover;
}

////////////////////////////

.desktopFooter {
  max-width: 1300px;
}

.imageStyle {
  cursor: pointer;
}

.footer_row {
  display: flex;
  justify-content: center;
}

.footer_title {
  color: @brand-primary;
  font-size: 20px;
  font-weight: 700;
}

.footer_text_1 {
  color: @brand-white;
  margin-bottom: 10px;
  line-height: 25px;
  font-size: 17px;
  hyphens: auto;
  text-align: left;
  word-spacing: 0.0px;
  margin-left: 5px;
}

.footer_text_2 {
  color: @brand-white;
  margin-bottom: 10px;
  line-height: normal;
  font-size: 17px;
  cursor: pointer;
  margin-left: 5px;
}

.footer_text_2:hover {
  margin-left: 10px;
  transform: scale(1.1);
}

.footer_text_3 {
  color: @brand-white;
  margin-bottom: 8px;
  line-height: 24px;
  font-size: 17px;
  margin-left: 5px;
}

.footer_copyright {
  margin-top: 60px;
}

.footer_info {
  margin: 20px 0;
  margin-left: 5px;
}

///////////////////////////////

.mobileFooter {
  display: none;
}

///////////////////////////////


@media screen and (max-width: 576px) {

  .desktopFooter {
    display: none;
  }

  .mobileFooter {
    display: block;
  }

  .footer_bg {
    padding: 125px 15px 0px 15px;
    padding-bottom: 1px;
  }

  .MobileFooterSection {
    padding-bottom: 35px;
  }

  .MobileFooter_title {
    color: @brand-primary;
    font-weight: 800;
    font-size: 17px;
    margin-bottom: 3px;
    margin-top: -10px;
  }

  .MobileFooter_text_1 {
    color: @brand-white;
    font-size: 13px;
    line-height: normal;
    margin-left: 4px
  }

  .MobileFooter_text_2 {
    color: @brand-white;
    font-size: 13px;
    margin-left: 4px;
  }

  .MobileFooter_text_3 {
    color: @brand-white;
    font-size: 13px;
    line-height: 18px;
    margin-left: 4px
  }

  .MobileFooter_info {
    padding-top: 20px;
  }

  .MobileFooter_copyright {
    // background-color: red;
    text-align: center;
    margin-bottom: 30px;
    font-size: 13px;
    // font-size: small;
    // display: inline-block;
    // justify-content: center;
  }

}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;