@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;

.sPageOne_bg {
  display: grid;
  position: relative;
  // display: flex;
  // flex-direction: row;
  // justify-content: center;
  // align-items: center;
  // width: 100%;
  height: 500px;
  // height: calc(100vh - 130px);
  // padding: 0px 50px;
  background-image: url("../../../Assets/common-bg.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-top: -0.3%;
}

.aPageOne_row {
  // padding: 0px 50px;
  max-width: 1300px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-self: center;

}

.aPageOne_colOne {
  // width: 40%;
  padding-right: 75px;
}

.aPageOne_colTwo {
  display: flex;
  justify-content: center;
  padding-left: 75px;
  // width: 60%;
  margin-bottom: -60px
}

.sPageOne_Italic {
  font-style: italic;
  font-weight: 600;
  color: @brand-white;
  font-size: 29px;
  margin-top: -12px;
}

.sPageOne_col_bigsShow {
  display: block;
}

.sPageOne_col_smallsShow {
  display: none;
}

.sPageOne_title_one {
  font-weight: 900;
  color: @brand-white;
  font-size: 45px;
  line-height: 1.3;
}

.sPageOne_title_two {
  font-weight: 600;
  color: @brand-white;
  font-size: 30px;
}

.sPageOne_title_three {
  font-weight: 800;
  color: @brand-white;
  font-size: 27px;
  background-color: #168668;
  padding: 8px 10px 8px 5px;
  margin-left: 6px;
  margin-top: 10px;
}

.sPageOne-box {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  width: 280px;
  padding: 7px 5px;
  margin-top: 40px;
  border-bottom: 5px solid#c0bebe;
  background-color: @brand-white;
  box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.2), 0 4px 8px 2px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.sPageOne-box_text {
  font-weight: 800;
  font-size: 24px;
  // margin-bottom: -3.5px;
  color: @brand-primary;
}

.sPageOne_img {
  width: 600px;
  height: 550px;
  position: relative;
  margin-bottom: 10px;
}

@media (min-width: 992px) and (max-width: 1110px) {
  .sPageOne_bg {
    padding: 30px 30px 0px;
  }

  .aPageOne_row {
    justify-content: space-around;
  }

  .aPageOne_colOne {
    width: 45%;
  }

  .aPageOne_colTwo {
    width: 55%;
  }

  .sPageOne_Italic {
    font-size: 24px;
  }

  .sPageOne_title_one {
    font-size: 36px;
    line-height: 1.25;
  }

  .sPageOne_title_two {
    font-size: 24px;
  }

  .sPageOne_title_three {
    font-size: 21px;
  }

  .sPageOne-box {
    width: 250px;
    padding: 6px 5px;
    margin-top: 60px;
  }

  .sPageOne-box_text {
    font-weight: 700;
    font-size: 21px;
  }

  .sPageOne_img {
    width: 580px;
    height: 480px;
    position: relative;
    margin-bottom: -55px;
  }
}

@media screen and (max-width: 576px) {

  // .aPageOne_row {
  //   display: none;
  // }
  //url(http://localhost:3000/static/media/background.0ee04fdc8ee555036e27.webp)
  .sPageOne_bg {
    padding: 0;
    margin-top: -6%;
    background-image: url(../../../Assets/background.webp);
  }

  .aPageOne_row {
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    flex-direction: column;
    margin-top: -15px;
  }

  .aPageOne_colOne {
    padding-right: 0px;
    // width: 100%;
    // height: 55%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .aPageOne_colTwo {
    padding-left: 0px;
    // width: 100%;
    // height: 45%;
  }

  .sPageOne_col_bigsShow {
    display: none;
  }

  .sPageOne_col_smallsShow {
    display: block;
  }

  .sPageOne_Italic {
    font-size: 20px;
    font-weight: 500;
  }

  .sPageOne_title_one {
    font-size: 28px;
    line-height: 1.4;
    font-weight: 800;
  }

  .sPageOne_title_three {
    font-size: 22px;
    padding: 2px 5px;
  }

  .sPageOne-box {
    width: auto;
    padding: 6px 10px;
    margin-top: 20px;
    margin-bottom: -20px;
    border-radius: 5px;
    // border-bottom: 200px;
    border-bottom-width: 4px;
  }

  .sPageOne-box_text {
    font-weight: 800;
    font-size: 14px;
  }

  .sPageOne_img {
    max-width: 300px;
    max-height: 300px;
  }
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;