@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;

.carPageFourMobile {
  display: none;
}

.carPageFourDesktop {
  display: flex;
  flex-wrap: wrap;
  max-width: 1000px;
  justify-content: center;
  // flex-direction: row;
}

.carPageFour_bg {
  display: grid;
  justify-content: center;
  padding: 30px 0px;
  background: #eafdf5;
}

.carPage_Four_heading {
  font-weight: 900;
  font-size: 36px;
}

.carPage_Four_hBar_wrapper {
  padding: 0 15px;
  justify-content: center;
  align-items: center;
  display: flex;
  // margin-top: 10px;
  margin-bottom: 20px;
}

.carPage_Four_hBar {
  width: 560px;
  height: 5px;
  background-color: #9fe1c6;
}

.carPage_Four_hEl {
  width: 50px;
  height: 5px;
  position: relative;
  background-color: @brand-primary;
  animation: carPageSixAnimation 2s infinite alternate linear;
}


@keyframes carPageSixAnimation {
  0% {
    left: 0;
  }

  // 25% {
  //   left: 127.5px;
  // }

  // 50% {
  //   left: 255px;
  // }

  // 75% {
  //   left: 382.5px;
  // }

  100% {
    left: 510px;
  }
}

.carPageFour_card {
  width: 300px;
  height: 240px;
  // padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // background-color: #eafdf5;
  // background-color: yellow;
  border-radius: 6px;
  // box-shadow: 0 4px 8px 0 #9ae4c5, 0 4px 8px 0 #9ae4c5, 0 4px 8px 0 #9ae4c5,
  //   0 4px 8px 0 #9ae4c5;
}

.carPageFour_card_img {
  position: relative;
  height: 160px;
  width: 160px;
  justify-content: center;
  // object-fit: cover;
}

.carPageFour_card_content {
  // margin-top: 27px;
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  // width: 500px;
}

@media screen and (max-width: 576px) {

  .carPageFourDesktop {
    width: auto;
  }

  .carPageFour_bg {
    padding: 25px 0px;
  }

  .carPage_Four_heading {
    font-size: 27px;
    line-height: 1.3;
    // width: 250px;
  }

  .carPage_Four_hBar_wrapper {
    margin-bottom: 5px;
  }

  .carPage_Four_hBar {
    width: 252px;
    height: 3px;
  }

  .carPage_Four_hEl {
    width: 50px;
    height: 3px;
  }

  @keyframes carPageSixAnimation {
    0% {
      left: 0;
    }
    100% {
      left: 202px;
    }
  }

  .carPageFour_card {
    width: 160px;
    height: 130px;
    // background-color: yellow;
    margin: 5px;
    // justify-content: space-around;
  }

  .carPageFour_card_img{
    width: 90px;
    height: 90px;
    margin-top: 10px;
  }

  .carPageFour_card_content {
    // position: relative;
    font-size: 13px;
    margin-top: 5px;
    // bottom: 0px;
  }
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;