@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;

.lPageThree_bg {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 350px;
  padding: 40px 15px 50px 15px;
  background: url("../../../Assets/playable-on-BG 2.webp");
  background-repeat: no-repeat;
  background-size: cover;
}

.lPageThree_Heading {
  text-align: center;
  font-weight: 900;
  font-size: 36px;
  color: @brand-white;
}

.lPageThree_hBar_wrapper {
  padding: 0 15px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 30px;
  // margin-top: 10px;
}

.lPageThree_hBar {
  width: 610px;
  height: 5px;
  background-color: rgba(225, 225, 225, 0.25);
}

.lPageThree_card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 190px;
  border-radius: 18px;
  contain: content;
  background-color: rgba(225, 225, 225, 0.25);
  // opacity: (0.35);
}

// .lPageThree_card:hover {
//   opacity: (1);
// }

.lPageThree_cImg {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.lPageThree_cContent {
  // color: @brand-primary;
  font-weight: 800;
  font-size: 24px;
  margin-top: 20px;
}

.lPageThree_hEl {
  width: 50px;
  height: 5px;
  position: relative;
  background-color: @brand-white;
  animation: lPageThree 2s infinite alternate linear;
}

@keyframes lPageThree {
  0% {
    left: 0;
  }
  25% {
    left: 140px;
  }
  50% {
    left: 280px;
  }
  75% {
    left: 420px;
  }
  100% {
    left: 560px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .lPageThree_card {
    width: 165px;
    height: 160px;
  }

  .lPageThree_cImg {
    width: 90px;
    height: 90px;
  }

  .lPageThree_cContent {
    font-size: 20px;
    margin-top: 15px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .lPageThree_Heading {
    font-size: 33px;
  }

  .lPageThree_hBar {
    width: 490px;
  }

  @keyframes lPageThree {
    0% {
      left: 0;
    }
    25% {
      left: 110px;
    }
    50% {
      left: 220px;
    }
    75% {
      left: 330px;
    }
    100% {
      left: 440px;
    }
  }

  .lPageThree_card {
    width: 150px;
    height: 150px;
  }

  .lPageThree_cImg {
    width: 70px;
    height: 70px;
  }

  .lPageThree_cContent {
    font-size: 17px;
    margin-top: 15px;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .lPageThree_Heading {
    font-size: 30px;
    line-height: 1.3;
  }

  .lPageThree_hBar {
    width: 450px;
  }

  @keyframes lPageThree {
    0% {
      left: 0;
    }
    25% {
      left: 100px;
    }
    50% {
      left: 200px;
    }
    75% {
      left: 300px;
    }
    100% {
      left: 400px;
    }
  }

  .lPageThree_card {
    width: 150px;
    height: 150px;
  }

  .lPageThree_cImg {
    width: 70px;
    height: 70px;
  }

  .lPageThree_cContent {
    font-size: 17px;
    margin-top: 15px;
  }
}

@media screen and (max-width: 576px) {

  .lPageThree_bg {
    padding: 25px 0px 30px 0px;
  }

  .lPageThree_Heading {
    font-size: 27px;
    line-height: 1.3;
  }
  .lPageThree_hBar {
    width: 275px;
    height: 3px;
  }

  .lPageThree_hEl{
    height: 3px;
  }

  @keyframes lPageThree {
    0% {
      left: 0px;
    }
    25% {
      left: 56.25px;
    }
    50% {
      left: 112.5px;
    }
    75% {
      left: 168.75px;
    }
    100% {
      left: 225px;
    }
  }

  .lPageThree_card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 130px;
    border-radius: 18px;
    contain: content;
    background-color: rgba(225, 225, 225, 0.25);
  }

  .lPageThree_cImg {
    width: 75px;
    height: 75px;
    object-fit: cover;
  }

  .lPageThree_cContent {
    color: @brand-primary;
    font-weight: 700;
    font-size: 20px;
    margin-top: 10px;
  }

  .lPageThree_hBar_wrapper {
    margin-bottom: 20px;
  }
}

@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;