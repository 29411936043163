@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;

.pageNineBody_Desktop_carausel {
  display: none;
}
.pageNineBody_Mobile {
  display: none;
}

.pageNineBody_Desktop{
  display: flex;
}

.crPageNine_bg {
  display: grid;
  justify-content: center;
  position: relative;
  width: 100%;
  // height: 620px;
  // min-height: auto;
  padding: 30px 15px 20px 15px;
  background: @brand-white;
  margin-bottom: -60px;
  z-index: 8;
}

.carPageNineSwipperBody {
  max-width: 1250px;
}

.crPageNine_heading {
  // margin-top: 30px;
  font-size: 39px;
  font-weight: 900;
  text-align: center;
}

.crPageNine_hBar_wrapper {
  padding: 0 15px;
  justify-content: center;
  align-items: center;
  display: flex;
  // margin-bottom: 15px;
}

.acrPageNine_hBar {
  margin-bottom: 15px;
  width: 330px;
  height: 5.4px;
  background-color: #9fe1c6;
}

.acrPageNine_hEl {
  width: 50px;
  height: 5.4px;
  position: relative;
  background-color: @brand-primary;
  animation: crPageNine 2s infinite alternate linear;
}

@keyframes crPageNine {
  0% {
    left: 0;
  }

  // 25% {
  //   left: 77.5px;
  // }

  // 50% {
  //   left: 155px;
  // }

  // 75% {
  //   left: 232.5px;
  // }

  100% {
    left: 280px;
  }
}

.crPageNine_mySwiper {
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  // width: 20%;
  height: 520px;
}

.crPageNine_SwiperContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.crPageNine_cardInner {
  // contain: content;
  width: 350px;
  height: 43px 0px;
  // box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 5px 30px 30px 30px;
  border-radius: 25px;
  // background: #29D78d;
  background-image: url("../../../Assets/SEO card new.webp");
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 1;
  transition: transform 1s;
}

.cardContent1 {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  margin-top: -10px;
}

.cardContent2 {
  display: flex;
  color: #ffffff;
  margin-bottom: 12px;
  justify-content: center;
  font-size: 24px;
  text-align: center;
  line-height: 1.3;
  height: 54px;
}

.cardContent3 {
  display: flex;
  color: #ffffff;
  justify-content: center;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}

.cardButton {
  display: flex;
  justify-content: center;
  border-radius: 8px;
  width: 170px;
  padding: 10px 0px 7px 0px;
  margin-top: 35px;
  margin: 28px auto 0px;
  border-bottom: 5px solid#c0bebe;
  background-color: @brand-white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.cardButtonText {
  font-weight: 800;
  font-size: 18px;
  color: @brand-primary;
  cursor: pointer;
}

.crPageNine_quote {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 15px;
}

.crPageNine_content {
  font-size: 13px;
  font-weight: 500;
  padding: 0 15px;
  width: 100%;
  height: 180px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: justify;
  line-height: 1.4;
}

.crPageNine_img_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 210px;
  background-repeat: no-repeat;
  background-size: cover;
}

// .crPageNine_card:hover {
//   transform: scale(1.1);
//   opacity: 0.8;
//   box-shadow: 0 4px 8px 4px rgba(0, 0, 0, 0.1),
//     0 8px 16px 8px rgba(0, 0, 0, 0.08), 0 8px 16px 8px rgba(0, 0, 0, 0.08),
//     0 8px 16px 8px rgba(0, 0, 0, 0.08);
// }

.crPageNine_img {
  border-radius: 50%;
  background: @brand-white;
  width: 140px;
  height: 140px;
  border: 7px solid @brand-white;
  contain: content;
}

.crPageNine_text_one {
  color: @brand-white;
  font-size: 18px;
  font-weight: 600;
  margin-top: 10px;
}

.crPageNine_text_two {
  color: @brand-white;
  font-size: 21px;
  line-height: 1.3;
  font-weight: 500;
  margin-bottom: 10px;
}

.crPageNine_sContainer {
  display: none;
}

@media screen and (max-width: 576px) {

  .pageNineBody_Desktop {
    display: none;
  }

  .crPageNine_bg {
    height: auto;
  }

  .crPageNine_heading {
    font-size: 27px;
  }

  .acrPageNine_hBar {
    margin-bottom: 30px;
    width: 230px;
    height: 3px;
  }

  .acrPageNine_hEl {
    height: 3px;
  }

  @keyframes crPageNine {
    0% {
      left: 0;
    }

    100% {
      left: 180px;
    }
  }

  .pageNineBody_Mobile {
    display: flex;
    flex-wrap: wrap;
    display: grid;
    justify-content: center;
    // align-content: center;
  }

  .crPageNine_mySwiper {
    display: none;
  }

  .crPageNine_SwiperContainer {
    margin-bottom: 30px;
  }

  .crPageNine_cardInner {
    width: 300px;
    padding: 20px 10px 30px 10px;
  }

  .cardContent1 {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .cardButton {
    transform: scale(0.9);
  }

  .crPageNine_cardInner {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; 
  }

  .cardContent2 {
    font-size: 22px;
    text-align: center;
    line-height: 1.3;
  }

  .cardContent3 {
    font-weight: 500;
  }

  // .crPageNine_sContainer {
  //   contain: content;
  //   margin-top: 20px;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   flex-direction: row;
  //   height: 440px;
  //   // flex-wrap: wrap;
  //   width: 100%;
  //   position: relative;
  //   // overflow: hidden;
  // }

  // .crPageNine_slide {
  //   display: flex;
  //   justify-content: center;
  //   flex-direction: column;
  //   align-items: center;
  //   width: 100%;
  //   height: 100%;
  //   position: absolute;
  //   opacity: 0;
  //   transition: opacity ease-in-out 1s;
  // }

  // .crPageNine_slide.active {
  //   opacity: 1;
  // }

  // .crPageNine_dContainer {
  //   width: 100%;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   flex-direction: row !important;
  //   flex-direction: column;
  //   position: relative;
  //   bottom: 10px;
  // }

  // .crPageNine_sDot {
  //   width: 40px;
  //   height: 6px;
  //   background: @brand-primary;
  //   opacity: 0.5;
  //   margin: 30px 5px 20px;
  //   margin-bottom: -15px;
  //   cursor: pointer;
  // }

  // .crPageNine_sDot.active {
  //   opacity: 1;
  //   cursor: pointer;
  // }

  // .crPageNine_card {
  //   contain: content;
  //   width: 330px !important;
  //   height: 400px;
  // }

  // .crPageNine_quote {
  //   width: 100%;
  //   height: 50px;
  // }

  // .crPageNine_content {
  //   font-size: 16px;
  //   height: 160px;
  // }

  // .crPageNine_img_wrapper {
  //   height: 190px;
  //   background-image: url("../../../Assets/testimonial-shape.webp");
  // }

  // .crPageNine_card:hover {
  //   transform: scale(1.05);
  // }

  // .crPageNine_img {
  //   width: 120px;
  //   height: 120px;
  //   border: 5px solid @brand-white;
  // }

  // .crPageNine_text_one {
  //   font-size: 16px;
  //   font-weight: 600;
  // }

  // .crPageNine_text_two {
  //   font-size: 18px;
  //   line-height: 1.2;
  //   margin-bottom: 10px;
  // }
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;