@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;

////////////////////////////

.pageFourBg{
  margin: 46px 0px;
}

.pageFour_xBrand {
  position: absolute;
  left: 110px;
  margin-top: -60px;
  transform: scale(0.8);
}

.pageFour_xBrand2 {
  position: absolute;
  left: 20px;
  margin-top: 20px;
  transform: scale(0.8);
  // top: 650px
}

////////////////////////////

////////////////////////////

.flip-card {
  background-color: transparent;
  width: 230px;
  height: 240px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  // border-radius: 6px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

//front,back  ==== .flip-card-front,.flip-card-back
.Apage_four_card,.Apage_four_card_after_hover {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  hyphens: auto;
  border-radius: 6px;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  box-shadow: 0 4px 8px 0 #9ae4c5;
}

// front
.Apage_four_card {
  width: 230px;
  height: 240px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #e3eae7;
  border-radius: 6px;
  box-shadow: 0 4px 8px 0 #9ae4c5;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  // position: relative;
}

// back
.Apage_four_card_after_hover {
  color: white;
  transform: rotateY(180deg);

  width: 230px;
  height: 240px;
  position: absolute;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: @brand-primary;
  color: @brand-white;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  // transition: 0.5s ease;
  font-size: 14px;
  text-align: justify;
  line-height: 1.4;
  hyphens: auto;
  word-spacing: 0.5px;
  font-weight: 400;

}

.Apage_four_card_image{
  position: relative;
  height: 150px;
  width: 150px;
}

.Apage_four_card_image .card_img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.Apage_four_card_content {
  margin-top: 20px;
  font-weight: 700;
  font-size: 21px;
}

////////////////////////////


.page_four_games_genre {
  font-weight: 900;
  font-size: 42px;
  margin-top: -30px;
}

.pageFour_hBar_wrapper {
  padding: 0 15px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 5;
  margin-bottom: 10px;
  margin-top: -5.5px;
}

.pageFour_hBar {
  width: 330px;
  height: 5px;
  background-color: #9fe1c6;
  margin-bottom: 30px;
}

.page_four_hEl {
  width: 50px;
  height: 5px;
  position: relative;
  background-color: @brand-primary;
  animation: pageFour 2s infinite alternate linear;
}

.page_four_card {
  width: 230px;
  height: 240px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #eafdf5;
  border-radius: 6px;
  box-shadow: 0 4px 8px 0 #9ae4c5;
  position: relative;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.page_four_card_after_hover {
  width: 230px;
  height: 240px;
  position: absolute;
  padding: 15px;
  // margin-left: 12px;
  // top: 0;
  // left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: @brand-primary;
  color: @brand-white;
  border-radius: 6px;
  box-shadow: 0 4px 8px 0 #9ae4c5;
  opacity: 0;
  transition: 0.5s ease;
  font-size: 12px;
  text-align: justify;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.page_four_card_image {
  position: relative;
  height: 150px;
  width: 150px;
}

.page_four_card_image .card_img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.page_four_card_content {
  margin-top: 27px;
  font-weight: 700;
  font-size: 20px;
}

.page_four_card_after_hover:hover {
  opacity: 1;
}

@keyframes pageFour {
  0% {
    left: 0px;
  }

  25% {
    left: 70px;
  }

  50% {
    left: 140px;
  }

  75% {
    left: 210px;
  }

  100% {
    left: 280px;
  }
}

@media screen and (max-width: 576px) {

  .pageFourBg{
    // background-color: yellow;
    margin-bottom: -20px;
    margin-top: -9px;
  }

  .pageFour_xBrand {
    display: none
  };

  .pageFour_xBrand2 {
    display: none
  };

  .pageFour_hBar {
    width: 210px;
    height: 3px;
    margin-top: 3px;
    margin-bottom: 19px;
  }

  .page_four_hEl {
    height: 3px;
  }

  .page_four_games_genre {
    font-size: 27px;
    margin-top: 6px;
  }
  
// .Apage_four_card_after_hover {
//   font-weight: 400;
// }

  @keyframes pageFour {
    0% {
      left: 0px;
    }

    25% {
      left: 40px;
    }

    50% {
      left: 80px;
    }

    75% {
      left: 120px;
    }

    100% {
      left: 160px;
    }
  }
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;