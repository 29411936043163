@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;

.jdPageOne_bg {
    width: 100%;
    height: 350px;
    position: relative;
    // padding: 50px 50px 0px;
    background-image: url("../../../../Assets/Solution-Page-BG.webp");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    // align-items: center;
    justify-content: center;
    flex-direction: column;
    background-size: 100% 100%;
    margin-top: -0.3%;
    // background-color: grey;
}

.jdPageOne_row {
    // background-color: red;
    display: flex;
    justify-content: center;
    // flex-direction: column;
    // align-self: center;
}

.jdPageOne_title_one {
    font-weight: 900;
    width: max-content;
    color: @brand-white;
    font-size: 58px;
    line-height: 1.3;
    // background-color: black;
    text-align: center;
    margin-top: 10px;
    text-transform: uppercase;
}

.jdPageOne_colOne {
    // background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.jdPageOne_Italic {
    text-align: center;
    width: max-content;
    // font-style: italic;
    font-weight: 600;
    color: @brand-white;
    font-size: 30px;
    // background-color: black;
}

// @media screen and (max-width: 576px) {
//     .jdPageOne_bg{
//       margin-top: -4%;
//     }
//   }

@media screen and (max-width: 576px) {
    .jdPageOne_bg {
        margin-top: -6%;
        padding: 15px 0px;
        width: 100%;
        background-repeat: no-repeat;
        background-size: 120% 100%;
        display: flex;
        // align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 230px;
    }

    .jdPageOne_Italic {
        width: auto;
        font-size: 15px;
        padding: 10px 50px 10px 50px;
        margin-top: -10px;
        font-weight: 500;
    }

    .jdPageOne_Italic2 {
        width: auto;
        font-size: 19px;
        padding: 10px 50px 10px 50px;
        margin-bottom: -10px;
        color: white;
        font-style: italic;
        font-weight: 500;
    }
    
    .jdPageOne_title_one {
        font-size: 25px;
        width: auto;
        line-height: 1.3;
        align-items: center;
        margin: auto;
    }
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;