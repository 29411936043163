@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;

.aPageFour_bg {
  display: grid;
  padding-top: 20px;
  padding-bottom: 60px;
}

.aPageFour_rHide {
  // display: flex;
  max-width: 1200px;
  // align-items: center;
  justify-self: center;
}

.aPageFour_card {
  width: 220px;
  height: 220px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #edf6f3;
  border-radius: 6px;
  box-shadow: 0 4px 8px 0 #9ae4c5, 0 4px 8px 0 #9ae4c5, 0 4px 8px 0 #9ae4c5,
    0 4px 8px 0 #9ae4c5;
  position: relative;
  margin: 10px 30px // margin-top: 10px;
    // margin-bottom: 10px;
}

.aPageFour_card_aOuter {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 136px;
  width: 136px;
  border-radius: 50%;
  background: lightgray;
  background: conic-gradient(#21ce86 0deg, #D3D3D3 0deg);
}

.aPageFour_card_aInner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 128px;
  height: 128px;
  background: #eafdf5;
  border-radius: 50%;
}

.aPageFour_card_aDots {
  display: block;
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transform: rotate(0deg);
}

.aPageFour_card_aDots::before {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  top: -3px;
  left: 50%;
  border-radius: 50%;
  background-color: @brand-primary;
  transform: translate(-55%);
}

.aPageFour_card_img {
  position: relative;
  height: 118px;
  width: 118px;
  object-fit: cover;
}

.aPageFour_card_content {
  margin-top: 9px;
  font-weight: 700;
  font-size: 20px;
}

.aPageFour_card:hover .aPageFour_card_aOuter {
  animation: aPageFour_one 0.6s linear forwards;
}

.aPageFour_card:hover .aPageFour_card_aDots {
  animation: aPageFour_two 0.6s linear forwards;
}

@keyframes aPageFour_one {
  0% {
    background: conic-gradient(#21ce86 3.6deg, #ededed 0deg);
  }

  1% {
    background: conic-gradient(#21ce86 7.2deg, #ededed 0deg);
  }

  2% {
    background: conic-gradient(#21ce86 10.8deg, #ededed 0deg);
  }

  3% {
    background: conic-gradient(#21ce86 14.4deg, #ededed 0deg);
  }

  4% {
    background: conic-gradient(#21ce86 18deg, #ededed 0deg);
  }

  5% {
    background: conic-gradient(#21ce86 21.6deg, #ededed 0deg);
  }

  6% {
    background: conic-gradient(#21ce86 25.2deg, #ededed 0deg);
  }

  7% {
    background: conic-gradient(#21ce86 28.8deg, #ededed 0deg);
  }

  8% {
    background: conic-gradient(#21ce86 32.4deg, #ededed 0deg);
  }

  9% {
    background: conic-gradient(#21ce86 36deg, #ededed 0deg);
  }

  10% {
    background: conic-gradient(#21ce86 39.6deg, #ededed 0deg);
  }

  11% {
    background: conic-gradient(#21ce86 43.2deg, #ededed 0deg);
  }

  12% {
    background: conic-gradient(#21ce86 46.8deg, #ededed 0deg);
  }

  13% {
    background: conic-gradient(#21ce86 50.4deg, #ededed 0deg);
  }

  14% {
    background: conic-gradient(#21ce86 54deg, #ededed 0deg);
  }

  15% {
    background: conic-gradient(#21ce86 57.6deg, #ededed 0deg);
  }

  16% {
    background: conic-gradient(#21ce86 61.2deg, #ededed 0deg);
  }

  17% {
    background: conic-gradient(#21ce86 64.8deg, #ededed 0deg);
  }

  18% {
    background: conic-gradient(#21ce86 68.4deg, #ededed 0deg);
  }

  19% {
    background: conic-gradient(#21ce86 72deg, #ededed 0deg);
  }

  20% {
    background: conic-gradient(#21ce86 75.6deg, #ededed 0deg);
  }

  21% {
    background: conic-gradient(#21ce86 79.2deg, #ededed 0deg);
  }

  22% {
    background: conic-gradient(#21ce86 82.8deg, #ededed 0deg);
  }

  23% {
    background: conic-gradient(#21ce86 86.4deg, #ededed 0deg);
  }

  24% {
    background: conic-gradient(#21ce86 90deg, #ededed 0deg);
  }

  25% {
    background: conic-gradient(#21ce86 93.6deg, #ededed 0deg);
  }

  26% {
    background: conic-gradient(#21ce86 97.2deg, #ededed 0deg);
  }

  27% {
    background: conic-gradient(#21ce86 100.8deg, #ededed 0deg);
  }

  28% {
    background: conic-gradient(#21ce86 104.4deg, #ededed 0deg);
  }

  29% {
    background: conic-gradient(#21ce86 108deg, #ededed 0deg);
  }

  30% {
    background: conic-gradient(#21ce86 111.6deg, #ededed 0deg);
  }

  31% {
    background: conic-gradient(#21ce86 115.2deg, #ededed 0deg);
  }

  32% {
    background: conic-gradient(#21ce86 118.8deg, #ededed 0deg);
  }

  33% {
    background: conic-gradient(#21ce86 122.4deg, #ededed 0deg);
  }

  34% {
    background: conic-gradient(#21ce86 126deg, #ededed 0deg);
  }

  35% {
    background: conic-gradient(#21ce86 129.6deg, #ededed 0deg);
  }

  36% {
    background: conic-gradient(#21ce86 133.2deg, #ededed 0deg);
  }

  37% {
    background: conic-gradient(#21ce86 136.8deg, #ededed 0deg);
  }

  38% {
    background: conic-gradient(#21ce86 140.4deg, #ededed 0deg);
  }

  39% {
    background: conic-gradient(#21ce86 144deg, #ededed 0deg);
  }

  40% {
    background: conic-gradient(#21ce86 147.6deg, #ededed 0deg);
  }

  41% {
    background: conic-gradient(#21ce86 151.2deg, #ededed 0deg);
  }

  42% {
    background: conic-gradient(#21ce86 154.8deg, #ededed 0deg);
  }

  43% {
    background: conic-gradient(#21ce86 158.4deg, #ededed 0deg);
  }

  44% {
    background: conic-gradient(#21ce86 162deg, #ededed 0deg);
  }

  45% {
    background: conic-gradient(#21ce86 165.6deg, #ededed 0deg);
  }

  46% {
    background: conic-gradient(#21ce86 169.2deg, #ededed 0deg);
  }

  47% {
    background: conic-gradient(#21ce86 172.8deg, #ededed 0deg);
  }

  48% {
    background: conic-gradient(#21ce86 176.4deg, #ededed 0deg);
  }

  49% {
    background: conic-gradient(#21ce86 180deg, #ededed 0deg);
  }

  50% {
    background: conic-gradient(#21ce86 183.6deg, #ededed 0deg);
  }

  51% {
    background: conic-gradient(#21ce86 187.2deg, #ededed 0deg);
  }

  52% {
    background: conic-gradient(#21ce86 190.8deg, #ededed 0deg);
  }

  53% {
    background: conic-gradient(#21ce86 194.4deg, #ededed 0deg);
  }

  54% {
    background: conic-gradient(#21ce86 198deg, #ededed 0deg);
  }

  55% {
    background: conic-gradient(#21ce86 201.6deg, #ededed 0deg);
  }

  56% {
    background: conic-gradient(#21ce86 205.2deg, #ededed 0deg);
  }

  57% {
    background: conic-gradient(#21ce86 208.8deg, #ededed 0deg);
  }

  58% {
    background: conic-gradient(#21ce86 212.4deg, #ededed 0deg);
  }

  59% {
    background: conic-gradient(#21ce86 216deg, #ededed 0deg);
  }

  60% {
    background: conic-gradient(#21ce86 219.6deg, #ededed 0deg);
  }

  61% {
    background: conic-gradient(#21ce86 223.2deg, #ededed 0deg);
  }

  62% {
    background: conic-gradient(#21ce86 226.8deg, #ededed 0deg);
  }

  63% {
    background: conic-gradient(#21ce86 230.4deg, #ededed 0deg);
  }

  64% {
    background: conic-gradient(#21ce86 234deg, #ededed 0deg);
  }

  65% {
    background: conic-gradient(#21ce86 237.6deg, #ededed 0deg);
  }

  66% {
    background: conic-gradient(#21ce86 241.2deg, #ededed 0deg);
  }

  67% {
    background: conic-gradient(#21ce86 244.8deg, #ededed 0deg);
  }

  68% {
    background: conic-gradient(#21ce86 248.4deg, #ededed 0deg);
  }

  69% {
    background: conic-gradient(#21ce86 252deg, #ededed 0deg);
  }

  70% {
    background: conic-gradient(#21ce86 255.6deg, #ededed 0deg);
  }

  71% {
    background: conic-gradient(#21ce86 259.2deg, #ededed 0deg);
  }

  72% {
    background: conic-gradient(#21ce86 262.8deg, #ededed 0deg);
  }

  73% {
    background: conic-gradient(#21ce86 266.4deg, #ededed 0deg);
  }

  74% {
    background: conic-gradient(#21ce86 270deg, #ededed 0deg);
  }

  75% {
    background: conic-gradient(#21ce86 273.6deg, #ededed 0deg);
  }

  76% {
    background: conic-gradient(#21ce86 277.2deg, #ededed 0deg);
  }

  77% {
    background: conic-gradient(#21ce86 280.8deg, #ededed 0deg);
  }

  78% {
    background: conic-gradient(#21ce86 284.4deg, #ededed 0deg);
  }

  79% {
    background: conic-gradient(#21ce86 288deg, #ededed 0deg);
  }

  80% {
    background: conic-gradient(#21ce86 291.6deg, #ededed 0deg);
  }

  81% {
    background: conic-gradient(#21ce86 295.2deg, #ededed 0deg);
  }

  82% {
    background: conic-gradient(#21ce86 298.8deg, #ededed 0deg);
  }

  83% {
    background: conic-gradient(#21ce86 302.4deg, #ededed 0deg);
  }

  84% {
    background: conic-gradient(#21ce86 306deg, #ededed 0deg);
  }

  85% {
    background: conic-gradient(#21ce86 309.6deg, #ededed 0deg);
  }

  86% {
    background: conic-gradient(#21ce86 313.2deg, #ededed 0deg);
  }

  87% {
    background: conic-gradient(#21ce86 316.8deg, #ededed 0deg);
  }

  88% {
    background: conic-gradient(#21ce86 320.4deg, #ededed 0deg);
  }

  89% {
    background: conic-gradient(#21ce86 324deg, #ededed 0deg);
  }

  90% {
    background: conic-gradient(#21ce86 327.6deg, #ededed 0deg);
  }

  91% {
    background: conic-gradient(#21ce86 331.2deg, #ededed 0deg);
  }

  92% {
    background: conic-gradient(#21ce86 334.8deg, #ededed 0deg);
  }

  93% {
    background: conic-gradient(#21ce86 338.4deg, #ededed 0deg);
  }

  94% {
    background: conic-gradient(#21ce86 342deg, #ededed 0deg);
  }

  95% {
    background: conic-gradient(#21ce86 345.6deg, #ededed 0deg);
  }

  96% {
    background: conic-gradient(#21ce86 349.2deg, #ededed 0deg);
  }

  97% {
    background: conic-gradient(#21ce86 352.8deg, #ededed 0deg);
  }

  98% {
    background: conic-gradient(#21ce86 356.4deg, #ededed 0deg);
  }

  99% {
    background: conic-gradient(#21ce86 360deg, #ededed 0deg);
  }

  100% {
    background: conic-gradient(#21ce86 360deg, #ededed 0deg);
  }

}

@keyframes aPageFour_two {
  0% {
    transform: rotate(3.6deg);
  }

  1% {
    transform: rotate(7.2deg);
  }

  2% {
    transform: rotate(10.8deg);
  }

  3% {
    transform: rotate(14.4deg);
  }

  4% {
    transform: rotate(18deg);
  }

  5% {
    transform: rotate(21.6deg);
  }

  6% {
    transform: rotate(25.2deg);
  }

  7% {
    transform: rotate(28.8deg);
  }

  8% {
    transform: rotate(32.4deg);
  }

  9% {
    transform: rotate(36deg);
  }

  10% {
    transform: rotate(39.6deg);
  }

  11% {
    transform: rotate(43.2deg);
  }

  12% {
    transform: rotate(46.8deg);
  }

  13% {
    transform: rotate(50.4deg);
  }

  14% {
    transform: rotate(54deg);
  }

  15% {
    transform: rotate(57.6deg);
  }

  16% {
    transform: rotate(61.2deg);
  }

  17% {
    transform: rotate(64.8deg);
  }

  18% {
    transform: rotate(68.4deg);
  }

  19% {
    transform: rotate(72deg);
  }

  20% {
    transform: rotate(75.6deg);
  }

  21% {
    transform: rotate(79.2deg);
  }

  22% {
    transform: rotate(82.8deg);
  }

  23% {
    transform: rotate(86.4deg);
  }

  24% {
    transform: rotate(90deg);
  }

  25% {
    transform: rotate(93.6deg);
  }

  26% {
    transform: rotate(97.2deg);
  }

  27% {
    transform: rotate(100.8deg);
  }

  28% {
    transform: rotate(104.4deg);
  }

  29% {
    transform: rotate(108deg);
  }

  30% {
    transform: rotate(111.6deg);
  }

  31% {
    transform: rotate(115.2deg);
  }

  32% {
    transform: rotate(118.8deg);
  }

  33% {
    transform: rotate(122.4deg);
  }

  34% {
    transform: rotate(126deg);
  }

  35% {
    transform: rotate(129.6deg);
  }

  36% {
    transform: rotate(133.2deg);
  }

  37% {
    transform: rotate(136.8deg);
  }

  38% {
    transform: rotate(140.4deg);
  }

  39% {
    transform: rotate(144deg);
  }

  40% {
    transform: rotate(147.6deg);
  }

  41% {
    transform: rotate(151.2deg);
  }

  42% {
    transform: rotate(154.8deg);
  }

  43% {
    transform: rotate(158.4deg);
  }

  44% {
    transform: rotate(162deg);
  }

  45% {
    transform: rotate(165.6deg);
  }

  46% {
    transform: rotate(169.2deg);
  }

  47% {
    transform: rotate(172.8deg);
  }

  48% {
    transform: rotate(176.4deg);
  }

  49% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(183.6deg);
  }

  51% {
    transform: rotate(187.2deg);
  }

  52% {
    transform: rotate(190.8deg);
  }

  53% {
    transform: rotate(194.4deg);
  }

  54% {
    transform: rotate(198deg);
  }

  55% {
    transform: rotate(201.6deg);
  }

  56% {
    transform: rotate(205.2deg);
  }

  57% {
    transform: rotate(208.8deg);
  }

  58% {
    transform: rotate(212.4deg);
  }

  59% {
    transform: rotate(216deg);
  }

  60% {
    transform: rotate(219.6deg);
  }

  61% {
    transform: rotate(223.2deg);
  }

  62% {
    transform: rotate(226.8deg);
  }

  63% {
    transform: rotate(230.4deg);
  }

  64% {
    transform: rotate(234deg);
  }

  65% {
    transform: rotate(237.6deg);
  }

  66% {
    transform: rotate(241.2deg);
  }

  67% {
    transform: rotate(244.8deg);
  }

  68% {
    transform: rotate(248.4deg);
  }

  69% {
    transform: rotate(252deg);
  }

  70% {
    transform: rotate(255.6deg);
  }

  71% {
    transform: rotate(259.2deg);
  }

  72% {
    transform: rotate(262.8deg);
  }

  73% {
    transform: rotate(266.4deg);
  }

  74% {
    transform: rotate(270deg);
  }

  75% {
    transform: rotate(273.6deg);
  }

  76% {
    transform: rotate(277.2deg);
  }

  77% {
    transform: rotate(280.8deg);
  }

  78% {
    transform: rotate(284.4deg);
  }

  79% {
    transform: rotate(288deg);
  }

  80% {
    transform: rotate(291.6deg);
  }

  81% {
    transform: rotate(295.2deg);
  }

  82% {
    transform: rotate(298.8deg);
  }

  83% {
    transform: rotate(302.4deg);
  }

  84% {
    transform: rotate(306deg);
  }

  85% {
    transform: rotate(309.6deg);
  }

  86% {
    transform: rotate(313.2deg);
  }

  87% {
    transform: rotate(316.8deg);
  }

  88% {
    transform: rotate(320.4deg);
  }

  89% {
    transform: rotate(324deg);
  }

  90% {
    transform: rotate(327.6deg);
  }

  91% {
    transform: rotate(331.2deg);
  }

  92% {
    transform: rotate(334.8deg);
  }

  93% {
    transform: rotate(338.4deg);
  }

  94% {
    transform: rotate(342deg);
  }

  95% {
    transform: rotate(345.6deg);
  }

  96% {
    transform: rotate(349.2deg);
  }

  97% {
    transform: rotate(352.8deg);
  }

  98% {
    transform: rotate(356.4deg);
  }

  99% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.aPageFour_mySwiper {
  display: none;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .aPageFour_card {
    width: 200px;
    height: 210px;
    padding: 12px;
  }

  .aPageFour_card_aOuter {
    height: 120px;
    width: 120px;
  }

  .aPageFour_card_aInner {
    width: 110px;
    height: 110px;
  }

  .aPageFour_card_img {
    position: relative;
    height: 100px;
    width: 100px;
    object-fit: cover;
  }

  .aPageFour_card_content {
    margin-top: 24px;
    font-size: 18px;
  }
}

// @media (min-width: 500px) and (max-width: 992px) {
//   .aPageFour_card {
//     width: 160px;
//     height: 180px;
//     padding: 10px;
//   }

//   .aPageFour_card_aOuter {
//     height: 100px;
//     width: 100px;
//   }

//   .aPageFour_card_aInner {
//     width: 90px;
//     height: 90px;
//   }

//   .aPageFour_card_img {
//     position: relative;
//     height: 80px;
//     width: 80px;
//     object-fit: cover;
//   }

//   .aPageFour_card_content {
//     margin-top: 24px;
//     font-size: 18px;
//   }
// }

@media screen and (max-width: 576px) {

  .aPageFour_bg {
    display: block;
  }

  .aPageFour_rHide {
    display: none;
  }

  .aPageFour_bg {
    padding: 20px 10px;
    margin-top: -2px;
    // background: rgb(33, 206, 134);
    // background: linear-gradient(177deg, rgba(33, 206, 134, 1) 0%, rgba(33, 206, 134, 1) 0%, rgba(33, 206, 134, 1) 0%, rgba(29, 207, 149, 1) 100%);
  }

  .aPageFour_mySwiper {
    // padding-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    // width: 90%;
    // height: 520px;
    height: 220px;
  }

  .aPageFour_SwiperContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .aPageFour_card {
    width: 145px;
    height: 159px;
    padding: 10px;
    margin: 4px 0px 0px 0px;
    background-color: #e8eced;
    // border-radius: 1px;
    box-shadow: 0 2px 6px 0 #9ae4c5, 0 2px 6px 0 #9ae4c5, 0 2px 6px 0 #9ae4c5,
      0 2px 6px 0 #9ae4c5;
    // box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  }

  .aPageFour_card_aOuter {
    height: 100px;
    width: 100px;
  }

  .aPageFour_card_aInner {
    width: 93px;
    height: 93px;
  }

  .aPageFour_card_img {
    position: relative;
    height: 85px;
    width: 85px;
    object-fit: cover;
  }

  .aPageFour_card_content {
    margin-top: 10px;
    font-size: 16px;
  }

  .swiper-pagination-bullet {
    width: 30px;
    height: 3px;
    background-color: @brand-primary;
    border-radius: 0px;
  }

  .swiper-pagination-bullet-active {
    background: @brand-primary;
  }
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;