@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;

.pageTwo_BodyCard_kids {
  // background-color: #ff2a2a;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1183px;
  // position: absolute;
  // margin: auto; /* Center horizontally */
}
.pageTwo_DesktopView {
  max-width: 1183px;;
}

.pageTwo_BodyCardChild_InsideKidsE {
  background-color: @brand-white;
  padding: 20px;
  margin: 6px;
  border-radius: 6px;
  width: 278px; /* Set a fixed width for each card */
  color: @brand-primary;
  font-weight: 800;
  text-align: center;
  background-size: 110%;
  transition: 0.1s ease-out;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.pageTwo_BodyCardChild_InsideKidsE:hover {
  background-position: left;
  transition: all 0.1s linear;
  background-color: @brand-primary;
  color: @brand-white;
}

.pageTwo_BodyCardChild_InsideKidsE img {
  transition: 0.1s all ease-in-out;
}

.pageTwo_BodyCardChild_InsideKidsE :hover {
  transform: scale(1.02);
}

.setBodyFont_InsideKidsE {
  font-size: 20px;
}

.pPageTwo_Background {
  display: grid;
  padding-top: 29px;
  padding-bottom: 24px;
}

.pf_page_two_ourgames_kidsee {
  font-weight: 900;
  font-size: 44px;
}

.pf_pageTwo_hBar_InsideKidsE {
  width: 645px;
  height: 5px;
  background-color: #9fe1c6;
  margin-top: -6px;
  // margin-bottom: -50px;
}

.pf_pageTwo_games_conttainer {
  display: flex;
  justify-content: center;
  padding: 0 30px;
}

.pf_pageTwo_img_card_xl {
  contain: content;
  display: flex;
  justify-content: center;
  border: 2px solid #f6f6f6;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.pf_pageTwo_img_card_sm {
  contain: content;
  display: flex;
  justify-content: center;
  border: 2px solid #f6f6f6;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}


.button-group_kidse {
  justify-self: center;
  justify-content: space-between;
  display: flex;
  padding: 8px;
  width: 446px;
  // margin-bottom: 5px;
}

.button-group_kidse button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 125px;
  height: 38px;
  color: @brand-white;
  background-color: #21ce86;
  font-size: 21px;
  font-weight: 700;
  border-bottom: 4px solid #26a671;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 4px, rgba(0, 0, 0, 0.2) 0px 7px 13px -3px, rgba(0, 0, 0, 0.1) 0px -3px 0px inset;
  border-radius: 6px;
  border: @brand-white;
}

.button-group_kidse button:hover,
.button-group_kidse button.selected {
  background-color: @brand-white;
  color: #21ce86;
  border-bottom: 3px #c0bebe;
}

.pf_pageTwo_explore_more {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 800;
  font-size: 24px;
  // min-width: 250px;
  border-radius: 6px;
  // height: 54px;
  border-bottom: 4px solid#26a671;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  // box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.4);
  color: @brand-white;
  background-color: @brand-primary;
  padding: 5px 25px 2px 25px;
  // text-align: ;
  margin: 10px 15px 15px 15px;
}

.bodyCardImage_kidsee {
  // background-color:#21ce86;
  width: 240px
}

.setBodyFont_InsideKidsE .pageTwo_GameTitle {
  font-size: 20px;
}


.pf_page_two_hEl_InsideKidsE {
  width: 50px;
  height: 5px;
  position: relative;
  background-color: @brand-primary;
  // animation-name: pageTwo_InsideKidsE;
  // animation-duration: 1.3s;
  // animation-iteration-count: infinite;
  // animation-direction: alternate;
  animation: pageTwo_InsideKidsE 3s infinite alternate linear;
}

@keyframes pageTwo_InsideKidsE {
  0% {
    left: 0px;
  }

  100% {
    left: 590px;
  }
}


@media (max-width: 576px) and (min-width: 992px) {
  .pf_pageTwo_img_card_sm {
    max-width: 180px;
    max-height: 180px;
  }


}

@media screen and (max-width: 576px) {
  .pf_pageTwo_hBar_InsideKidsE {
    width: 180px;
    height: 3px;
    margin-bottom: 24px;
    margin-top: -3px;
    margin-left: 17px;

  }

  // .pageTwo_MobileView{
  //   background-color: red;
  // }
  .pageTwo_GameTitle {
    font-size: 20px;
    // background-color: red;
  }



  .pageTwo_BodyCardChild_InsideKidsE {
    background-color: @brand-white;
    // background: linear-gradient(to right, black 50%, white 50%);
    padding: 14px;
    margin: 6px -1px 14px 6px;
    border-radius: 6px;
    width: min-content;
    color: @brand-primary;
    // font-size: 16px;
    font-weight: 800;
    text-align: center;
    /* "to left" / "to right" - affects initial color */
    // background: linear-gradient(to left top, @brand-white 95%, @brand-primary 45%) right;
    background-size: 110%;
    transition: 0.1s ease-out;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  // .bodyCardImage_kidsee {
  //   // background-color:#21ce86;
  //   width: 280px
  // }

  // .pf_page_two_hEl_InsideKidsE{
  //   height: 3px;
  // }

  .pf_page_two_hEl_InsideKidsE {
    width: 50px;
    height: 3px;
    position: relative;
    background-color: @brand-primary;
    // animation-name: pageTwo_InsideKidsE;
    // animation-duration: 1.3s;
    // animation-iteration-count: infinite;
    // animation-direction: alternate;
    animation: pageTwo_insideKids 2s infinite alternate linear;

  }

  @keyframes pageTwo_insideKids {
    0% {
      left: 0px;
    }


    100% {
      left: 130px;
    }
  }

  .pf_page_two_ourgames_kidsee {
    width: 250px;
    font-weight: 900;
    font-size: 28px;
    line-height: 32px;
    margin-left: 45px;

  }

  .pf_page_two_ourgames_kidse {
    width: 250px;
    font-weight: 900;
    font-size: 27px;
    line-height: 32px;
    margin-left: 19px;

  }


  .button-group_kidse_Mobile {
    // background-color: #997602;
    // display: flex;
    width: 315px;
    margin-top: -34px;
    margin-right: 45px;
    position: relative;
    margin-bottom: 11px;
  }

  .button-group_kidse_Mobile button {
    display: flex;
    align-items: center !important;
    text-align: center !important;
    // justify-content: center;
    height: 28px;
    width: 85px;
    font-size: 13px;
    margin: auto;
    border-bottom: 3px solid #26a671 !important;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 2px;
    font-weight: 700;
    border-radius: 4px;
    align-items:baseline !important;
  }

  .button-group_kidse button:hover,
  .button-group_kidse button.selected {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 2px !important;
    background-color: @brand-white !important;
    color: #21ce86;
    border-bottom: 3px solid #c0bebe !important;
  }

  .pf_pageTwo_explore_more {
    font-size: 17px;
    font-weight: 700;
    padding: 5px 18px 3px 18px;
    height: auto;
    margin-top: 0px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  }

  .pf_pageTwo_games_conttainer {
    padding: 0 6px;
  }

  @keyframes pageTwo_InsideKidsE {
    0% {
      left: 0px;
    }

    25% {
      left: 32.5px;
    }

    50% {
      left: 65px;
    }

    75% {
      left: 97.5px;
    }

    100% {
      left: 130px;
    }
  }
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;