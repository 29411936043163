@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;

.pfPageOne_bg {
    width: 100%;
    height: 450px;
    position: relative;
    // padding: 50px 50px 0px;
    background-image: url("../../../Assets/Solution-Page-BG.webp");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    // align-items: center;
    justify-content: center;
    flex-direction: column;
    background-size: 100% 100%;
    margin-top: -5px;
    // background-color: grey;
}

.pfPageOne_row {
    // background-color: red;
    display: flex;
    justify-content: center;
    // flex-direction: column;
    // align-self: center;
}

.pfPageOne_title_one_port {
    font-weight: 900;
    width: max-content;
    color: @brand-white;
    font-size: 70px;
    line-height: 1.3;
    // background-color: black;
    text-align: center;
    // display: flex;
    // justify-content: center;
    // align-self: center;
    margin-top: -10px;
}

.pfPageOne_colOne {
    // background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pfPageOne_Italic {
    text-align: center;
    width: max-content;
    // font-style: italic;
    font-weight: 600;
    color: @brand-white;
    font-size: 35px;
    // font-style: italic;
    // background-color: black;
}

// @media screen and (max-width: 576px) {
//     .pfPageOne_bg{
//       margin-top: -4%;
//     }
//   }

@media (min-width: 992px) and (max-width: 1150px) {
    .pfPageOne_bg {
        padding: 30px 30px 0px;
        height: 350px;

    }

    .pfPageOne_title_one_port {
        font-size: 46px;
        // line-height: 1.25;
    }

    .pfPageOne_Italic {
        font-size: 27px;
    }
}

@media screen and (max-width: 992px) {
    .pfPageOne_bg {
        margin-top: -22px;
        // padding: 15px 0px;
        // width: 100%;
        // background-repeat: no-repeat;
        // background-size: 120% 100%;
        // display: flex;
        // // align-items: center;
        // justify-content: center;
        // flex-direction: column;
        height: 250px;
    }

    .pfPageOne_title_one_port {
        font-size: 36px;
        // line-height: 1.25;
    }

    .pfPageOne_Italic {
        font-size: 27px;
    }

    .pfPageOne_Italic {
        width: auto;
        font-size: 20px;
        padding: 10px 50px 10px 50px;
    }
}

@media screen and (max-width: 576px) {
    .pfPageOne_bg {
        margin-top: -5.8%;
        // background-image: url("../../../Assets/bg.webp");
        background-repeat: no-repeat;
        background-size: 130% 100%;
        padding: 50px 50px 0px;
        // width: 100%;
        // background-repeat: no-repeat;
        // background-size: 120% 100%;
        // display: flex;
        // // align-items: center;
        // justify-content: center;
        // flex-direction: column;
        height: 230px;
    }

    .pfPageOne_title_one_port {
        font-size: 30px;
        line-height: 1.25;
        margin-top: -30px;
    }

    // .pfPageOne_Italic {
    //     font-size: 27px;
    // }

    .pfPageOne_Italic {
        width: 350px;
        font-size: 18px;
        padding: 10px 50px 10px 50px;
    }
}

@media (min-width: 0px) and (max-width: 386px) {}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;