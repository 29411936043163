@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;

////////////////////////////////
.pageNine_bg {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 40px 60px 40px 60px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
  background-color: @brand-white;
  border-radius: 18px;
  bottom: -70px;
  margin: auto;
  margin-top: -200px;
  width: 86%;
  height: 151px;
  line-height: 1.3;
  // transform: scale(0.5);
}

////////////////////////////////

////////////-Text-Div-////////////////////
.pageNine_TextDiv {
  // background-color: brown;
  // display: flex;
  justify-items: center;
}

.pageNine_text_title {
  // background-color: yellow;
  // text-align: center;
  font-size: 50px;
  font-weight: 900;
  color: @brand-primary;
  margin-top: -35px;
  padding-top: 21px;
  // margin-left: 20px;
}

.pageNine_text {
  // background-color: blue;
  font-size: 17px;
  text-align: center;
  color: #6b6b6b;
  font-weight: 600;
  // margin-left: 20px;
}

////////////////////////////////

////////////////////////////////
.pageNine-btn {
  // background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  // font-weight: 800;
  // font-size: 21px;
  border-radius: 8px;
  // height: 54px;
  border-bottom: 2px solid #009688;
  color: @brand-white;
  background-color: @brand-primary;
  // background-color: #ffffff;
    // box-shadow: rgb(0 0 0 / 30%) 0px 2px 2px, rgb(0 0 0 / 30%) 0px 7px 10px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;
  cursor: pointer;
  // box-shadow: rgb(0 0 0 / 35%) 0px 5px 5px;
  box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;
  align-content: center;
  align-self: center;
  padding: 13px 15px 12.5px 15px;
}

.pageNine_Btn_Text {
  // background-color: black;
  text-align: center;
  display: flex;
  align-self: center;
  font-weight: 800;
  font-size: 22px;
}

////////////////////////////////

// @media screen and (max-width: 1140px) {
//   .pageNine_bg {
//     padding: 45px;
//   }
// }

// @media screen and (max-width: 992px) {
//   .pageNine_row {
//     // display: flex;
//     // justify-content: center;
//     // align-items: center;
//   }

//   .pageNine_text_title {
//     // text-align: center;
//   }
// }

// @media screen and (max-width: 896px) {
//   .pageNine_bg {
//     bottom: -130px;
//     margin-top: -230px;
//     width: 85%;
//   }
// }

@media screen and (max-width: 576px) {

  .pageNine_bg {
    // background-color: red;
    display: block;
    justify-content: center;
    padding: 18px 4px 18px 4px;
    bottom: -90px;
    text-align: center;

  }
  
  .pageNine_TextDiv {
    transform: scale(1.1);
    // background-color: brown;
    display: inline-block;

    // justify-content: center;
    // justify-items: center;
    // justify-self: center;
    // align-content: center;
    // align-items: center;
    // align-self: center;
  }

  .pageNine_text {
    text-align: center;
    // background-color: blue;
    font-size: 11px;
    width: 200px;
    margin-bottom: 6px;
    font-weight: 700;
    color: #8d9fa2;
    // justify-self: center;
    // margin-top: -16px;
  }

  .pageNine_text_title {
    // background-color: yellow;
    font-size: 21px;
    margin: auto;
    margin-top: -15px;
  }

  .pageNine-btn {
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    padding: 7px 9px 6px 10px;
    border-bottom: 3.5px solid #009688;
    margin-top: 8px;
    border-radius: 4px;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 5px;

  }

  .pageNine_Btn_Text {
    // justify-self: center;
    // align-content: center;
    // display: inline-block;
    font-size: 14px;
    font-weight: 800px;

  }
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;