@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;

.pageEight_icons_container {
  height: 100px;
  position: relative;
  overflow: hidden;
  width: auto;
}

.pageEight_icons {
  display: flex;
  width: calc(250px * 5);
  // width: auto;
  animation: scroll 15s linear infinite;
}

.pageEight_icon {
  width: 250px;
  height: 100px;
  padding: 0 40px;
  padding-right: 70px;
  transform: scale(1.4);
}

.pageEight_icons:hover+ {
  // animation-play-state: paused;
}

.pageEight_icon:hover {
  // -webkit-transform: scale(1.1);
  // -moz-transform: scale(1.1);
  // -o-transform: scale(1.1);
  // -ms-transform: scale(1.1);
  transform: scale(1.6);
  cursor: pointer;
}

@keyframes scroll {
  0% {
    transform: translateX(250px * 5);
  }

  100% {
    transform: translateX(-250px * 5);
  }
}

@media screen and (max-width: 768px) {
  .pageEight_icon {
    width: 200px;
    height: 90px;
    padding: 0 10px;
  }

  @keyframes scroll {
    0% {
      transform: translateX(200px * 5);
    }

    100% {
      transform: translateX(-200px * 5);
    }
  }
}

@media screen and (max-width: 576px) {
  .pageEight_icon {
  width: 200px;
  height: 100px;
    // padding: 0 10px;
    transform: scale(1);
  }

  .pageEight_icon:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  @keyframes scroll {
    0% {
      transform: translateX(200px * 5);
    }

    100% {
      transform: translateX(-200px * 5);
    }
  }
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;