@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;

.sPageThree_bg {
  display: grid;
  position: relative;
  width: 100%;
  min-height: auto;
  padding: 30px 30px;
  background: @brand-white;
}

.sPageThree_Body {
  width: 1200px;
  align-self: center;
  justify-content: center;
  justify-self: center;
}

.sPageThree_Heading {
  text-align: center;
  font-weight: 900;
  font-size: 36px;
  margin-top: 3px;
}

.sPageThree_hBar_wrapper {
  padding: 0 15px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.sPageThree_hBar {
  width: 565px;
  height: 5px;
  background-color: #9fe1c6;
}

.sPageThree_content_wraper {
  max-width: 500px;
}

.sPageThree_step_one {
  font-size: 26px;
  font-weight: 800;
  color: @brand-primary;
}

.sPageThree_subHeading_one {
  margin-top: 10px;
  font-size: 32px;
  font-weight: 800;
  line-height: 1.3;
  color: @brand-secondary;
}

.sPageThree_content_one {
  margin-top: 10px;
  color: @brand-primary;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.4;
  text-align: justify;
  hyphens: auto;
  max-width: 500px;
}

.sPageThree_step_two {
  text-align: end;
  font-size: 26px;
  font-weight: 800;
  color: @brand-primary;
}

.sPageThree_subHeading_two {
  text-align: end;
  margin-top: 10px;
  font-size: 32px;
  font-weight: 800;
  line-height: 1.3;
  color: @brand-secondary;
}

.sPageThree_content_two {
  margin-top: 10px;
  color: @brand-primary;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.4;
  text-align: justify;
}

.sPageThree_img {
  max-width: 400px;
  max-height: 350px;
}

.sPageThree_hEl {
  width: 50px;
  height: 5px;
  position: relative;
  background-color: @brand-primary;
  animation: sPageThree 2s infinite alternate linear;
}

@keyframes sPageThree {
  0% {
    left: 0;
  }

  25% {
    left: 128.75px;
  }

  50% {
    left: 257.5px;
  }

  75% {
    left: 386.25px;
  }

  100% {
    left: 515px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .sPageThree_Heading {
    font-size: 33px;
  }

  .sPageThree_hBar {
    width: 450px;
  }

  @keyframes sPageThree {
    0% {
      left: 0;
    }

    25% {
      left: 100px;
    }

    50% {
      left: 200px;
    }

    75% {
      left: 300px;
    }

    100% {
      left: 400px;
    }
  }

  .sPageThree_step_one {
    font-size: 21px;
  }

  .sPageThree_subHeading_one {
    font-size: 27px;
  }

  .sPageThree_content_one {
    font-weight: 600;
    font-size: 17px;
  }

  .sPageThree_step_two {
    font-size: 21px;
  }

  .sPageThree_subHeading_two {
    font-size: 27px;
  }

  .sPageThree_content_two {
    font-size: 17px;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .sPageThree_Heading {
    font-size: 30px;
  }

  .sPageThree_img {
    max-width: 350px;
    max-height: 300px;
  }

  .sPageThree_hBar {
    width: 410px;
  }

  @keyframes sPageThree {
    0% {
      left: 0;
    }

    25% {
      left: 90px;
    }

    50% {
      left: 180px;
    }

    75% {
      left: 270px;
    }

    100% {
      left: 360px;
    }
  }

  .sPageThree_step_one {
    font-size: 18px;
  }

  .sPageThree_subHeading_one {
    font-size: 24px;
  }

  .sPageThree_content_one {
    font-size: 16px;
  }

  .sPageThree_step_two {
    text-align: start;
    font-size: 18px;
  }

  .sPageThree_subHeading_two {
    text-align: start;
    font-size: 24px;
  }

  .sPageThree_content_two {
    font-size: 16px;
  }
}

@media screen and (max-width: 576px) {
  .sPageThree_bg {
    padding: 30px 5px 20px 5px;
    display: block;
  }

  .sPageThree_Body {
    width: auto;
    padding: 0px 10px;
  }

  .sPageThree_Heading {
    font-size: 27px;
    line-height: 1.3;
  }

  .sPageThree_hBar_wrapper {
    margin-top: 10px;
  }

  .sPageThree_hBar {
    width: 280px;
    height: 3px;
    margin-top: -14px;
  }

  .sPageThree_hEl {
    height: 3px;
  }

  @keyframes sPageThree {
    0% {
      left: 0px;
    }

    25% {
      left: 57.5px;
    }

    50% {
      left: 115px;
    }

    75% {
      left: 172.5px;
    }

    100% {
      left: 230px;
    }
  }

  .sPageThree_step_one {
    font-size: 18px;
    margin-bottom: -10px;
  }

  .sPageThree_subHeading_one {
    font-size: 23px;
  }

  .sPageThree_content_one {
    font-size: 15px;
    hyphens: auto;
  }

  .sPageThree_step_two {
    text-align: start;
    font-size: 18px;
    margin-bottom: -10px;
  }

  .sPageThree_subHeading_two {
    text-align: start;
    font-size: 23px;
  }

  .sPageThree_content_two {
    font-size: 15px;
    hyphens: auto;
  }

  .sPageThree_img {
    margin-bottom: -40px;
    margin-top: 15px;
  }
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;