@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;

.cuPageOne_bg {
    width: 100%;
    height: 250px;
    position: relative;
    padding: 50px 50px 50px 50px;
    background-image: url("../../../Assets/X-Brandiung-gradient.webp");
    background-repeat: no-repeat;
    background-size: 100% 500%;
    background-position: 0px -720px;
    display: flex;
    // align-items: center;
    justify-content: center;
    flex-direction: column;
    // background-size: 100% 100%;
    // background-color: yellow;
    margin-top: -0.3%;
}

.cuPageOne_row {
    // background-color: red;
    display: flex;
    justify-content: center;
    // flex-direction: column;
    // align-self: center;
}

.cuPageOne_title_one {
    font-weight: 900;
    width: max-content;
    color: @brand-secondary;
    font-size: 51px;
    line-height: 1.3;
    // background-color: black;
    text-align: center;
    // display: flex;
    // justify-content: center;
    // align-self: center;
}

.cuPageOne_title_one_Mobile {
    display: none;
    // justify-content: center;
    // align-self: center;
}

.cuPageOne_colOne {
    // background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cuPageOne_Italic {
    text-align: center;
    width: max-content;
    // font-style: italic;
    font-weight: 600;
    color: @brand-secondary;
    font-size: 25px;
    // background-color: black;
}

// @media (min-width: 992px) and (max-width: 1150px) {
//     .cuPageOne_bg {
//         padding: 30px 30px 0px;
//     }

//     .cuPageOne_title_one {
//         font-size: 36px;
//         line-height: 1.25;
//     }

//     .cuPageOne_Italic {
//         font-size: 24px;
//     }
// }

// @media screen and (max-width: 992px) {
//     .cuPageOne_bg {
//         margin-top: -4%;
//         padding: 15px 0px;
//         // width: 100%;
//         background-repeat: no-repeat;
//         // background-size: 120% 100%;
//         display: flex;
//         // align-items: center;
//         justify-content: center;
//         flex-direction: column;
//         height: calc(100vh - 740px);
//         background-size: 200% 500%;
//         background-position: -200px -350px;
//     }

//     .cuPageOne_title_one {
//         font-size: 28px;
//         width: auto;
//         // margin-inline: 20px;
//         padding: 10px 70px 10px 70px;
//     }

//     .cuPageOne_Italic {
//         width: auto;
//         margin-inline: 30px;
//         font-size: 12px;
//     }
// }

@media screen and (max-width: 576px) {
    .cuPageOne_bg {
        margin-top: -8%;
        padding: 15px 0px;
        width: 100%;
        height: 140px;
        background-image: none;
    }

    .cuPageOne_title_one {
        font-size: 28px;
        // width: 300px;
        background-color: red;
        // margin-inline: 20px;
        // padding: 10px 10px 10px 70px;
        display: none;
    }

    .cuPageOne_title_one_Mobile {
        display: block;
        font-weight: 900;
        width: auto;
        color: @brand-secondary;
        font-size: 28px;
        line-height: 1.3;
        // background-color: yellow;
        text-align: center;
    }

    .cuPageOne_Italic {
        width: auto;
        // margin-inline: 30px;
        font-size: 11.5px;
    }
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;