@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;

.lPageOne_bg_mobile {
  display: none;
}

.lPageOne_innerBg {
  display: grid;
  background-image: url("../../../Assets/common-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 100% 100%;
  margin-top: -0.3%;
}

.lPageOne_bg {
  display: flex;
  justify-content: space-around;
  justify-self: center;
  max-width: 1300px;
  // width: 100%;
  height: 500px;
  padding: 15px;
  margin-top: -0.3%;
}

.rightSectionL {
  margin-bottom: -110px;
  max-width: 700px;
}

.leftSectionL {
  justify-content: center;
  display: flex;
  flex-direction: column;
  min-width: 500px;
}

.lPageOne_row {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lPageOne_colOne {
  width: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  padding-right: 14px;
}

.lPageOne_colTwo {
  display: flex;
  justify-content: center;
  padding-left: 14px;
  width: 50%;
}

.lPageOne_Italic {
  font-style: italic;
  font-weight: 600;
  color: @brand-white;
  font-size: 29px;
  margin-top: -14px;
}

.lPageOne_title_one {
  font-weight: 900;
  color: @brand-white;
  font-size: 45px;
  line-height: 1.3;
}

.lPageOne_title_two {
  font-weight: 600;
  color: @brand-white;
  font-size: 30px;
}

.lPageOne_title_three {
  font-weight: 800;
  color: @brand-white;
  font-size: 27px;
  background-color: #168668;
  padding: 8px 10px 8px 5px;
  margin-left: 6px;
}

.lPageOne-box {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  width: 300px;
  padding: 6px 5px;
  margin-top: 50px;
  border-bottom: 5px solid#c0bebe;
  background-color: @brand-white;
  box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.2), 0 4px 8px 2px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.lPageOne-box_text {
  font-weight: 800;
  font-size: 26px;
  color: @brand-primary;
}

.lPageOne_img {
  max-width: 710px;
  max-height: 550px;
  margin-bottom: -18px;
  // background-color: red;
  // align-items:baseline;
}

// @media (max-width: 576px) {

//   .lPageOne_bg {
//     display: none;
//     // padding: 15px 0px;
//   }

//   .lPageOne_innerBg {
//     // height: 530px;
//     margin-top: -6%;
//   }

//   .lPageOne_bg_mobile{
//     display: grid;
//     justify-content: center;
//     align-items: center;
//     height: 530px;
//   }

//   .lPageOne_colOne {
//     width: 100%;
//     height: 50%;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     text-align: center;
//   }

//   .lPageOne_colTwo {
//     width: 100%;
//     height: 50%;
//   }

//   .lPageOne_Italic {
//     font-size: 27px;
//     text-align: center;
//   }

//   .lPageOne_title_one {
//     font-size: 36px;
//     text-align: center;
//   }

//   .lPageOne_title_two {
//     font-size: 24px;
//     text-align: center;
//   }

//   .lPageOne_title_three {
//     font-size: 21px;
//     padding: 5px 7px 5px 4px;
//     text-align: center;
//   }

//   .lPageOne-box {
//     width: 270px;
//     padding: 2px;
//     margin-top: 30px;
//     justify-self: center;
//   }

//   .lPageOne-box_text {
//     font-weight: 700;
//     font-size: 24px;
//   }

//   .lPageOne_img {
//     max-width: 380px;
//     max-height: 300px;
//     margin-bottom: -25px;
//   }
// }

@media (max-width: 576px) {

  .lPageOne_bg {
    display: none;
  }

  .lPageOne_innerBg {
    background-image: url("../../../Assets/background.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-size: 100% 100%;
    height: 478px;
    margin-top: -6%;
    display: grid;
    justify-content: center;
    align-items: center;
  }

  .lPageOne_bg_mobile {
    display: block;
    justify-self: center;
    text-align: center;
  }

  .topSection {
    display: grid;
  }

  .textOne {
    font-style: italic;
    font-weight: 500;
    color: @brand-white;
    font-size: 20px;
  }

  .textTwo {
    font-weight: 800;
    color: @brand-white;
    font-size: 28px;
    line-height: 1.3;
  }

  .textThreeOne {
    font-weight: 700;
    color: @brand-white;
    font-size: 23px;
  }

  .textThreeTwo {
    font-weight: 700;
    color: @brand-white;
    font-size: 22px;
    background-color: #168668;
    padding: 2px 2px 2px 2px;
    margin-left: 6px;
    border-radius: 2px;
  }

  .contactUsLicenseButton {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    // width: 190px;
    padding: 6px 10px 4px 10px;
    margin-top: 25px;
    border-bottom: 3.5px solid#c0bebe;
    background-color: @brand-white;
    box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.2), 0 4px 8px 2px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    justify-self: center;
  }

  .contactUsLicenseButton_text {
    font-weight: 800;
    font-size: 14px;
    color: @brand-primary;
  }

  .bottomSection {
    position: relative;
    justify-self: center;
    width: 325px;
    margin-top: 35px;
    margin-bottom: -60px;
    // height: 200px
  }

  // .contactUsLicenseButton_text {
  //   font-weight: 800;
  //   font-size: 14px;
  //   color: @brand-primary;
  // }

  // .bottomSection {
  //   position: relative;
  //   justify-self: center;
  //   width: 325px;
  //   margin-top: 45px;
  //   margin-bottom: -70px;
  //   // height: 200px
  // }
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;