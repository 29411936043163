@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;

.pageSeven_bg {
  position: relative;
  width: 100%;
  height: 400px;
  background-image: url("../../../Assets/office-background.webp");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-size: 100% 100%;
  // margin-bottom: -70px;
  z-index: 8;
}

.pageSeven_text {
  text-align: center;
  font-size: 48px;
  font-weight: 900;
  color: @brand-white;
  -webkit-animation: glow 2s ease-in-out infinite alternate;
  -moz-animation: glow 2s ease-in-out infinite alternate;
  animation: glow 2s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    text-shadow: 0 0 30px #445769, 0 0 40px #445769, 0 0 50px #445769;
  }
  to {
    text-shadow: 0 0 30px #769bae, 0 0 40px #7c9dab, 0 0 50px #7aa2b3;
  }
}

.pageSeven_button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  font-weight: 800;
  font-size: 28px;
  min-width: 250px;
  border-radius: 6px;
  height: 54px;
  border-bottom: 3px solid#c0bebe;
  color: @brand-primary;
  background-color: @brand-white;
  // box-shadow: 0 4px 0px 0 rgb(133, 122, 122);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  cursor: pointer;
  margin-top: 60px;
  padding: 25px 30px 25px 30px;
  // transition: ;
}

@media screen and (max-width: 576px) {

  .pageSeven_bg {
    // background-repeat: no-repeat;
    background-size: auto 100%;
    height: auto;
    background-position: center;
    // padding-bottom: -20px;
  }
  
  .pageSeven_text {
    font-size: 36px;
    width: 250px;
    margin-top: 50px;
    line-height: 1.3;
  }
  .pageSeven_button {
    font-size: 24px;
    min-width: 200px;
    height: 48px;
    padding: 10px;
    transform: scale(0.8);
    margin-top: 19px;
    margin-bottom: 40px;;
  }
}

@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;