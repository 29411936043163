@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;

.aPageThree_bg {
  display: grid;
  // background: rgb(33,206,134);
  // background: linear-gradient(177deg, rgba(33,206,134,1) 0%, rgba(33,206,134,1) 0%, rgba(33,206,134,1) 0%, rgba(29,207,149,1) 100%);
}

.content {
  max-width: 1300px;
  justify-self: center;
}

.aPageThree_heading {
  margin-top: -10px;
  font-size: 36px;
  font-weight: 900;
  text-align: center;
}

.aPageThree_hBar_wrapper {
  padding: 0 15px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 15px;
}

.aPageThree_hBar {
  width: 355px;
  height: 5px;
  background-color: #9fe1c6;
  margin-top: -2px;
}

.aPageThree_hEl {
  width: 50px;
  height: 5px;
  position: relative;
  background-color: @brand-primary;
  animation: aPageThree 2s infinite alternate linear;
}

@keyframes aPageThree {
  0% {
    left: 0;
  }

  25% {
    left: 76.25px;
  }

  50% {
    left: 152.5px;
  }

  75% {
    left: 228.75px;
  }

  100% {
    left: 305px;
  }
}

.aPageThree_text {
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  margin-top: 25px;
}

@media (min-width: 768px) and (max-width: 992px) {
  .aPageThree_heading {
    font-size: 33px;
  }

  .aPageThree_hBar {
    width: 290px;
  }

  @keyframes aPageThree {
    0% {
      left: 0px;
    }

    25% {
      left: 60px;
    }

    50% {
      left: 120px;
    }

    75% {
      left: 180px;
    }

    100% {
      left: 240px;
    }
  }

  .aPageThree_text {
    font-size: 17px;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .aPageThree_heading {
    font-size: 30px;
  }

  .aPageThree_hBar {
    width: 290px;
  }

  @keyframes aPageThree {
    0% {
      left: 0px;
    }

    25% {
      left: 60px;
    }

    50% {
      left: 120px;
    }

    75% {
      left: 180px;
    }

    100% {
      left: 240px;
    }
  }

  .aPageThree_text {
    font-size: 17px;
  }
}

@media screen and (max-width: 576px) {
  .aPageThree_bg {
    // background: rgb(33,206,134);
    // background: linear-gradient(177deg, rgba(33,206,134,1) 0%, rgba(33,206,134,1) 0%, rgba(33,206,134,1) 0%, rgba(29,207,149,1) 100%);
  }

  .aPageThree_heading {
    margin-top: 5px;
    padding-top: 20px;
    font-size: 24px;
    color: #4e7884;
  }

  .aPageThree_hBar {
    width: 235px;
    height: 3px;
    background-color: #9fe1c6;
  }

  .aPageThree_hEl {
    background-color: @brand-white;
    height: 3px;
    background-color: @brand-primary;
  }

  .aPageThree_text {
    margin-top: 0px;
    font-size: 14px;
    text-align: center;
    padding: 0px 8px;
    color: #4e7884;
    hyphens: auto;
    margin-bottom: -2px;
    font-weight: 600;
    line-height: 1.4;
  }

  @keyframes aPageThree {
    0% {
      left: 0px;
    }

    25% {
      left: 46.25px;
    }

    50% {
      left: 92.5px;
    }

    75% {
      left: 138.75px;
    }

    100% {
      left: 185px;
    }
  }
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;