@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;

.pageFiveBackground {
  display: grid;
  padding: 24px;
  padding-bottom: 60px;
  margin-top: 50px;
}

.pageFive_sittingBoys {
  position: relative;
  z-index: 8;
}

.pageFive_card_body {
  margin-top: -9px;
}

.mobileCard {
  display: none;
}

.page_five_benefits2 {
  display: none;
}

.page_five_benefits {
  font-weight: 900;
  font-size: 40px;
}

.mobileCardContent {
  text-align: justify;
  hyphens: auto;
  line-height: 20px;
  font-weight: 500;
  color: #4e7884;
}

.pageFive_hBar_wrapper {
  padding: 0 15px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: -60px;
  margin-bottom: 10px;
}

.pageFive_hBar {
  width: 790px;
  height: 5px;
  background-color: #9fe1c6;
  // z-index: -100;
}

.pageFive_img_card {
  contain: content;
  display: flex;
  justify-content: center;
  border: 2px solid #f6f6f6;
  width: 140px;
  height: 140px;
  padding: 12px;
  // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-right: 30px;
  border-radius: 6px;
  background-color: white;
}

.page_five_title_one {
  font-size: 23px;
  font-weight: 700;
  color: @brand-primary;
  margin-right: 6px;
}

.page_five_title_two {
  font-size: 23px;
  font-weight: 700;
  color: @brand-secondary;
}

.page_five_hEl {
  width: 50px;
  height: 5px;
  position: relative;
  z-index: 7;
  background-color: @brand-primary;
  animation: pageFive 2s infinite alternate linear;
}

.pageFive_cards_div {
  // padding: 0px 40px 0px 40px;
    // background-color: red;
    max-width: 1300px;
    justify-self: center;
    align-self: center;
}

@keyframes pageFive {
  0% {
    left: 0;
  }

  25% {
    left: 165px;
  }

  50% {
    left: 330px;
  }

  75% {
    left: 510px;
  }

  100% {
    left: 720px;
  }
}

@media screen and (max-width: 576px) {

  .pageFiveBackground {
    // background-color: yellow;
    background-image: url("../../../Assets/X-Brandiung-gradient.webp");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 130% 47%;
    margin-bottom: -80px;
    margin-top: 30px;
  }

  .desktopCard {
    display: none;
  }

  .mobileCard {
    display: flex;
    align-items: center;
  }

  .pageFive_img_card_mobile {
    // display: none;
    contain: content;
    display: flex;
    justify-content: center;
    border: 2px solid #f6f6f6;
    padding: 4px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    margin-right: 15px;
    border-radius: 6px;
    background-color: white;
  }

  .mobileCardContent {
    text-align: justify;
    margin-top: 10px;
    hyphens: auto;
    font-size: 14px;
    color: #4e7884;
    font-weight: 600;
  }

  .pageFive_cards_div {
    padding: 12px;
    margin-bottom: 24px;
  }

  .pageFive_cards_div {
    margin-top: -80px;
  }

  .page_five_benefits {
    display: none;
  }

  .page_five_benefits2 {
    display: flex;
    justify-content: center;
  }

  .pageFive_benifit_text {
    width: 250px;
    font-weight: 900;
    font-size: 29px;
    line-height: 32px;
  }

  .pageFive_hBar {
    width: 230px;
    height: 3px;
    margin-bottom: 24px;
    
   
  }

  .page_five_hEl {
    height: 3px;
    // margin-top: -1px;
  }

  .pageFive_img_card {
    display: none;
  }

  .page_five_benefits {
    font-size: 27px;
  }

  .page_five_title_one,
  .page_five_title_two {
    font-size: 21px;
  }

  @keyframes pageFive {
    0% {
      left: 0px;
    }

    25% {
      left: 45px;
    }

    50% {
      left: 90px;
    }

    75% {
      left: 135px;
    }

    100% {
      left: 180px;
    }
  }

  .pageFive_sittingBoys {
    // display: flex;
    // margin-top: 20px;
  }

  .pageFive_hBar {
    margin-top: -90px;
    // width: 215px;
  }
}

@media screen and (max-width: 576px) {
  .pageFive_sittingBoys {
    display: none;
  }

  .pageFive_hBar_wrapper {
    margin-top: 62px;
    margin-bottom: 20px;
  }
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;