@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;

.lPageSeven_bg {
  display: grid;
  position: relative;
  width: 100%;
  height: 500px;
  min-height: auto;
  padding: 120px 40px 15px 40px;
  background: #eafdf5;
}

.pageSeven_Body {
  max-width: 1300px;
  justify-self: center;
}

//////

.xBrandingL {
  // position: relative;
  width: 120px;
  height: 120px;
  top: 270px;
  left: 100vw;
}

.grass1 {
  position: absolute;
  width: 75px;
  height: 75px;
  bottom: -3px;
  left: 250px;
}

.grass2 {
  position: absolute;
  width: 175px;
  height: 175px;
  bottom: -28px;
  left: 330px;
}

.grass3 {
  position: absolute;
  width: 60px;
  height: 60px;
  bottom: -3px;
  right: 5px;
}

//////

.lPageSeven_Italic {
  font-style: italic;
  font-weight: 800;
  color: @brand-primary;
  font-size: 38px;
  line-height: 1.2;
}

.lPageSeven_title_one {
  font-weight: 900;
  color: @brand-secondary;
  font-size: 44px;
  line-height: 1.1;
}

.lPageSeven_title_two {
  font-weight: 600;
  font-size: 18px;
}

.lPageSeven_img {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 380px;
  left: -315px;
  bottom: 0;
  height: 500px;
  z-index: 1;
  // margin-top: -30px;
  top: 70px
}

.lPageSeven_hBar_wrapper {
  justify-content: flex-start;
  align-items: center;
  display: flex;
  margin-bottom: 10px;
  margin-top: 10px;
}

.lPageSeven_hBar {
  width: 355px;
  height: 5px;
  background-color: #9fe1c6;
}

.lPageSeven_hEl {
  width: 50px;
  height: 5px;
  position: relative;
  background-color: @brand-primary;
  animation: lPageSeven 2s infinite alternate linear;
}

@keyframes lPageSeven {
  0% {
    width: 0;
  }

  25% {
    width: 88.75px;
  }

  50% {
    width: 177.5px;
  }

  75% {
    width: 266.25px;
  }

  100% {
    width: 355px;
  }
}

@media screen and (max-width: 1200px) {
  .lPageSeven_bg {
    padding: 120px 15px 15px 10px;
  }

  .lPageSeven_Italic {
    font-size: 30px;
  }

  .lPageSeven_title_one {
    font-size: 36px;
  }

  .lPageSeven_title_two {
    font-size: 17px;
  }

  .lPageSeven_hBar {
    width: 360px;
  }

  @keyframes lPageSeven {
    0% {
      width: 0;
    }

    25% {
      width: 90px;
    }

    50% {
      width: 180px;
    }

    75% {
      width: 270px;
    }

    100% {
      width: 360px;
    }
  }

  .lPageSeven_img {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .lPageSeven_Italic {
    font-size: 27px;
  }

  .lPageSeven_title_one {
    font-size: 30px;
  }

  .lPageSeven_title_two {
    font-size: 16px;
  }

  .lPageSeven_hBar {
    width: 320px;
  }

  @keyframes lPageSeven {
    0% {
      width: 0;
    }

    25% {
      width: 80px;
    }

    50% {
      width: 160px;
    }

    75% {
      width: 240px;
    }

    100% {
      width: 320px;
    }
  }

  .lPageSeven_img {
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .lPageSeven_bg {
    padding: 20px 15px 15px 10px;
  }

  .lPageSeven_Italic {
    text-align: center;
    font-size: 30px;
  }

  .lPageSeven_title_one {
    text-align: center;
    font-size: 36px;
  }

  .lPageSeven_title_two {
    text-align: center;
    font-size: 17px;
  }

  .lPageSeven_hBar_wrapper {
    justify-content: center;
  }

  .lPageSeven_hBar {
    width: 360px;
  }

  @keyframes lPageSeven {
    0% {
      width: 0;
    }

    25% {
      width: 90px;
    }

    50% {
      width: 180px;
    }

    75% {
      width: 270px;
    }

    100% {
      width: 360px;
    }
  }
}

@media screen and (max-width: 576px) {

  .pageSeven_Body {
    margin-left: 5px;
  }

  .joinTodaySection {
    margin-top: -20px;
  }

  .lPageSeven_bg {
    display: grid;
    padding: 25px 15px 20px 10px;
    margin-bottom: -80px;
    margin-top: -17px;
    z-index: 8;
    height: auto;
    // background-color: white;
    // background: url("../../../Assets/form layout.webp");
    // background-repeat: no-repeat;
    // background-size: contain;
    // box-shadow: none;
  }

  .lPageSeven_Italic {
    text-align: center;
    font-size: 27px;
  }

  .lPageSeven_title_one {
    text-align: center;
    font-size: 31px;
  }

  .lPageSeven_title_two {
    text-align: center;
    // width: 200px;
    padding: 0px 25px;
    font-size: 13px;
  }

  .lPageSeven_hBar_wrapper {
    justify-content: center;
  }

  .lPageSeven_hBar {
    width: 250px;
    height: 3px;
  }
  .lPageSeven_hEl {
    height: 3px;
  }

  .xBrandingL {
    display: none;
  }
  
  .grass1 {
    display: none;
  }
  
  .grass2 {
    display: none;
  }

  .grass3 {
    display: none;
  }

  // .xBrandingL {
  //   display: none;
  // }
  
  // .grass1 {
  //   display: none;
  // }
  
  // .grass2 {
  //   display: none;
  // }

  // .grass3 {
  //   display: none;
  // }

  @keyframes lPageSeven {
    0% {
      width: 0;
    }

    25% {
      width: 62.5px;
    }

    50% {
      width: 125px;
    }

    75% {
      width: 187.5px;
    }

    100% {
      width: 250px;
    }
  }
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;