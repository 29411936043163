@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;

.sPageTwo_bg {
  display: grid;
  position: relative;
  width: 100%;
  min-height: auto;
  padding: 30px 30px;
  background: #eafdf5;
}

.sPageTwo_Body {
  max-width: 1300px;
  justify-self: center;
  margin-bottom: 10px;
  margin-left: 80px;
}

.sPageTwo_Heading {
  text-align: center;
  font-weight: 900;
  font-size: 36px;
  margin-top: 5px;
}

.sPageTwo_hBar_wrapper {
  padding: 0 15px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 20px;
}

.sPageTwo_hBar {
  width: 655px;
  height: 5px;
  background-color: #9fe1c6;
}

.sPageTwo_Card {
  text-align: justify;
  font-weight: 500;
  padding: 45px 40px 40px 40px;
  font-size: 20px;
  background: @brand-white;
  line-height: 1.5;
  max-width: 610px;
  box-shadow: 0 3px 6px 2px rgba(0, 0, 0, 0.2);
}

.sPageTwo_card_sNumber {
  position: absolute;
  top: -100px;
  left: 10px;
}

.aPageTwo_numImg {
  width: 100px;
}

.sPageTwo_img {
  // margin-left: -10px
}

.sPageTwo_card_heading {
  font-size: 36px;
  font-weight: 700;
  color: #21ce86;
  position: absolute;
  top: -62px;
  left: 120px;
}

.sPageTwo_hEl {
  width: 50px;
  height: 5px;
  position: relative;
  background-color: @brand-primary;
  animation: sPageTwo 2s infinite alternate linear;
}

@keyframes sPageTwo {
  0% {
    left: 0;
  }

  25% {
    left: 151.25px;
  }

  50% {
    left: 302.5px;
  }

  75% {
    left: 453.75px;
  }

  100% {
    left: 605px;
  }
}

@media screen and (max-width: 576px) {
  .aPageTwo_numImg {
    margin-top: -50px;
  }

  .sPageTwo_card_heading {
    margin-left: 20px;
  }
}

@media screen and (max-width: 992px) {
  .aPageTwo_numImg {
    margin-top: -50px;
  }

  .sPageTwo_card_heading {
    margin-left: 20px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .sPageTwo_Heading {
    font-size: 33px;
  }

  .sPageTwo_Card {
    padding: 36px 30px 30px 30px;
    font-size: 16px;
    line-height: 1.4;
    max-width: 620px;
  }

  .aPageTwo_numImg {
    width: 80px;
  }

  .sPageTwo_card_heading {
    font-size: 30px;
    top: -50px;
    left: 120px;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  //   .sPageTwo_Heading {
  //     font-size: 30px;
  //   }

  //   .sPageTwo_Card {
  //     padding: 36px 30px 30px 30px;
  //     font-size: 16px;
  //     line-height: 1.4;
  //     max-width: 620px;
  //   }

  //   .sPageTwo_card_sNumber {
  //     position: absolute;
  //     top: -70px;
  //     left: 10px;
  //   }

  //   .aPageTwo_numImg {
  //     width: 80px;
  //   }

  //   .sPageTwo_card_heading {
  //     font-size: 30px;
  //     top: -50px;
  //     left: 120px;
  //   }
}

@media screen and (max-width: 576px) {
  .sPageTwo_bg {
    padding: 15px 15px 30px 15px;
  }

  .sPageTwo_Body {
    padding: 0px 20px;
    margin-left: 0px;
  }

  .sPageTwo_card_heading {
    left: 0px;
    top: 0px;
    margin: 0px;
    font-size: 18px;
  }

  .sPageTwo_Heading {
    font-size: 27px;
    line-height: 1.3;
  }

  .sPageTwo_hBar_wrapper {
    margin-top: 7px;
    padding: 0px;
  }

  .sPageTwo_hBar {
    width: 315px;
    height: 3px;
    padding: 0px;
    margin-top: -5px;
  }

  .sPageTwo_hEl {
    height: 3px;
  }

  .sPageTwo_card_sNumber:nth-child(1) {
    position: absolute;
    // bottom: 1px;
    top:0px;
    left: 20px;
    width: 74px;
  }

  .aPageTwo_numImg {
    // width: 70px;
  }

  .sPageTwo_card_heading {
    font-size: 19px;
    line-height: 1.2;
    margin-top: 10px;
    font-weight: 700;
    color: #21ce86;
    position: absolute;
    top: -40px;
    left: 70px;
  }

  .sPageTwo_Card {
    padding: 30px 20px 20px 20px;
    font-size: 14px;
    font-weight: 500;
    // margin-bottom: 50px;
  }

  @keyframes sPageTwo {
    0% {
      left: 0px;
    }

    25% {
      left: 66.25px;
    }

    50% {
      left: 132.5px;
    }

    75% {
      left: 198.75px;
    }

    100% {
      left: 265px;
    }
  }
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;