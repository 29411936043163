@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;
@percentage: 86%;

.page_three_ourservices {
  font-weight: 900;
  font-size: 42px;
  color: @brand-white;
  margin-top: 30px;
  // background-color: black;
}

.hbarOuter {
  margin-bottom: 30px;
}

.ourService_char_card {
  width: 41%;
  height: 100%;
  // position:absolute;
  z-index: 10;
  margin-right: -40px;
  margin-left: -180px;
  margin-top: -50px;
  // transform:scale(0.9);
}

.pageThree_hBar {
  width: 335px;
  height: 5px;
  background-color: #9fe1c6;
  margin-top: -6px;
}

.pageThree_bg_Home {
  // width: 100%;
  display: grid;
  justify-content: center;
  background: linear-gradient(45deg, #4be7a7, #01c674);
  // background-size: auto;
  background-image: url("../../../Assets/Our-Services-BG.webp");
  background-size: 100% 100%;
}

.pageThree_cCard {
  width: 300px;
  height: 248px;
  contain: content;
  // min-height: 300px;
  // padding: 5px;
  // border: 2px solid #f6f6f6;
  background: @brand-white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  // box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  // box-shadow: 5px 6px 6px 12px grey, 3px 6px 6px 12px grey;
  border-radius: 6px;
  // margin: 5px;
  transform: scale(0.95);
  padding-top: 30px;
  padding-right: 0px;
  padding-left: 0px;
}

.pageThree_cCardHeading_One {
  display: flex;
  justify-content: center;
  font-weight: 700;
  font-size: 23px;
  color: @brand-primary;
  margin-top: -15px;
}

.pageThree_cCardHeading_Two {
  display: flex;
  justify-content: center;
  font-weight: 700;
  font-size: 25px;
  color: @brand-ternary;
  margin-top: -5px;
}

.pageThree_cCardText {
  color: #4e7884;
  font-size: 14px;
  text-align: justify;
  hyphens: auto;
  font-weight: 500;
  // line-height: 1.4;
  // word-spacing:2px;
}

.ant-carousel .slick-dots li button {
  background: #01c674;
  opacity: 0.4;
}

.ant-carousel .slick-dots li.slick-active button {
  opacity: 1;
  background: #01c674;
}

.page_three_hEl {
  width: 50px;
  height: 5px;
  position: relative;
  background-color: @brand-white;
  animation: pageThree 2s infinite alternate linear;
}

@keyframes pageThree {
  0% {
    left: 0px;
  }

  25% {
    left: 71.25px;
  }

  50% {
    left: 142.5px;
  }

  75% {
    left: 213.75px;
  }

  100% {
    left: 285px;
  }
}

/////////////////////////////

.outerDiv {
  display: flex;
  max-width: 1200px;
  max-height: 500px;
  margin-top: 2px;
  margin-bottom: 45px;
  margin-right: -31px;
  // transform: scale(0.9);
  // background-color: red;
  // justify-self: center;
  // background-color: red;
}

.cardsDiv {
  // display: flex;
  // width:1000px
}

.allCardsDiv {
  // background-color: red;
  display: flex;
  // flex-direction: column;
  flex-wrap: wrap;
  max-width: 900px;
  // padding: 50px;
  // max-width: 10000px;
  // width: auto;
}

/////////////////////////////

@media (max-width: 992px) and (min-width: 900px) {}

@media screen and (max-width: 576px) {

  .outerDiv {
    // margin-top: -40px;
    // background-color: red;
    max-width: 310px;
    max-height: 2000px;
    justify-self: center;
    margin-right: 0px;
    padding: 0px;
    margin-bottom: 19px;
    margin-top: -1px;
  }

  .allCardsDiv {
    justify-self: center;
    display: grid;
  }

  .pageThree_cCard {
    width: 300px;
    height: 235px;
    margin-bottom: 10px;
    // padding-right: 20px;
    // padding-left: 20px;
  }

  .pageThree_cCardHeading_One {
    font-size: 19px;
  }

  .pageThree_cCardHeading_Two {
    font-size: 19px;
  }

  .pageThree_cCardText {
    font-size: 14px;
    hyphens: auto;
    font-weight: 500;
    padding-top: 8px;
    // line-height: 1px;
  }

  .pageThree_bg_Home {
    // background-image: none;
    background-color: @brand-primary;
    background-image: url("../../../Assets/our-service-bg(mobile).webp");
    background-size: 100% 100%;
    background-position: center;
  }

  .page_three_ourservices {
    font-weight: 900;
    font-size: 29px;
    margin-top: 22px;
  }

  .pageThree_hBar {
    width: 225px;
    height: 3px;
  }

  .page_three_hEl {
    height: 3px;
  }

  .ourService_char_card {
    display: none;
  }

  .hbarOuter {
    margin-bottom: 23px;
  }

  @keyframes pageThree {
    0% {
      left: 0px;
    }

    25% {
      left: 43.75px;
    }

    50% {
      left: 87.5px;
    }

    75% {
      left: 131.25px;
    }

    100% {
      left: 175px;
    }
  }
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;