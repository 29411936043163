@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;

.ourMissionBody_Mobile {
  display: none;
}

.ourMissionBody_Desktop {
  // display: grid;
  justify-self: center;
  max-width: 1300px;
  // background-color: red;
}

.aPageSeveBody {
  display: grid;
}

.apageSeven_bg {
  background-color: #eafdf5;
  padding-top: 40px;
  // padding-bottom: 70px;
  display: grid;
}

.aPageSeven_heading {
  // margin-top: 20px;
  font-size: 36px;
  font-weight: 900;
  text-align: center;
}

.aPageSeven_hBar_wrapper {
  padding: 0 15px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 15px;
}

.aPageSeven_hBar {
  width: 260px;
  height: 5px;
  background-color: #9fe1c6;
}

.aPageSeven_hEl {
  width: 50px;
  height: 5px;
  position: relative;
  background-color: @brand-primary;
  animation: aPageSeven 2s infinite alternate linear;
}

.aPageSeven_text_one {
  width: fit-content;
  font-size: 18px;
  font-weight: 600;
  text-align: justify;
  margin-top: -35px;
}

.aPageSeven_text_two {
  text-align: justify;
  font-size: 18px;
  font-weight: 600;
  margin-top: 20px;
}

.aPageSeven_text_three {
  text-align: justify;
  font-size: 18px;
  font-weight: 600;
  margin-top: 20px;
  display: none;
}

.aPageSeven_img {
  max-width: 450px;
  max-height: 510px;
  display: flex;
  align-items: center;
}

@keyframes aPageSeven {
  0% {
    left: 0px;
  }

  25% {
    left: 52.5px;
  }

  50% {
    left: 105px;
  }

  75% {
    left: 157.5px;
  }

  100% {
    left: 210px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .aPageSeven_heading {
    font-size: 33px;
  }

  .aPageSeven_text_one {
    font-size: 17px;
  }

  .aPageSeven_text_two {
    display: none;
  }

  .aPageSeven_text_three {
    display: block;
    font-size: 17px;
  }

  .aPageSeven_img {
    max-width: 220px;
    max-height: 250px;
  }

  .aPageSeven_hBar {
    width: 230px;
  }

  @keyframes aPageSeven {
    0% {
      left: 0px;
    }

    25% {
      left: 45px;
    }

    50% {
      left: 90px;
    }

    75% {
      left: 135px;
    }

    100% {
      left: 180px;
    }
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .aPageSeven_heading {
    font-size: 30px;
  }

  .aPageSeven_text_one {
    font-size: 17px;
  }

  .aPageSeven_text_two {
    display: none;
  }

  .aPageSeven_img {
    max-width: 200px;
    height: 280px;
  }

  .aPageSeven_text_three {
    display: block;
    font-size: 17px;
  }

  .aPageSeven_hBar {
    width: 230px;
  }

  @keyframes aPageSeven {
    0% {
      left: 0px;
    }

    25% {
      left: 45px;
    }

    50% {
      left: 90px;
    }

    75% {
      left: 135px;
    }

    100% {
      left: 180px;
    }
  }
}

@media screen and (max-width: 576px) {
  .ourMissionBody_Desktop {
    display: none;
  }

  .ourMissionBody_Mobile {
    display: block;
    padding: 0px 30px;
    text-align: justify;
    hyphens: auto;
    word-spacing: -1px;
    font-size: 14px;
    color: black;
    padding-bottom: 30px;
    font-weight: 600;
    color: #4e7884;
  }

  img {
    // float: right;
    // z-index: inherit;
  }

  .apageSeven_bg {
    background-color: #eafdf5;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .aPageSeven_heading {
    font-size: 26px;
    margin-top: 0px;
    padding-top: 30px;
  }

  .aPageSeven_text_one {
    font-size: 16px;
  }

  .aPageSeven_text_two {
    display: none;
  }

  .aPageSeven_img {
    max-width: 200px;
    height: 240px;
  }

  .aPageSeven_text_three {
    display: block;
    font-size: 16px;
  }

  .aPageSeven_hBar_wrapper {
    margin-bottom: 0px;
  }

  .aPageSeven_hBar {
    width: 180px;
    height: 3px;
    margin-bottom: 20px;
    margin-top: -2px;
  }

  .aPageSeven_hEl {
    height: 3px;
  }

  @keyframes aPageSeven {
    0% {
      left: 0px;
    }

    25% {
      left: 30px;
    }

    50% {
      left: 60px;
    }

    75% {
      left: 95px;
    }

    100% {
      left: 130px;
    }
  }
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;