@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;

.aPageOne_bg {
  // position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 500px;
  // min-height: 615px;
  padding: 15px;
  background-image: url("../../../Assets/common-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 100% 100%;
  margin-top: -0.3%;
  // background: @brand-primary;
  // border-bottom: 5px solid #009688;
}

.aPageOne_Italic {
  font-style: italic;
  font-weight: 500;
  color: @brand-white;
  font-size: 27px;
  margin-left: 5px;
}

.aPageOne_title_one {
  font-weight: 900;
  color: @brand-white;
  font-size: 46px;
  line-height: 1.2;
  margin-left: 0px;
}

.aPageOne_title_two {
  font-weight: 900;
  color: @brand-ternary;
  font-size: 50px;
  line-height: 1.2;
  margin-top: 6px;
}

.aPageOne_title_three {
  font-weight: 400;
  color: @brand-white;
  font-size: 19px;
  line-height: initial;
  margin-top: 6px;
  margin-left: 5px;
  // text-align:justify;
  max-width: 698px;
}

.aPageOne-box {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  width: 200px;
  padding: 10px 0px 5px;
  margin-top: 50px;
  margin-left: 10px;
  border-bottom: 2px solid#c0bebe;
  background-color: @brand-white;
  // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;
}

.aPageOne-box_text {
  font-weight: 800;
  font-size: 22px;
  margin-top: -5px;
  color: @brand-primary;
  cursor: pointer;
}

.rightSection {
  margin-bottom: -30px;
}

.leftSection {
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-top: -12px;
}

@media (min-width: 1100px) and (max-width: 1290px) {
  .aPageOne_Italic {
    font-size: 24px;
  }

  .aPageOne_title_one {
    font-size: 36px;
  }

  .aPageOne_title_two {
    font-size: 36px;
  }

  .aPageOne_title_three {
    font-size: 17px;
    width: 650px
  }

  .aPageOne-box {
    width: 190px;
    margin-top: 37px;
  }

  .aPageOne-box_text {
    // font-size: 24px;
  }

  .leftSection {
    margin-left: 50px;
  }

  .aPageOne_bg {
    height: 430px;
  }
}

// @media screen and (max-width: 992px) {
//   .aPageOne_bg{
//     margin-top: -4%;
//   }
//   .aPageOne_Italic {
//     font-size: 24px;
//     text-align: center;
//   }

//   .aPageOne_title_one {
//     font-size: 36px;
//     text-align: center;
//   }

//   .aPageOne_title_two {
//     text-align: center;
//     font-size: 36px;
//   }

//   .aPageOne_title_three {
//     text-align: center;
//     font-size: 16px;
//   }

//   .aPageOne-box {
//     margin: 30px auto 0px;
//     width: 190px;
//   }
//   .aPageOne-box_text {
//     font-size: 24px;
//   }

//   .aPageOne_img {
//     max-width: 350px;
//     max-height: 300px;
//   }
//   .aPageOne-box{
//     transform: scale(0.8);
//   }
// }

@media screen and (max-width: 576px) {

  .aPageOne_bg {
    margin-top: -5.5%;
    height: 530px;
    background-image: url("../../../Assets/background.webp");
    // background-size: cover;
    flex-direction: column;
    justify-content: end;
  }

  .leftSection {
    margin-bottom: -40px;
  }

  .rightSection {
    margin-bottom: -20px;
    width: 280px;
    align-self: center;
  }

  .aPageOne-box {
    margin: 30px auto 0px;
    width: 190px;
    transform: scale(1.1);
  }

  .aPageOne-box_text {
    font-size: 14px;
  }

  .aPageOne-box {
    margin-top: 12px;
    margin-bottom: 70px;
    // font-size: smaller;
    // transform: scale(0.6);
    padding: 8px 0px 1.5px 0px;
    width: 120px;
    border-radius: 4px;
    // box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 5px;
    // box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.2), 0 1px 2px 1px rgba(0, 0, 0, 0.2);
    // -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.28);
    // -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.28);
    // box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.28);


  }

  .aPageOne_Italic {
    font-size: 17px;
    font-weight: 600;
    text-align: center;
  }

  .aPageOne_title_one {
    font-size: 25px;
    text-align: center;
    font-weight: 800;
  }

  .aPageOne_title_two {
    text-align: justify;
    color: @brand-white;
    font-size: 25px;
    font-weight: 700;
  }

  .aPageOne_title_three {
    margin-top: 0px;
    text-align: center;
    font-size: 14.5px;
    // width: 200px;
    // background-color: coral;s
    padding: 7px;
    line-height: 1.45;
    // justify-self: center;
    font-weight: 500
  }

  .aPageOne_img {
    max-width: 330px;
    max-height: 280px;
  }
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;