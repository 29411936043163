@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;

.XBrand_PageFourFive {
    background-image: url("../../Assets/X-Brandiung-gradient.webp");
    background-position: top;
}

@media (max-width: 576px) and (min-width: 992px) {
    .XBrand_PageFourFive {
        background-image: none;
    }
}

@media screen and (max-width: 576px) {
    .XBrand_PageFourFive {
        background-image: none;
    }
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;