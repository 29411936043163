@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;

.NotFoundPage {
    // position: relative;
    // display: block;
    flex-wrap: wrap;
    margin-top: -0.3%;
    // background-color: yellow;
    justify-content: center;
    justify-items: center;
    align-items: center;
    position: relative;
    z-index: 8;
    margin-bottom: -70px;
    // margin-top: 50px;

    
    
}

.MobileErrorPage {
    display: none;
}

// aBOVE part is for DESKTOP view

// =======================================================

.PageOne_404error_button {
    font-weight: 700;
    font-size: 22px;
    border-radius: 8px;
    // border-bottom: 3.5px solid #315560;
    color: @brand-white;
    background-color: #4e7884;
    box-shadow: rgb(18 8 8 / 24%) 0px 3px 8px;
    // box-shadow: rgb(0 0 0 / 40%) 0px 3px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;
    cursor: pointer;
    cursor: pointer;
    padding: 5px 10px 4px 12px;
    // width: auto;
    // margin-top: -100px;
    // margin-bottom: 50px;
    // transform: scale(0.8);
    font-family: "Montserrat", sans-serif;
    box-sizing: border-box;
    text-transform: uppercase;
    border-bottom: 6px solid #315560;
    width: 240px;
    // margin-bottom: 500px;
    // margin: auto;
    position: absolute;
    bottom: 37px;
    left: 42%;
    align-items: center;
    // align-items: center;
    
}


// =======================================================

// BeloW part is for mobile view

@media screen and (max-width: 576px) {

    .NotFoundPage {
        margin-top: -5.8%;
        margin-bottom: -70px;

    }

    .DesktopErrorPage {
        display: none;
    }

    .MobileErrorPage {
        display: block;
    }
    .PageOne_404error_button {
        font-weight: 700;
        font-size: 15px;
        border-radius: 6px;
        color: @brand-white;
        background-color: #4e7884;
         // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        // box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;
        cursor: pointer;
        padding: 4px 0px 4px 0px;
        width: 165px;
        // margin-top: -146px;
        text-align: center;
        font-family: "Montserrat" ,sans-serif;
        border-bottom: 4px solid #315560;
        position: absolute;
        bottom: 28px;
        left: 28%;
        // margin-bottom: 50px;
        // transform: scale(0.8);
    }
    
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;