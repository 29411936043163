@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;

.sPageSix_bg {
  display: grid;
  position: relative;
  width: 100%;
  min-height: auto;
  padding: 30px 30px 60px 30px;
  background-image: url("../../../Assets/X-Brandiung-gradient.webp");
  background-repeat: no-repeat;
  background-position: center center;
  /* or simply */
  background-size: 150% 300%;
}

.sPageSix_Body {
  // width: 1300px;
  // justify-self: center;
  margin-bottom: 0px;
}

.sPageSix_Heading {
  text-align: center;
  font-weight: 900;
  font-size: 36px;
  margin-top: 10px;
}

.sPageSix_hBar_wrapper {
  padding: 0 15px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 45px;
  // margin-top: 10px;
}

.sPageSix_hBar {
  width: 635px;
  height: 5px;
  background-color: #9fe1c6;
}

.sPageSix_hEl {
  width: 50px;
  height: 5px;
  position: relative;
  background-color: @brand-primary;
  animation: sPageSix 2s infinite alternate linear;
}

@keyframes sPageSix {
  0% {
    left: 0;
  }
  25% {
    left: 146.25px;
  }
  50% {
    left: 292.5px;
  }
  75% {
    left: 438.75px;
  }
  100% {
    left: 585px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .sPageSix_Heading {
    font-size: 33px;
    line-height: 1.4;
  }

  .sPageSix_hBar {
    width: 450px;
  }
  @keyframes sPageSix {
    0% {
      left: 0;
    }
    25% {
      left: 100px;
    }
    50% {
      left: 200px;
    }
    75% {
      left: 300px;
    }
    100% {
      left: 400px;
    }
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .sPageSix_Heading {
    font-size: 30px;
    line-height: 1.4;
  }
  .sPageSix_hBar {
    width: 410px;
  }
  @keyframes sPageSix {
    0% {
      left: 0;
    }
    25% {
      left: 90px;
    }
    50% {
      left: 180px;
    }
    75% {
      left: 270px;
    }
    100% {
      left: 360px;
    }
  }
}

@media screen and (max-width: 576px) {
  .sPageSix_bg {
    padding: 25px 0px 0px 0px;
    background-image: none;
  }

  .sPageSix_Body {
    margin-top: -15px
  }

  .sPageSix_Heading {
    font-size: 27px;
    line-height: 1.3;
    margin-top: 0px;
  }
  .sPageSix_hBar {
    width: 300px;
    height: 3px;
    margin-top: 0px;
  }


  .sPageSix_hEl {
height: 3px;
  }

  @keyframes sPageSix {
    0% {
      left: 0px;
    }
    25% {
      left: 62.5px;
    }
    50% {
      left: 125px;
    }
    75% {
      left: 187.5px;
    }
    100% {
      left: 250px;
    }
  }
}

@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;