@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;

.aPageEight_bg {
  display: grid;
  position: relative;
  width: 100%;
  min-height: auto;
  padding: 15px;
  padding-bottom: 7px;
  background-image: url("../../../Assets/X-Brandiung-gradient.webp");
  background-position: center;
  // margin-bottom: -70px;
  z-index: 8;
  // margin-bottom: -40px;
}

.entireTeamDiv {
  max-width: 1200px;
  justify-self: center;
  margin-top: -36px;
}

.aPageEight_heading {
  margin-top: 30px;
  font-size: 36px;
  font-weight: 900;
  text-align: center;
}

.aPageEight_hBar_wrapper {
  padding: 0 15px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 15px;
}

.aPageEight_hBar {
  width: 210px;
  height: 5px;
  background-color: #9fe1c6;
}

.aPageEight_hEl {
  width: 50px;
  height: 5px;
  position: relative;
  background-color: @brand-primary;
  animation: aPageEight 2s infinite alternate linear;
}

@keyframes aPageEight {
  0% {
    left: 0;
  }

  25% {
    left: 40px;
  }

  50% {
    left: 80px;
  }

  75% {
    left: 120px;
  }

  100% {
    left: 160px;
  }
}

.aPageEight_row {
  margin-top: 120px;
}

.aPageEight_card_lg {
  width: 330px;
  height: 350px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  // box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  // box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  background: linear-gradient(to bottom, #21ce86 0% 70%, white 70%);
  display: flex;
  justify-content: space-around;
  // align-items: center;
  flex-direction: column;
  padding: 0 0 10px 0;
  transform: scale(0.90);
}

.aPageEight_img_lg {
  // width: 200px;
  height: 332px;
  margin-top: -92px;
  align-self: center;
}

.aPageEight_text_lg_one {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: -10px;
  color: @brand-primary;
  text-align: center;
}

.aPageEight_text_lg_two {
  font-size: 22px;
  font-weight: 500;
  color: @brand-primary;
  text-align: center;
}

.aPageEight_card_sm {
  width: 240px;
  height: 260px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  // box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  // box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  background: linear-gradient(to bottom, #21ce86 0% 70%, white 70%);
  display: flex;
  justify-content: space-around;
  // align-items: center;
  flex-direction: column;
  padding: 0 0 10px 0;
  transform: scale(0.90);
}

.aPageEight_img_sm {
  // width: 150px;
  height: 220px;
  margin-top: -44px;
  align-self: center;
}

.aPageEight_text_sm_one {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: -10px;
  color: @brand-primary;
  text-align: center;
}

.aPageEight_text_sm_two {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: -10px;
  color: @brand-primary;
  text-align: center;
}

@media (min-width: 768px) and (max-width: 992px) {
  .aPageEight_heading {
    font-size: 33px;
  }

  .aPageEight_hBar {
    width: 190px;
  }

  @keyframes aPageEight {
    0% {
      left: 0;
    }

    25% {
      left: 35px;
    }

    50% {
      left: 70px;
    }

    75% {
      left: 105px;
    }

    100% {
      left: 140px;
    }
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .aPageEight_heading {
    font-size: 30px;
  }

  .aPageEight_hBar {
    width: 180px;
  }

  @keyframes aPageEight {
    0% {
      left: 0;
    }

    25% {
      left: 30px;
    }

    50% {
      left: 60px;
    }

    75% {
      left: 90px;
    }

    100% {
      left: 120px;
    }
  }
}

@media screen and (max-width: 576px) {

  .aPageEight_bg {
    padding-bottom: 58px;
    margin-bottom: -70px;
    // z-index: 5;
  }

  .aPageEight_heading {
    margin-top: 20px;
    font-size: 30px;
  }

  .aPageEight_hBar {
    width: 170px;
    height: 3px;
    margin-top: -2px;
  }

  .aPageEight_hEl {
    width: 50px;
    height: 3px;
  }

  @keyframes aPageEight {
    0% {
      left: 0;
    }

    25% {
      left: 30px;
    }

    50% {
      left: 60px;
    }

    75% {
      left: 90px;
    }

    100% {
      left: 120px;
    }
  }

  .aPageEight_row {
    margin-top: 80px;
  }

  .aPageEight_card_lg {
    width: 250px;
    height: 280px;
    background: linear-gradient(to bottom, #21ce86 0% 70%, white 70%);
    transform: scale(0.85);
    margin-top: -25px;
    align-items: center;
  }

  .aPageEight_img_lg {
    // width: 180px;
    height: 250px;
    margin-top: -60px;
    // transform: scale(0.9);
  }

  .aPageEight_text_lg_one {
    font-size: 22px;
    margin-bottom: -10px;
  }

  .aPageEight_text_lg_two {
    font-size: 18px;
    margin-bottom: -10px;
  }

  .aPageEight_card_sm {
    width: 150px;
    height: 180px;
    background: linear-gradient(to bottom, #21ce86 0% 65%, white 65%);
    // padding: 0 0 10px 0;
  }

  .aPageEight_img_sm {
    // width: 120px;
    height: 160px;
    margin-top: -49px;
    align-self: center;
  }

  .aPageEight_text_sm_one {
    font-size: 13.5px;
    margin-bottom: -10px;
  }

  .aPageEight_text_sm_two {
    font-size: 10.5px;
    margin-bottom: -5px;
  }
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;