@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;

.pageThree_bg {
  background-image: url("../../../Assets/playable-on-BG.webp");
  position: relative;
  margin-bottom: -70px;
  z-index: 8;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
}

.pageThree_Port_bg {
  position: relative;
  z-index: 8;
  max-width: 1300px;
  height: 200px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  justify-self: center;
  //   flex-direction: column;
}

.pageThree_Port_text {
  //   text-align: center;
  font-size: 48px;
  font-weight: 900;
  color: @brand-white;
  //   -webkit-animation: glow 2s ease-in-out infinite alternate;
  //   -moz-animation: glow 2s ease-in-out infinite alternate;
  //   animation: glow 2s ease-in-out infinite alternate;
}

.pageThree_Port_text2 {
  text-align: left;
  // margin-left: 4px;
  font-size: 25px;
  font-weight: 600;
  color: @brand-white;
  margin-top: -8px;
  margin-left: 8px;
  //   -webkit-animation: glow 2s ease-in-out infinite alternate;
  //   -moz-animation: glow 2s ease-in-out infinite alternate;
  //   animation: glow 2s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    text-shadow: 0 0 30px #445769, 0 0 40px #445769, 0 0 50px #445769;
  }

  to {
    text-shadow: 0 0 30px #769bae, 0 0 40px #7c9dab, 0 0 50px #7aa2b3;
  }
}

.pageThree_Port_button {
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-top: 20px;
  margin-left: 200px;
  font-weight: 800;
  font-size: 33px;
  min-width: 250px;
  border-radius: 10px;
  height: 54px;
  border-bottom: 3px solid#c0bebe;
  color: @brand-primary;
  background-color: @brand-white;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  cursor: pointer;
  // margin-top: -0.5%;
  padding: 35px 55px 35px 55px;
  transform: scale(0.8);
}

@media screen and (max-width: 576px) {
  .pageThree_Port_text {
    font-size: 24px;
    text-align: center;
    text-transform: uppercase;
    // padding: 30px 40px 30px 40px;
  }

  .pageThree_Port_text2 {
    font-size: 14px;
    text-align: center;
    margin-top: -2.5px;
  }

  .pageThree_Port_button {
    font-size: 27px;
    // min-width: 200px;
    height: 48px;
    margin-top: 8px;
    transform: scale(0.65);
    padding: 24px 20px 24px 20px;
    border-radius: 7px;
    margin: 15px 0px 0px 0px;
    // width: 50px;
  }

  .pageThree_Port_bg {
    flex-direction: column;
    justify-content: center;
    background-size: auto;
    // margin-bottom: -80px;
    z-index: 6;
  }

  .pageThree_Port_bg {
    height: 185px;
  }

  .pageThree_bg {
    background-repeat: no-repeat;
    background-size: 350% 350%;
  }
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;