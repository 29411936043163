@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;

.aPageNine_mySwiper_Mobile {
  display: none;
}

.aPageNine_mySwiper_Desktop {
  padding-top: 5px;
  // padding-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  // width: 20%;
  height: 600px;
  // display: block;
  width: auto;
}


.aPageNine_SwiperContainer_Desktop {
  // display: grid;
  // justify-content:space-between;
  align-items: center;
  width: 300px;
  margin: 0px auto;
  background-color: #4a6c7c;
  // background-color: yellow;
  border-radius: 15px;
  box-shadow: 0 6px 10px 0 rgb(80, 88, 84);
  // margin-right: auto;
  height: auto;
}

.swiper-pagination-bullet {
  width: 30px;
  height: 3px;
  background-color: @brand-primary;
  border-radius: 0px;
  margin-top: 500px;
}

.swiper-pagination-bullet-active {
  background: @brand-primary;
}

.aPageNine_heading {
  margin-top: 30px;
  font-size: 36px;
  font-weight: 900;
  text-align: center;
}

.aPageNine_hBar_wrapper {
  padding: 0 15px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 15px;
}

.aPageNine_hBar {
  width: 490px;
  height: 5px;
  background-color: #9fe1c6;
  margin-bottom: 15px;
}

.aPageNine_hEl {
  width: 50px;
  height: 5px;
  position: relative;
  background-color: @brand-primary;
  animation: aPageNine 2s infinite alternate linear;
}

@keyframes aPageNine {
  0% {
    left: 0px;
  }

  // 25% {
  //   width: 75px;
  //   left: 110px;
  // }

  // 50% {
  //   width: 100px;
  //   left: 220px;
  // }

  // 75% {
  //   width: 75px;
  //   left: 330px;
  // }

  100% {
    left: 440px;
  }
}

.aPageNine_mySwiper {
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  // width: 20%;
  height: 520px;

}

.aPageNine_card {
  contain: content;
  width: 320px !important;
  height: 43px 0px;
  // box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 8px 4px rgba(0, 0, 0, 0.2), 0 4px 8px 4px rgba(0, 0, 0, 0.1);

  background: @brand-white;
  opacity: 1;
  transition: transform 1s;
  border-radius: 10px;
}

.aPageNine_quote {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 15px;
}

.aPageNine_content {
  font-size: 13px;
  font-weight: 500;
  padding: 0 15px;
  width: 100%;
  height: 180px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: justify;
  line-height: 1.4;
}

.aPageNine_img_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 210px;
  background-repeat: no-repeat;
  background-size: cover;
}

// .aPageNine_card:hover {
//   transform: scale(1.1);
//   opacity: 0.8;
//   box-shadow: 0 4px 8px 4px rgba(0, 0, 0, 0.1),
//     0 8px 16px 8px rgba(0, 0, 0, 0.08), 0 8px 16px 8px rgba(0, 0, 0, 0.08),
//     0 8px 16px 8px rgba(0, 0, 0, 0.08);
// }

.aPageNine_img {
  border-radius: 50%;
  background: @brand-white;
  width: 140px;
  height: 140px;
  border: 7px solid @brand-white;
  contain: content;
}

.aPageNine_text_one {
  color: @brand-white;
  font-size: 18px;
  font-weight: 600;
  margin-top: 10px;
}

.aPageNine_text_two {
  color: @brand-white;
  font-size: 21px;
  line-height: 1.3;
  font-weight: 500;
  margin-bottom: 10px;
}

.aPageNine_bg {
  position: relative;
  width: 100%;
  min-height: auto;
  padding: 15px 15px 16px 15px;
  background: @brand-white;
  display: grid;
  flex-wrap: wrap;
  justify-content: center;
}

.desktopAboutUsSwiper {
  max-width: 1052px;
  // background-color: red;
  // display: flex;
  margin-bottom: 42px;
}

.desktopAboutUsSwiper3 {
  max-width: 1080px;
  // background-color: red;
  // display: flex;
  margin-bottom: 42px;
  height: 500px;
}

.desktopAboutUsSwiper_2 {
  max-width: 1052px;
  // background-color: red;
  // display: none;
  margin-bottom: 42px;
}

.aPageNine_SwiperContainer {
  // display: grid;
  // justify-content:space-between;
  align-items: center;
  width: 400px;
  margin: 0px 10px;
  background-color: #4a6c7c;
  // background-color: yellow;
  border-radius: 15px;
  box-shadow: 0 6px 10px 0 rgb(80, 88, 84);
  // margin-right: auto;
  height: auto;
}

.aPageNine_SwiperContainer3 {
  // display: grid;
  // justify-content:space-between;
  align-items: center;
  width: 320px;
  margin: 0px 10px;
  background-color: #4a6c7c;
  // background-color: yellow;
  border-radius: 15px;
  box-shadow: 0 6px 10px 0 rgb(80, 88, 84);
  // margin-right: auto;
  height: auto;
}

.aPageNine_SwiperContainer_2 {
  // display: grid;
  // justify-content:space-between;
  align-items: center;
  width: 400px;
  margin: 0px 10px;
  background-color: #4a6c7c;
  // background-color: yellow;
  border-radius: 15px;
  box-shadow: 0 6px 10px 0 rgb(80, 88, 84);
  // margin-right: auto;
  height: auto;
}


.card_img_client {
  // background-color: red;
  // width: 100%;
  height: 138px;
  display: flex;
  justify-content: center;
  // box-shadow: 0 10px 0px 0 rgba(33, 206, 134), 0 6px 20px 0 rgb(80, 88, 84);
  border-bottom: 10px solid #21ce86;
  border-bottom-left-radius: 55px;
  border-bottom-right-radius: 55px;
  // margin-bottom: 0px;
  border-radius: 15px;
  background: @brand-white;
  padding: 10px 0px;

  // clip-path: inset(22% 0 35% 0);
  // background-position: center;

}

.card_img_client_2 {
  // background-color: red;
  // width: 100%;
  height: 138px;
  display: flex;
  justify-content: center;
  // box-shadow: 0 10px 0px 0 rgba(33, 206, 134), 0 6px 20px 0 rgb(80, 88, 84);
  border-bottom: 10px solid #21ce86;
  border-bottom-left-radius: 55px;
  border-bottom-right-radius: 55px;
  // margin-bottom: 0px;
  border-radius: 15px;
  background: @brand-white;
  padding: 10px 0px;

  // clip-path: inset(22% 0 35% 0);
  // background-position: center;

}


.cards_content {
  // position: fixed;
  // z-index: -1;
  // left: 90%;
  // top: 90%;
  // width: 100%;
  height: 283px;
  min-width: min-content;
  // margin: auto;
  // margin-top: 0px;
  background-color: #4a6c7c;
  display: flex;
  justify-content: center;
  // font-weight: bold;
  font-size: 13px;
  color: aliceblue;
  padding: 30px;
  border-radius: 10px 10px 15px 15px;
  // box-shadow: 0 6px 20px 0 rgb(80, 88, 84);
  text-align: justify;
  hyphens: auto;
  font-weight: 600;
  line-height: 1.6;

}

.cards_content_2 {
  // position: fixed;
  // z-index: -1;
  // left: 90%;
  // top: 90%;
  // width: 100%;
  height: 283px;
  min-width: min-content;
  // margin: auto;
  // margin-top: 0px;
  background-color: #4a6c7c;
  display: flex;
  justify-content: center;
  // font-weight: bold;
  font-size: 13px;
  color: aliceblue;
  padding: 30px;
  border-radius: 10px 10px 15px 15px;
  // box-shadow: 0 6px 20px 0 rgb(80, 88, 84);
  text-align: justify;
  hyphens: auto;
  font-weight: 600;
  line-height: 1.6;

}






// ------------------------------------------------------------------------------------------
@media screen and (max-width: 576px) {

  .animDesk {
    display: none;
  }

  .desktopAboutUsSwiper {
    display: none;
  }

  .desktopAboutUsSwiper_2 {
    display: none;
  }

  .desktopAboutUsSwiper3 {
    display:none;
  }


  .aPageNine_mySwiper_Mobile {
    padding-top: 5px;
    // padding-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    // width: 20%;
    height: 520px;
    // display: block;
    width: auto;




  }





  .aPageNine_heading {
    font-size: 24px;
  }

  .aPageNine_hBar {
    width: 328px;
    height: 3px;
  }

  .aPageNine_hEl {
    width: 50px;
    height: 3px;
    position: relative;
    background-color: @brand-primary;
    animation: aPageNine 2s infinite alternate linear;
  }

  @keyframes aPageNine {
    0% {
      left: 0px;
    }

    // 25% {
    //   left: 60px;
    // }

    // 50% {
    //   left: 120px;
    // }

    // 75% {
    //   left: 180px;
    // }

    100% {
      left: 278px;
    }
  }

  .aPageNine_sContainer {
    contain: content;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    height: 440px;
    // flex-wrap: wrap;
    width: 100%;
    position: relative;
    // overflow: hidden;

  }

  .aPageNine_slide {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    transition: opacity ease-in-out 1s;
  }

  .aPageNine_slide.active {
    opacity: 1;
  }

  .aPageNine_dContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row !important;
    flex-direction: column;
    position: relative;
    bottom: 10px;
  }

  .aPageNine_sDot {
    width: 30px;
    height: 3px;
    background: @brand-primary;
    opacity: 0.5;
    margin: 30px 5px 20px;
    margin-bottom: -15px;
    cursor: pointer;
  }

  .aPageNine_sDot.active {
    opacity: 1;
    cursor: pointer;
  }

  .aPageNine_card {
    contain: content;
    width: 330px !important;
    height: 400px;
  }

  .aPageNine_quote {
    width: 100%;
    height: 50px;
  }

  .aPageNine_content {
    font-size: 16px;
    height: 160px;
  }

  .aPageNine_img_wrapper {
    height: 190px;
    background-image: url("../../../Assets/testimonial-shape.webp");
  }

  .aPageNine_card:hover {
    transform: scale(1.05);
  }

  .aPageNine_img {
    width: 120px;
    height: 120px;
    border: 5px solid @brand-white;
  }

  .aPageNine_text_one {
    font-size: 16px;
    font-weight: 600;
  }

  .aPageNine_text_two {
    font-size: 18px;
    line-height: 1.2;
    margin-bottom: 10px;
  }

  .card_img_client {
    // background-color: red;
    width: 100%;
    margin: auto;
    height: 120px;
    display: flex;
    justify-content: center;
    // box-shadow: 0 10px 0px 0 rgba(33, 206, 134), 0 6px 20px 0 rgb(80, 88, 84);
    border-bottom: 10px solid #21ce86;
    border-bottom-left-radius: 55px;
    border-bottom-right-radius: 55px;
    // margin-bottom: 0px;
    border-radius: 15px;
    background: @brand-white;
    // clip-path: inset(22% 0 35% 0);
    // background-position: center 
  }

  .card_img_client_2 {
    // background-color: red;
    width: 100%;
    margin: auto;
    height: 120px;
    display: flex;
    justify-content: center;
    // box-shadow: 0 10px 0px 0 rgba(33, 206, 134), 0 6px 20px 0 rgb(80, 88, 84);
    border-bottom: 10px solid #21ce86;
    border-bottom-left-radius: 55px;
    border-bottom-right-radius: 55px;
    // margin-bottom: 0px;
    border-radius: 15px;
    background: @brand-white;
    // clip-path: inset(22% 0 35% 0);
    // background-position: center 
  }

  .cards_content {

    width: 100%;
    height: 318px;
    min-width: min-content;
    margin: auto;
    background-color: #4a6c7c;
    display: flex;
    justify-content: center;
    font-weight: bold;
    font-size: 13px;
    color: aliceblue;
    padding: 30px;
    border-radius: 10px 10px 15px 15px;
    // box-shadow: 0 6px 20px 0 rgb(80, 88, 84);
    text-align: justify;
    hyphens: auto;

  }

  .cards_content_2 {

    width: 100%;
    height: 318px;
    min-width: min-content;
    margin: auto;
    background-color: #4a6c7c;
    display: flex;
    justify-content: center;
    font-weight: bold;
    font-size: 13px;
    color: aliceblue;
    padding: 30px;
    border-radius: 10px 10px 15px 15px;
    // box-shadow: 0 6px 20px 0 rgb(80, 88, 84);
    text-align: justify;
    hyphens: auto;

  }

  .aPageNine_SwiperContainer_Mobile {
    // display: grid;
    // justify-content:space-between;
    align-items: center;
    width: 300px;
    margin: 0px auto;
    background-color: #4a6c7c;
    // background-color: yellow;
    border-radius: 15px;
    box-shadow: 0 6px 10px 0 rgb(80, 88, 84);
    // margin-right: auto;
    height: auto;
  }

  .aPageNine_bg {
    margin-bottom: -80px;
  }

}

// @media (min-width: 768px) and (max-width: 992px) {
//   .aPageNine_heading {
//     font-size: 33px;
//   }
//   .aPageNine_hBar {
//     width: 450px;
//     height: 3px;
//   }

//   .aPageNine_hEl {
//     width: 50px;
//     height: 3px;
//     position: relative;
//     background-color: @brand-primary;
//     animation: aPageNine 2s infinite alternate linear;
//   }

//   @keyframes aPageNine {
//     0% {
//       left: 0;
//     }
//     25% {
//       width: 75px;
//       left: 100px;
//     }
//     50% {
//       width: 100px;
//       left: 200px;
//     }
//     75% {
//       width: 75px;
//       left: 300px;
//     }
//     100% {
//       left: 400px;
//     }
//   }

//   .aPageNine_bg {
//     padding: 0;
//   }

//   .aPageNine_card {
//     contain: content;
//     width: 300px;
//     height: 450px;
//     border-radius: 5px;
//   }

//   .aPageNine_content {
//     font-size: 16px;
//     width: 100%;
//     height: 180px;
//   }

//   .aPageNine_img_wrapper {
//     width: 100%;
//     height: 210px;
//   }

//   .aPageNine_img {
//     width: 120px;
//     height: 120px;
//     border: 5px solid @brand-white;
//   }

//   .aPageNine_text_one {
//     font-size: 16px;
//   }

//   .aPageNine_text_two {
//     font-size: 18px;
//   }
// }

// @media (min-width: 576px) and (max-width: 768px) {
//   .aPageNine_heading {
//     font-size: 30px;
//   }
//   .aPageNine_hBar {
//     width: 410px;
//   }
//   @keyframes aPageNine {
//     0% {
//       left: 0;
//     }
//     25% {
//       left: 90px;
//     }
//     50% {
//       left: 180px;
//     }
//     75% {
//       left: 270px;
//     }
//     100% {
//       left: 360px;
//     }
//   }

//   .aPageNine_bg {
//     padding: 0;
//   }

//   .aPageNine_card {
//     contain: content;
//     width: 300px;
//     height: 450px;
//   }

//   .aPageNine_content {
//     font-size: 16px;
//     width: 100%;
//     height: 180px;
//   }

//   .aPageNine_img_wrapper {
//     width: 100%;
//     height: 210px;
//   }

//   .aPageNine_img {
//     width: 120px;
//     height: 120px;
//     border: 5px solid @brand-white;
//   }

//   .aPageNine_text_one {
//     font-size: 16px;
//   }

//   .aPageNine_text_two {
//     font-size: 18px;
//   }
// }
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;