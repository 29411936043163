@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;

.sPageFive_bg {
  display: grid;
  position: relative;
  width: 100%;
  min-height: auto;
  padding: 25px 30px 30px 30px;
}

.sPageFive_Body {
  max-width: 1300px;
  justify-self: center;
  margin-top: -10px;
}

.sPageFive_Heading {
  text-align: center;
  font-weight: 900;
  font-size: 36px;
  margin-top: 0px;
}

.sPageFive_hBar_wrapper {
  padding: 0 15px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 45px;
  // margin-top: 10px;
}

.sPageFive_hBar {
  width: 825px;
  height: 5px;
  background-color: #9fe1c6;
}

.sPageFive_img {
  // max-width: 450px;
  max-height: 471px;
  margin-top: -62px;
}

.sPageFive_content_one {
  font-weight: 600;
  font-size: 18px;
  text-align: justify;
}

.sPageFive_content_two {
  font-weight: 600;
  font-size: 18px;
  margin-top: 20px;
  text-align: justify;
}

.sPageFive_content_three {
  font-weight: 600;
  font-size: 18px;
  margin-top: 20px;
  text-align: justify;
}

.sPageFive_content_four {
  font-weight: 600;
  font-size: 18px;
  margin-top: 20px;
  display: none;
  text-align: justify;
}

.sPageFive_content_five {
  font-weight: 600;
  font-size: 18px;
  margin-top: 20px;
  text-align: justify;
}

.sPageFive_hEl {
  width: 50px;
  height: 5px;
  position: relative;
  background-color: @brand-primary;
  animation: sPageFive 2s infinite alternate linear;
}

@keyframes sPageFive {
  0% {
    left: 0;
  }

  25% {
    left: 193.75px;
  }

  50% {
    left: 387.5px;
  }

  75% {
    left: 581.25px;
  }

  100% {
    left: 775px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .sPageFive_content_one {
    font-size: 17px;
  }

  .sPageFive_content_two {
    font-size: 17px;
  }

  .sPageFive_content_three {
    font-size: 17px;
  }

  .sPageFive_content_four {
    font-size: 17px;

    display: none;
  }

  .sPageFive_content_five {
    font-size: 17px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .sPageFive_Heading {
    font-size: 33px;
    line-height: 1.4;
  }

  .sPageFive_hBar {
    width: 450px;
  }

  @keyframes sPageFive {
    0% {
      left: 0;
    }

    25% {
      left: 100px;
    }

    50% {
      left: 200px;
    }

    75% {
      left: 300px;
    }

    100% {
      left: 400px;
    }
  }

  .sPageFive_img {
    max-width: 350px;
    max-height: 300px;
  }

  .sPageFive_content_one {
    font-size: 16px;
  }

  .sPageFive_content_two {
    font-size: 16px;
  }

  .sPageFive_content_three {
    font-size: 16px;

    display: none;
  }

  .sPageFive_content_four {
    font-size: 16px;

    display: block;
  }

  .sPageFive_content_five {
    font-size: 16px;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .sPageFive_Heading {
    font-size: 30px;
    line-height: 1.4;
  }

  .sPageFive_hBar {
    width: 410px;
  }

  @keyframes sPageFive {
    0% {
      left: 0;
    }

    25% {
      left: 90px;
    }

    50% {
      left: 180px;
    }

    75% {
      left: 270px;
    }

    100% {
      left: 360px;
    }
  }

  .sPageFive_content_one {
    font-size: 15px;
  }

  .sPageFive_content_two {
    font-size: 15px;
  }

  .sPageFive_content_three {
    font-size: 15px;

    display: none;
  }

  .sPageFive_content_four {
    font-size: 15px;

    display: block;
  }

  .sPageFive_content_five {
    font-size: 15px;
  }
}

@media screen and (max-width: 576px) {
  .sPageFive_bg {
    padding: 20px 15px 20px 15px;
    background: #eafdf5;
  }

  .sPageFive_Body {
    margin-top: 50px;
    // padding-bottom: -40px;
    // margin-bottom: -20px;
  }

  .sPageFive_img {
    // max-width: 450px;
    // max-height: 471px;
    // margin-top: -62px;
    margin-bottom: -40px
  }

  .sPageFive_Heading {
    font-size: 27px;
    line-height: 1.3;
    margin-top: 0px;
  }

  .sPageFive_hBar_wrapper {
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .sPageFive_hBar {
    width: 305px;
    height: 3px;
    margin-top: -7px;
  }


  .sPageFive_hEl {
    height: 3px;
  }

  @keyframes sPageFive {
    0% {
      left: 0px;
    }

    25% {
      left: 63.75px;
    }

    50% {
      left: 127.5px;
    }

    75% {
      left: 191.25px;
    }

    100% {
      left: 255px;
    }
  }

  .sPageFive_content_one {
    font-size: 14px;
  }

  .sPageFive_content_two {
    font-size: 14px;
  }

  .sPageFive_content_three {
    font-size: 14px;
  }

  .sPageFive_content_four {
    font-size: 14px;

    display: block;
  }

  .sPageFive_content_five {
    font-size: 14px;
    font-weight: 500;
    display: none;
  }
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;