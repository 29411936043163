@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;

.ApplyForm-close-button {
  position: absolute;
  right: 3px;
  top: -5px;
  font-size: 32px;
  font-weight: 600;
  color: #4e7884;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.jdPageTwo_mobileBody {
    display: none;
}

.jdPageTwo_bg {
    display: grid;
    // justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.careerPageModal {
    // position: relative;
    z-index: 0;
}

.jdPageTwo_body {
    // max-width: 1300px;
    // justify-self: center;
}

.jdPageTwo_left {
    float: left;
    width: 70%;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 60px;
    padding-right: 60px;
}

.leftTitle {
    color: @brand-secondary;
    font-size: 33px;
    font-weight: 700;
    margin-top: 20px;
    line-height: 1.4;
    margin-bottom: -20px;
    // border-bottom: 6px solid @brand-primary;
    width: max-content;
}

.rightTitle {
    color: @brand-secondary;
    font-size: 20px;
    font-weight: 700;
    margin-top: 20px;
    // border-bottom: 3px solid @brand-primary;
    width: max-content;
    text-transform: uppercase;
    line-height: 1.8;
}

.rightTitle2 {
    color: @brand-primary;
    margin-top: 10px;
    font-size: 17px;
    font-weight: 600;
    line-height: 0.3;
}

.roleListUL {
    color: @brand-primary;
    margin-top: 30px;
    margin-bottom: 50px;
    font-weight: 600;
    line-height: 2;
}

.roleListLI {
    color: black;
    font-size: 18px;
    color: @brand-secondary;
}

.jdPageTwo_center {
    color: white;
    border-left: 2.4px solid rgba(33, 101, 33, 0.153);
    float: left;
    height: 100%;
}

.jdPageTwo_right {
    float: right;
    width: 26%;
    margin-top: 10px;
    margin-bottom: 10px;
    // line-height: 0.9;
}

.applyButtonJD {
    display: flex;
    justify-content: center;
    border-radius: 8px;
    width: 220px;
    padding: 5px 0px 4px 0px;
    margin-top: 35px;
    margin: 28px auto 0px;
    margin-bottom: 30px;
    border-bottom: 6px solid #26a671;
    background-color: @brand-primary;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.applyButtonJDText {
    font-weight: 800;
    font-size: 26px;
    color: @brand-white;
    cursor: pointer;
}

.applyButtonJD2 {
    display: flex;
    justify-content: center;
    border-radius: 8px;
    width: 220px;
    padding: 5px 0px 4px 0px;
    // margin-top: 25px;
    margin: 28px auto 0px;
    border-bottom: 6px solid #26a671;
    background-color: @brand-primary;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transform: scale(0.85);
}

.applyButtonJD2Text {
    font-weight: 800;
    font-size: 26px;
    color: @brand-white;
    cursor: pointer;
}


@media screen and (max-width: 576px) {
    .jdPageTwo_body {
        display: none;
    }

    .jdPageTwo_bg {
        // position:relative;
        margin-bottom: 0px;
        padding-bottom: 0px;
        z-index: 8;
    }

    .jdPageTwo_mobileBody {
        display: block;
        padding: 0px 12px;
        // background-color: yellow;
        // justify-self: left;
        // width: auto;
        // align-self: flex-start;
    }

    .upperJd {
        margin-bottom: 30px;
    }

    .upperRow {
        display: flex;
        margin-bottom: 15px;
    }

    .upperColumn1 {
        width: 140px;
        padding: 0px 10px;
    }

    .upperColumn2 {
        width: 140px;
        padding: 0px 10px;
        margin-left: 20px;
    }

    .upperTitle {
        font-size: 16px;
        font-weight: 800;
        // text-decoration: underline @brand-primary;
        // text-underline-offset: 4px;
    }

    .upperContent {
        color: @brand-primary;
        font-size: 13px;
        font-weight: 600;
        margin: 2.5px 0px 0px 5px;
    }

    .lowerJd {
        margin-left: 10px;
    }

    .lowerTitle {
        font-size: 23px;
        font-weight: 700;
        // border-bottom: 2.5px solid @brand-primary;
        // text-underline-offset: 9px;
        width: max-content;
        color: #4e7884;
        margin-bottom: 5px;
    }

    .lowerListUL {
        padding: 0px 20px;
        color: @brand-primary;
        margin-bottom: 25px;
        font-weight: 500;
    }

    .lowerListLI {
        color: #4e7884;
        line-height: 1.5;
        text-align: justify;
        hyphens: auto;
        font-weight: 600;
    }

    .applyButtonJD {
        transform: scale(0.65);
        margin-bottom: 20px;
        border-bottom: 6px solid #26a671;
        box-shadow: rgb(0 0 0 / 10%) 0px 10px 15px -3px, rgb(0 0 0 / 5%) 0px 4px 6px // margin: 0;
            // justify-self: center;
    }

    // .careerPageModal {
    //     // position: relative;
    //     z-index: 9;
    // }
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;