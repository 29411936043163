@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;

.pageTwo_Body_Mobile {
  display: none;
}

.lPageTwo_bg {
  display: grid;
  position: relative;
  width: 100%;
  min-height: auto;
  padding: 30px 15px;
  background: @brand-white;
}

.pageTwo_Body {
  max-width: 1300px;
  justify-self: center;
  margin-left: -73px;
}

.lPageTwo_Heading {
  text-align: center;
  margin-top: 10px;
  font-weight: 900;
  font-size: 36px;
}

.lPageTwo_hBar_wrapper {
  padding: 0 15px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 10px;
}

.lPageTwo_hBar {
  width: 400px;
  height: 5px;
  background-color: #9fe1c6;
  margin-top: -14px;
}

.lPageTwo_content_wraper {
  // max-width: 445px;
  padding: 0px 33px;
}

.lPageTwo_step_one {
  font-size: 26px;
  font-weight: 800;
  color: @brand-primary;
}

.lPageTwo_subHeading_one {
  margin-top: 10px;
  font-size: 32px;
  font-weight: 800;
  line-height: 1.3;
  color: @brand-secondary;
}

.lPageTwo_content_one {
  margin-top: 10px;
  color: @brand-primary;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.4;
  text-align: justify;
  hyphens: auto;
  // min-width: 500px;
  max-width: 500px;
}

.lPageTwo_step_two {
  text-align: end;
  font-size: 26px;
  font-weight: 800;
  color: @brand-primary;
}

.lPageTwo_subHeading_two {
  text-align: end;
  margin-top: 10px;
  font-size: 32px;
  font-weight: 800;
  line-height: 1.3;
  color: @brand-secondary;
}

.lPageTwo_content_two {
  margin-top: 10px;
  color: @brand-primary;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.4;
  text-align: justify;
  // min-width: 500px;
  // max-width: 500px;
}

// .test{
//   display: inline-flex;
//   justify-content: space-between;
//   align-items: center;
//   background-color: #ceba21;
//   margin-top:0;
// margin-right:auto;
// margin-bottom:0;
// margin-left:auto;
// }

.lPageTwo_img {
  max-width: 600px;
  max-height: 2050px;
}

.lPageTwo_hEl {
  width: 50px;
  height: 5px;
  position: relative;
  background-color: @brand-primary;
  animation: lPageTwo 2s infinite alternate linear;
}

@keyframes lPageTwo {
  0% {
    left: 0;
  }

  25% {
    left: 87.5px;
  }

  50% {
    left: 175px;
  }

  75% {
    left: 262.5px;
  }

  100% {
    left: 350px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .lPageTwo_Heading {
    font-size: 33px;
  }

  .lPageTwo_hBar {
    width: 330px;
  }

  @keyframes lPageTwo {
    0% {
      left: 0;
    }

    25% {
      left: 70px;
    }

    50% {
      left: 140px;
    }

    75% {
      left: 210px;
    }

    100% {
      left: 280px;
    }
  }

  .lPageTwo_step_one {
    font-size: 21px;
  }

  .lPageTwo_subHeading_one {
    font-size: 27px;
  }

  .lPageTwo_content_one {
    font-weight: 600;
    font-size: 17px;
  }

  .lPageTwo_step_two {
    font-size: 21px;
  }

  .lPageTwo_subHeading_two {
    font-size: 27px;
  }

  .lPageTwo_content_two {
    font-size: 17px;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .lPageTwo_Heading {
    font-size: 30px;
  }

  .lPageTwo_hBar {
    width: 290px;
  }

  @keyframes lPageTwo {
    0% {
      left: 0;
    }

    25% {
      left: 60px;
    }

    50% {
      left: 120px;
    }

    75% {
      left: 180px;
    }

    100% {
      left: 240px;
    }
  }

  .lPageTwo_step_one {
    font-size: 18px;
  }

  .lPageTwo_subHeading_one {
    font-size: 24px;
  }

  .lPageTwo_content_one {
    font-size: 16px;
  }

  .lPageTwo_step_two {
    text-align: start;
    font-size: 18px;
  }

  .lPageTwo_subHeading_two {
    text-align: start;
    font-size: 24px;
  }

  .lPageTwo_content_two {
    font-size: 16px;
  }

  // .lPageTwo_img {
  //   max-width: 350px;
  //   max-height: 300px;
  // }
}

@media screen and (max-width: 576px) {

  .lPageTwo_content_wraper {
    // max-width: 445px;
    padding: 0px 10px;
  }

  .pageTwo_Body {
    display: none;
  }

  .pageTwo_Body_Mobile {
    display: block;
    // background-color: yellow;
    margin-top: -50px;
  }

  .lPageTwo_bg {
    padding: 15px 5px;
    padding-bottom: 25px;
  }

  .lPageTwo_Heading {
    font-size: 27px;
    line-height: 1.3;
  }

  .lPageTwo_hBar {
    width: 300px;
    height: 3px;
  }

  .lPageTwo_hEl{
    height: 3px;
  }

  @keyframes lPageTwo {
    0% {
      left: 0;
    }

    25% {
      left: 62.5px;
    }

    50% {
      left: 125px;
    }

    75% {
      left: 187.5px;
    }

    100% {
      left: 250px;
    }
  }

  .lPageTwo_step_one {
    font-size: 18px;
  }

  .lPageTwo_subHeading_one {
    font-size: 24px;
    margin-top: 0px;
  }

  .lPageTwo_subHeading_two {
    text-align: start;
    font-size: 24px;
    margin-top: 0px;
  }

  .lPageTwo_content_one {
    font-size: 15px;
  }

  .lPageTwo_step_two {
    text-align: start;
    font-size: 18px;
  }

  .lPageTwo_content_two {
    font-size: 15px;
  }

  .lPageTwo_img {
    max-width: 350px;
    max-height: 300px;
    margin: 40px 0px -20px 0px;
  }

  // .lPageTwo_img:nth-child(3) {
  //   max-width: 350px;
  //   max-height: 300px;
  //   // margin-bottom: -40px;
  // }
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;