@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;

////////////////////////////

.pageEight_xBrand {
  position: absolute;
  z-index: 10;
  left: 20px;
  top: 10px;
  transform: scale(0.7);
}

.pageEight_xBrand2 {
  position: absolute;
  z-index: 10;
  left: 130px;
  top: 55px;
  transform: scale(0.7);
  // top: 450px
}

////////////////////////////

.pageEight_hBar_wrapper {
  padding: 0 15px;
  justify-content: center;
  align-items: center;
  display: flex;
  // margin-top: 10px;
  margin-bottom: 40px;
}

.pageEight_hBar {
  width: 470px;
  height: 5px;
  background-color: #9fe1c6;
  margin: -7px;
  margin-top: -13px;
}

.page_eight_hEl {
  width: 50px;
  height: 5px;
  position: relative;
  background-color: @brand-primary;
  animation: pageEight 2s infinite alternate linear;
}

@keyframes pageEight {
  0% {
    left: 0px;
  }

  // 25% {
  //   left: 110px;
  //   width: 100px;
  // }

  // 50% {
  //   left: 220px;
  //   width: 150px;
  // }

  // 75% {
  //   left: 330px;
  //   width: 100px;
  // }

  100% {
    left: 420px;
  }
}

.pageEight_bg {
  position: relative;
  background: #eafdf5;
  width: 100%;
  // min-height: 450px;
  padding: 30px 15px;
  background-size: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 180px;
  // margin-bottom: 37px;
}

.pageEight_text {
  text-align: center;
  font-size: 42px;
  font-weight: 900;
  color: @brand-primary;
  // margin-top: 70px;
  // margin-bottom: 60px;
}

//////////////////////////////////

.pageEightPartners {
  // background-color: yellow;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.pageEight_BodyCardChild {
  background-color: @brand-white;
  padding: 11px;
  padding-bottom: 13px;
  margin: 6px 6px 12px 6px;
  border-radius: 6px;
  width: min-content;
  color: @brand-primary;
  font-weight: 800;
  text-align: center;
  background-size: 110%;
  transition: 0.1s ease-out;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  box-shadow: #21ce86 0px 5px 15px;
  filter: grayscale(100%);
}

.pageEight_BodyCardChild:hover {
  filter: grayscale(0%);
  // background-position: left;
  // transition: all 0.1s linear;
  // background-color: @brand-primary;
  // color: @brand-white;
}

.pageEight_BodyCardChild:hover img {
  transform: scale(1.05);
}

//////////////////////////////////

@media screen and (max-width: 576px) {

.pageEight_hBar_wrapper {
  margin-bottom: 30px;
}

  .pageEight_bg {
    padding-top: 20px;
    padding-bottom: 140px;
  }

  .pageEight_text {
    margin-top: 10px;
    width: 350px;
    // font-size: 40px;
  }

  .pageEight_xBrand {
    display: none;
  }

  .pageEight_xBrand2 {
    display: none;
  }

  .pageEight_bg {
    // background-color: white;
  }


  .pageEight_hBar {
    width: 300px;
    height: 3px;
    background-color: #9fe1c6;
    margin-top: 2px;
  }

  .page_eight_hEl {
    width: 50px;
    height: 3px;
    position: relative;
    background-color: @brand-primary;
    animation: pageEight 2s infinite alternate linear;
  }

  @keyframes pageEight {
    0% {
      left: 0px;
      // width: 50px;
    }

    // 25% {
    //   left: 60px;
    //   width: 100px;
    // }

    // 50% {
    //   left: 120px;
    //   width: 150px;
    // }

    // 75% {
    //   left: 180px;
    //   width: 100px;
    // }

    100% {
      left: 250px;
      // width: 50px;
    }
  }

  .pageEight_text {
    font-size: 27px;
    line-height: 32px;
  }

  .pageEight_bg {
    justify-content: start;
    // min-height: 350px;
    padding-bottom: 146px;
  }

  .pageEight_BodyCardChild {
    filter: grayscale(0%);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    
  }
  
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;