@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;

.sPageFour_bg {
  display: grid;
  position: relative;
  width: 100%;
  min-height: auto;
  padding: 30px 30px;
}

.sPageFour_Body {
  max-width: 1300px;
  justify-self: center;
}

.spageFour_BodyStrip {
  position: absolute;
  // align-self: center;
  top: 163px;
  left: 0px;
  width: 100vw;
  height: 170px;
  background-color: #e6fcf3;
  // margin-top: -7px;
}

.spageFour_BodyStrip2 {
  display: none;
}

.sPageFour_Heading {
  text-align: center;
  font-weight: 900;
  font-size: 36px;
  margin-top: 0px;
}

.sPageFour_hBar_wrapper {
  padding: 0 15px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 45px;
}

.sPageFour_hBar {
  width: 590px;
  height: 5px;
  background-color: #9fe1c6;
}

.sPageFour_card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 220px;
  box-shadow: 0 4px 4px 2px rgba(0, 0, 0, 0.2);
  border-radius: 18px;
  border: 2px solid @brand-primary;
  background-color: @brand-white;
}

.sPageFour_card_cContent {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: -1px;
  margin-right: -1px;
  border-radius: 0px 17px 0px 0px;
  border: 18px solid @brand-primary;
  border-left: 18px solid @brand-white;
  border-bottom: 18px solid @brand-white;
  width: 0;
}

.sPageFour_card_img {
  width: 110px;
  height: 110px;
}

.sPageFour_card_heading {
  font-size: 21px;
  font-weight: 700;
  color: @brand-primary;
  margin-top: 10px;
}

.sPageFour_card_sHeading {
  font-size: 21px;
  font-weight: 700;
  color: @brand-ternary;
}

.sPageFour_hEl {
  width: 50px;
  height: 5px;
  position: relative;
  background-color: @brand-primary;
  animation: sPageFour 2s infinite alternate linear;
}

@keyframes sPageFour {
  0% {
    left: 0;
  }

  25% {
    left: 135px;
  }

  50% {
    left: 270px;
  }

  75% {
    left: 405px;
  }

  100% {
    left: 540px;
  }
}

.sPageFour_mySwiper {
  display: none;
}

// .sPageFour_bImg {
//   position: absolute;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
//   height: 150px;
//   background: #d0ffec;
//   z-index: -1;
// }

@media (min-width: 992px) and (max-width: 1200px) {
  .sPageFour_card {
    width: 155px;
    height: 180px;
  }

  .sPageFour_card_cContent {
    border-radius: 0px 12px 0px 0px;
    border: 14px solid @brand-primary;
    border-left: 14px solid @brand-white;
    border-bottom: 14px solid @brand-white;
  }

  .sPageFour_card_img {
    width: 100px;
    height: 100px;
  }

  .sPageFour_card_heading {
    font-size: 16px;
    margin-top: 0px;
  }

  .sPageFour_card_sHeading {
    font-size: 16px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .sPageFour_Heading {
    font-size: 33px;
  }

  .sPageFour_hBar {
    width: 450px;
  }

  @keyframes sPageFour {
    0% {
      left: 0;
    }

    25% {
      left: 100px;
    }

    50% {
      left: 200px;
    }

    75% {
      left: 300px;
    }

    100% {
      left: 400px;
    }
  }

  .sPageFour_card {
    width: 140px;
    height: 160px;
  }

  .sPageFour_card_cContent {
    border-radius: 0px 12px 0px 0px;
    border: 12px solid @brand-primary;
    border-left: 12px solid @brand-white;
    border-bottom: 12px solid @brand-white;
  }

  .sPageFour_card_img {
    width: 80px;
    height: 80px;
  }

  .sPageFour_card_heading {
    font-size: 14px;
    margin-top: 0px;
  }

  .sPageFour_card_sHeading {
    font-size: 14px;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .sPageFour_bg {
    padding: 15px;
  }

  .sPageFour_Heading {
    font-size: 30px;
  }

  .sPageFour_hBar {
    width: 450px;
  }

  @keyframes sPageFour {
    0% {
      left: 0;
    }

    25% {
      left: 100px;
    }

    50% {
      left: 200px;
    }

    75% {
      left: 300px;
    }

    100% {
      left: 400px;
    }
  }

  .sPageFour_card {
    width: 140px;
    height: 160px;
  }

  .sPageFour_card_cContent {
    border-radius: 0px 12px 0px 0px;
    border: 14px solid @brand-primary;
    border-left: 14px solid @brand-white;
    border-bottom: 14px solid @brand-white;
  }

  .sPageFour_card_img {
    width: 80px;
    height: 80px;
  }

  .sPageFour_card_heading {
    font-size: 14px;
    margin-top: 0px;
  }

  .sPageFour_card_sHeading {
    font-size: 14px;
  }
}

@media screen and (max-width: 576px) {
  .sPageFour_bg {
    padding: 10px 15px 15px 15px;
    display: block;
    margin-bottom: 10px;
  }

  .sPageFour_Body {
    display: none;
  }

  .sPageFour_Heading {
    font-size: 30px;
    line-height: 1.3;
    margin-top: 0px;
  }

  .sPageFour_hBar_wrapper {
    margin-top: 10px;
    margin-bottom: 30px;
  }

  .sPageFour_hBar {
    width: 290px;
    height: 3px;
    margin-top: -18px;
  }

  @keyframes sPageFour {
    0% {
      left: 0px;
    }

    25% {
      left: 60px;
    }

    50% {
      left: 120px;
    }

    75% {
      left: 180px;
    }

    100% {
      left: 240px;
    }
  }

  .sPageFour_mySwiper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 180px;
  }

  .sPageFour_SwiperContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sPageFour_fcontent {
    display: none;
  }

  .sPageFour_card {
    width: 140px;
    height: 170px;
    background-color: white;
  }

  .sPageFour_card_cContent {
    border-radius: 0px 16px 0px 0px;
    border: 16px solid @brand-primary;
    border-left: 16px solid @brand-white;
    border-bottom: 16px solid @brand-white;
  }

  .sPageFour_card_img {
    width: 80px;
    height: 80px;
  }

  .sPageFour_card_heading {
    font-size: 14px;
  }

  .sPageFour_card_sHeading {
    font-size: 14px;
  }

  .spageFour_BodyStrip2 {
    display: block;
    position: absolute;
    align-self: center;
    top: 155px;
    left: 0px;
    width: 100vw;
    height: 120px;
    background-color: #e6fcf3;
    // margin-top: -7px;
  }

  .sPageFour_hEl {
    height: 3px;
  }

}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;