.tacPageTwoBg {
    // display: grid;
}

.tacPageTwoBody {
    // margin-top: -20px;
    // margin-bottom: 40px;
    padding: 20px 40px;
}

.tacPageTwoSubTitle {
    font-size: 28px;
    font-weight: 700;
    // margin-top: 50px;
    margin-bottom: 10px;
    color: #4e7884;
    // text-align: justify;
    line-height: 1.3;
    text-transform: capitalize;
    // text-decoration: underline;
}

.tacPageTwoContent {
    font-size: 15px;
    font-weight: 500;
    margin-left: 5px;
    color: #4e7884;
    text-align: justify;
    word-wrap: break-word;
    hyphens: auto;
    line-height: 1.3;
    margin-bottom: 40px;
}

.hyperLink {
    word-wrap: break-word;
}

@media screen and (max-width: 576px) {
    .tacPageTwoBody {
        padding: 8px 25px 0px 19px;
    }
    .tacPageTwoSubTitle {
        font-size: 22px;
        font-weight: 700;
    }
    .tacPageTwoContent {
        margin-bottom: 40px;
    }
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;