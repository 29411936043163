@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;

.mapouterMobile {
    display: none;
}

.pageTwo_Title {
    display: none;
}

.pageTwo_background {
    // background-color: #ffffff;
    z-index: 8;
}

.gmap_iframe {
    position: relative;
    width: 100%;
    height: 600px;
    // background-color: red;
    margin-bottom: -75px;
    z-index: 8;
}

.cuPageTwo_Div {
    margin-inline: 20px;
    // height: calc(100vh - 45px);
    // background-color: red;
    display: flex;
    width: auto;
    justify-content: center;
    margin-bottom: 80px;
    // align-items: center;
    // align-self: center;
}

.cuForm_Div {

    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2),
        0 4px 4px 4px rgba(0, 0, 0, 0.001), 0 4px 4px 3px rgba(0, 0, 0, 0.01),
        0 4px 4px 3px rgba(0, 0, 0, 0.04);
    // border: black;
    // border-width: 50;
}

.cuCard_Div {
    width: 450px;
    height: 600px;
    padding: 30px;
    background-image: url("../../../Assets/contact-us-form-bg.webp");
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2),
        0 4px 4px 4px rgba(0, 0, 0, 0.001), 0 4px 4px 3px rgba(0, 0, 0, 0.01),
        0 4px 4px 3px rgba(0, 0, 0, 0.04);
    background-repeat: no-repeat;
    background-size: 100% 100%;

}

.cuCard_Title_Text {
    font-size: 35px;
    font-weight: 900;
    color: @brand-white;
}

.cuCard_Text_Div {
    display: flex;
    width: auto;

    // justify-content: center;
    // align-items: center;
}

.cuCard_Text_1 {
    margin-top: -1%;
    color: @brand-white;
    font-size: 16px;
    font-weight: 500;
}

.cuCard_Text_11 {
    margin-top: 10.5px;
}

@media screen and (max-width: 576px) {
    .cuPageTwo_Div {
        background-color: @brand-white;
        display: flex;
        flex-direction: column-reverse;
        width: auto;
        // justify-content: center;
        padding: 0px;
        margin: 0px;
    }

    .cuForm_Div {
        background-color: @brand-white;
        padding: 20px;
        margin-top: 20px;
        width: auto;
        box-shadow: none
    }

    .cuCard_Div {
        width: 100%;
        height: 255px;
        background-image: none;
        background: rgb(33, 213, 137);
        background: linear-gradient(90deg, rgba(33, 213, 137, 1) 0%, rgba(76, 231, 166, 1) 100%);
    }

    .cuCard_Title_Text {
        font-size: 26px;
        text-align: center;
    }

    .cuCard_Text_Div {
        display: flex;
        width: auto;
        // justify-content: center;
        // align-items: center;
    }

    .cuCard_Text_1 {
        font-size: 14px;
    }

    .pageTwo_Title {
        position: relative;
        display: block;
        font-size: 20px;
        font-weight: 800;
        color: @brand-primary;
        margin-bottom: -30px;
        text-align: center;
        text-decoration: underline;
        text-underline-position: under
    }

    .mapouter {
        display: none;
    }

    .mapouterMobile {
        display: block;
        height: 275px;
        margin-bottom: -75px;
    }

    .gmap_canvas {
        height: 275px !important;
    }

    .gmap_iframe {
        height: 275px !important;
    }
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;