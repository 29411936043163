@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;

.aPageFive_bg {
  position: relative;
  width: 100%;
  min-height: auto;
  padding: 15px;
  background: #eafdf5;
  padding-top: 40px;
  display: grid;
}

.pageFiveBody {
  // display: grid;
  max-width: 1300px;
  justify-self: center;
}

.aPageFive_heading {
  font-size: 36px;
  font-weight: 900;
  text-align: center;
}

.aPageFive_hBar_wrapper {
  padding: 0 15px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 15px;
}

.aPageFive_hBar {
  width: 450px;
  height: 5px;
  background-color: #9fe1c6;
}

.aPageFive_hEl {
  width: 50px;
  height: 5px;
  position: relative;
  background-color: @brand-primary;
  animation: aPageFive 2s infinite alternate linear;
}

.aPageFive_text {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  margin-top: 20px;
  // max-width: 1300px;
  // justify-self: center;
}

@keyframes aPageFive {
  0% {
    left: 0;
  }

  25% {
    left: 100px;
  }

  50% {
    left: 200px;
  }

  75% {
    left: 300px;
  }

  100% {
    left: 400px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .aPageFive_heading {
    font-size: 33px;
  }

  .aPageFive_hBar {
    width: 350px;
  }

  .aPageFive_text {
    font-size: 17px;
    margin-top: 15px;
  }

  @keyframes aPageFive {
    0% {
      left: 0px;
    }

    25% {
      left: 75px;
    }

    50% {
      left: 150px;
    }

    75% {
      left: 225px;
    }

    100% {
      left: 300px;
    }
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .aPageFive_heading {
    font-size: 30px;
  }

  .aPageFive_hBar {
    width: 350px;
  }

  .aPageFive_text {
    font-size: 17px;
    margin-top: 15px;
  }

  @keyframes aPageFive {
    0% {
      left: 0px;
    }

    25% {
      left: 75px;
    }

    50% {
      left: 150px;
    }

    75% {
      left: 225px;
    }

    100% {
      left: 300px;
    }
  }
}

@media screen and (max-width: 576px) {

  .aPageFive_bg {
    background: none;
    padding-top: 10px;
  }

  .aPageFive_heading {
    font-size: 24px;
  }

  .aPageFive_hBar {
    width: 300px;
    height: 3px;
  }

  .aPageFive_hEl {
    height: 3px;
  }

  .aPageFive_text {
    font-size: 14px;
    text-align: justify;
    hyphens: auto;
    margin: 0px 14px;
    margin-top: 12px;
    line-height: 1.4;
    color: #4e7884;
  }

  @keyframes aPageFive {
    0% {
      left: 0px;
    }

    25% {
      left: 62.5px;
    }

    50% {
      left: 125px;
    }

    75% {
      left: 187.5px;
    }

    100% {
      left: 250px;
    }
  }
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;