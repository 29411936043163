@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;

.side_drawer {
  display: none;
  height: 100%;
  background: @brand-white;
  // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2),
  //   0px 4px 4px 0px rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 103px;
  right: 0;
  width: 75%;
  max-width: 400px;
  z-index: 200;
  transform: translateX(100%);
  transition: transform 0.4s ease-out;
  margin-top: -9.18%;
  // background-color: black;
}

.side_drawer.show {
  transform: translateX(0);
}

.side_drawer_items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
  // text-align: left;
  // margin-left: 25px;
}

.side_drawer_item {
  margin: 10px 20px;
}

.side_drawer_item div {
  font-size: 15px;
  font-weight: 700;
  transition: 0.5s ease-in-out;
  // text-align: left;
}

.side_drawer_item div:hover,
.side_drawer_item div.active {
  width: fit-content;
  color: @brand-primary;
  border-radius: 6px;
  cursor: pointer;
}

@media screen and (max-width: 576px) {
  .side_drawer {
    display: block;
  }
}

@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;