@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;

.header {
  width: 100%;
  // height: 100px;
  padding: 10px 0 10px;
  position: fixed;
  top: 0;
  left: 0;
  background: @brand-white;
  z-index: 200;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  // box-shadow: 0px 0px 0px 0px rgb(204, 255, 0),
  //   0px 1px 1px 0px rgb(246, 255, 0);
  // border-bottom: 3px solid @brand-secondary;
  
  // background-color: grey;
}

.logoStyling {
  margin-right: 70px;
  width: 4em;
  // height: "10px";
}

// .logoCSS {
//   // background-color: #21ce86;
//   // margin-left: 10%;
//   // margin-right: 5%;
// }

.nav {
  // background-color: #bdce21;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // background: @brand-white;
  padding: 0px 60px 0px 20px;
  margin: 0% 4% 0% 5%;
}

.navbar {
  // background-color: #21ce86;
  display: flex;
  // justify-content: space-evenly;
  align-items: center;
  min-width: 85vw;
}

.navbar .navbar_item div {
  // width: fit-content;
  margin: 0px 20px;
  padding: 6px 12px 6px 10px;
  font-size: 15.5px;
  font-weight: 700;
  transition: 0.5s ease-in-out;
}

.navbar .navbar_item div:hover,
.navbar .navbar_item div.active {
  background: @brand-primary;
  color: @brand-white;
  border-radius: 4px;
  padding-left: 12px;
  padding-right: 12px;
  cursor: pointer;
}

.navbar_dropdown {
  display: none;
  position: absolute;
  // margin-top: 10px;
  margin-left: 20px;
  // background: @brand-white;
  background: @brand-white;
  min-width: 195px;
  border-radius: 3px;
  // border-radius: 0 0 6px 6px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2),
    0px 4px 4px 0px rgba(0, 0, 0, 0.2), 0px 4px 4px 0px rgba(0, 0, 0, 0.2);
  // padding-left: 20px;
  padding-right: 20px;
  z-index: 200;
  border-top: 3px solid @brand-primary;
  width: 200px
}

// .navbar_dropdown::before {
//   content: "";
//   position: absolute;
//   border: 10px solid @brand-primary;
//   margin-left: 75px;
//   margin-top: -34px;
//   border-color: transparent transparent @brand-primary;
// }

.navbar .navbar_item:hover .navbar_dropdown {
  display: block;
}

.navbar .navbar_item>.navbar_dropdown div {
  padding: 0;
  margin: 9px 0;
}

.navbar .navbar_item:hover .navbar_dropdown div:hover,
.navbar .navbar_item:hover .navbar_dropdown div.active {
  color: @brand-primary;
  background: @brand-white;
}

.hamberberg {
  display: none;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  color: @brand-primary;
  cursor: pointer;
}

@media screen and (max-width: 576px) {

  .logoStyling {
    width: 3em;
    // height: "10px";
  }

  .header {
    // height: 2em;
  }

  .nav {
    padding: 0px 0px 0px 0px;
  }

  .navbar {
    display: none;
  }

  .hamberberg {
    display: flex;
    width: 58px;
    height: 40px;
  }
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;