@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;

.lPageFive_bg {
  position: relative;
  background: #eafdf5;
  width: 100%;
  min-height: 400px;
  padding: 30px 15px;
  background-size: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.lPageFive_heading {
  text-align: center;
  font-size: 36px;
  font-weight: 900;
  color: @brand-primary;
}

.lPageFive_hBar_wrapper {
  padding: 0 15px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 75px;
}

.lPageFive_hBar {
  width: 530px;
  height: 5px;
  background-color: #9fe1c6;
}

.lPageFive_hEl {
  width: 50px;
  height: 5px;
  position: relative;
  background-color: @brand-primary;
  animation: lPageFive 2s infinite alternate linear;
}

@keyframes lPageFive {
  0% {
    left: 0;
  }
  25% {
    left: 120px;
  }
  50% {
    left: 240px;
  }
  75% {
    left: 360px;
  }
  100% {
    left: 480px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .lPageFive_heading {
    font-size: 33px;
  }
  .lPageFive_hBar {
    width: 430px;
  }

  @keyframes lPageFive {
    0% {
      left: 0;
    }
    25% {
      left: 95px;
    }
    50% {
      left: 190px;
    }
    75% {
      left: 285px;
    }
    100% {
      left: 380px;
    }
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .lPageFive_heading {
    font-size: 30px;
    line-height: 1.3;
  }
  .lPageFive_hBar {
    width: 410px;
  }
  @keyframes lPageFive {
    0% {
      left: 0;
    }
    25% {
      left: 90px;
    }
    50% {
      left: 180px;
    }
    75% {
      left: 270px;
    }
    100% {
      left: 360px;
    }
  }
}

@media screen and (max-width: 576px) {
  .lPageFive_heading {
    font-size: 27px;
    line-height: 1.2;
  }

  .lPageFive_hBar_wrapper {
    margin-bottom: 50px;
    margin-top: 10px;
  }

  .lPageFive_hBar {
    width: 290px;
  }
  .lPageFive_bg {
    justify-content: start;
    min-height: 200px;
  }

  @keyframes lPageFive {
    0% {
      left: 0px;
    }
    25% {
      left: 60px;
    }
    50% {
      left: 120px;
    }
    75% {
      left: 180px;
    }
    100% {
      left: 240px;
    }
  }
}

@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;