@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;

.tacPageOneBg {
    display: grid;
    position: relative;
    height: 250px;
    background-image: url("../../../Assets/terms n condition banner bg.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
    background-position-y: bottom;
    // background-size: 100% 100%;
    margin-top: -0.3%;
}

.tacPageOneBody {
    max-width: 1300px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-self: center;
}

.tacPageOneTitle {
    font-weight: 900;
    color: @brand-white;
    font-size: 47px;
    line-height: 1.3;
}


@media screen and (max-width: 576px) {
    .tacPageOneBg {
        padding: 0;
        margin-top: -6%;
        height: 150px;
        // background-image: url(../../../Assets/background.webp);
    }

    .tacPageOneTitle {
        font-size: 24px;
        line-height: 1.4;
        font-weight: 800;
    }
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;