@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;

.accordion {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-top: 2px solid rgb(33 206 134 / 42%);
}

.collapse_container {
  width: 100%;
  padding: 20px 15px;
  background-color: white;
  margin-top: -2px;
  cursor: pointer;
  border: 2px solid rgb(33 206 134 / 42%);
  border-width: 2px 2px 0px 2px;
}

.collapse_container_content {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0 5px;
  font-weight: 600;
  font-size: 20px;
  align-items: center;
}

.collapse_sContainer {
  width: 100%;
  padding: 0px 15px;
  background-color: @brand-secondary;
  border: solid @brand-white;
  border-bottom: solid #ffffff;
  border-width: 0px 2px 0px 2px;
  // background: #eafdf5;
  color: white;
}

.collapse_sContainer_content {
  font-weight: 600;
  font-size: 18px;
  // color: "4e7884";
  font-weight: 400;
  // margin-top: -20px;
  list-style-type: disc;
  // background-color: @brand-secondary;
}

.accordion_ninus {
  padding-right: 15px;
}

.accordion_plus {
  padding-right: 15px;
}

.answerStyling {
  text-align: justify;
  // padding-right: 3.4%;
  padding-left: 37px;
  font-weight: 500;
  margin: -10px 0px 0px 0px;
  padding-bottom: 15px;
  // border-bottom: #029989;
}

@media (min-width: 768px) and (max-width: 992px) {
  .collapse_container_content {
    font-size: 17px;
  }

  .collapse_sContainer_content {
    font-size: 17px;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .collapse_container_content {
    font-size: 16px;
  }

  .collapse_sContainer_content {
    font-size: 16px;
  }
}

@media screen and (max-width: 576px) {

  .accordion {
    margin: 0px 0px;
    box-shadow: none;
    // border: solid @brand-primary 2px
  }

  .collapse_sContainer {
    padding: 0px 5px;
  }

  .collapse_container {
    padding: 10px 10px;
    border: none;
  }

  .collapse_container_content {
    font-size: 14px;
    line-height: 18px;
    // border: ;
  }

  .collapse_sContainer_content {
    font-size: 12px;
    padding: 0px 25px;
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .answerStyling {
    text-align: justify;
    // padding-right: 3.4%;
    padding-left: 12px;
    font-weight: 600;
    margin: 0px 0px 0px 0px;
    padding-top: 8px;
    padding-bottom: 0px;
    line-height: 18px;
    font-size: 12.5px;
    hyphens: auto;
    // border-bottom: #029989;
  }
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;