@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;

.aPageTwoDesktop_bg {
  padding: 50px 0px 0px 0px;
  display: grid;
}

.aPageTwoDesktop_Body {
  // background-color: green;
  // width: min-content;
  display: flex;
  // justify-content: center;
  padding: 0px 10px;
  max-width: 1250px;
  justify-self: center;
}

.aPageTwoDesktop_Image {
  // background-color: orange;
  margin-top: -100px;
  // margin-right: 5px;
  display: flex;
  justify-content: flex-end;
  width: 32%;
  transform: scale(0.8);
}

.aPageTwoDesktop_Content {
  // background-color: purple;
  width: 68%;
}

.aPageTwo_textDesktop {
  font-weight: 600;
  font-size: 18px;
  text-align: justify;
  margin-bottom: 20px;
  width: 80%;
  hyphens: auto;
}

.aPageTwoDesktop_Title {
  // background-color: red;
  text-align: center;
  // width: min-content;
}

.aPageTwo_bg {
  position: relative;
  width: 100%;
  min-height: auto;
  padding: 30px 15px;
  // background: @brand-white;
  display: none;
}

.aPageTwo_heading {
  margin-top: 10px;
  font-size: 36px;
  font-weight: 900;
  text-align: center;
}

.aPageTwo_headingMobile {
  margin-top: 10px;
  font-size: 40px;
  font-weight: 900;
  text-align: center;
  display: none;
}

.aPageTwo_hBar_wrapper {
  padding: 0 15px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 45px;
}

.aPageTwo_hBar {
  width: 470px;
  height: 5px;
  background-color: #9fe1c6;
  margin-top: -2px;
}

.aPageTwo_hEl {
  width: 50px;
  height: 5px;
  position: relative;
  background-color: @brand-primary;
  animation: aPageTwo 2s infinite alternate linear;
}

@keyframes aPageTwo {
  0% {
    left: 0;
    // width: 50px;
  }

  25% {
    left: 105px;
    // width: 125px;
  }

  50% {
    left: 210px;
    // width: 150px;
  }

  75% {
    left: 315px;
    // width: 125px;
  }

  100% {
    left: 420px;
    // width: 50px;
  }
}

.aPageTwo_img {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 350px;
  max-height: 320px;
}

.aPageTwo_text {
  font-weight: 600;
  font-size: 18px;
  text-align: justify;
  margin-bottom: 20px;
}

.aPageTwo_text_one {
  font-weight: 600;
  font-size: 18px;
  text-align: justify;
  margin-bottom: 20px;
}

.aPageTwo_text_two {
  font-weight: 600;
  font-size: 18px;
  text-align: justify;
  margin-bottom: 20px;
}

.aPageTwo_text_three {
  font-weight: 600;
  font-size: 18px;
  text-align: justify;
  margin-bottom: 20px;
  display: none;
}

.aPageTwo_text_four {
  font-weight: 600;
  font-size: 18px;
  text-align: justify;
  margin-bottom: 20px;
  display: none;
}

@media (min-width: 768px) and (max-width: 992px) {
  .aPageTwo_heading {
    font-size: 33px;
  }

  .aPageTwo_headingMobile {
    font-size: 33px;
    display: none;
  }

  .aPageTwo_hBar {
    width: 370px;
  }

  @keyframes aPageTwo {
    0% {
      left: 0px;
    }

    25% {
      left: 80px;
    }

    50% {
      left: 160px;
    }

    75% {
      left: 240px;
    }

    100% {
      left: 320px;
    }
  }

  .aPageTwo_img {
    max-width: 200px;
    max-height: 200px;
  }

  .aPageTwo_text {
    font-size: 17px;
  }

  .aPageTwo_text_one {
    margin-bottom: 0px;
    font-size: 17px;
  }

  .aPageTwo_text_two {
    display: none;
  }

  .aPageTwo_text_three {
    display: none;
  }

  .aPageTwo_text_four {
    text-align: justify;
    display: inline;
    font-size: 17px;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .aPageTwo_heading {
    font-size: 30px;
  }

  .aPageTwo_headingMobile {
    font-size: 30px;
    display: none;
  }

  .aPageTwo_hBar {
    width: 330px;
  }

  @keyframes aPageTwo {
    0% {
      left: 0px;
    }

    25% {
      left: 70px;
    }

    50% {
      left: 140px;
    }

    75% {
      left: 210px;
    }

    100% {
      left: 280px;
    }
  }

  .aPageTwo_img {
    max-width: 200px;
    max-height: 200px;
  }

  .aPageTwo_text {
    font-size: 16px;
  }

  .aPageTwo_text_one {
    margin-bottom: 0px;
    font-size: 16px;
  }

  .aPageTwo_text_two {
    display: none;
  }

  .aPageTwo_text_three {
    display: none;
  }

  .aPageTwo_text_four {
    display: inline;
    font-size: 16px;
  }
}

@media screen and (max-width: 576px) {

  .aboutUsBody_Mobile {
    display: block;
    padding: 0px 30px;
    text-align: justify;
    hyphens: auto;
    word-spacing: -1px;
    font-size: 14px;
    color: black;
    margin-top: 30px;
    font-weight: 600;
    color: #4e7884;
    // padding-bottom: 30px;
  }

  .aPageTwo_bg {
    display: block;
    padding: 30px 0px 0px 0px;
    // background-image: url("../../../Assets/X-Brandiung-gradient.webp");
    // background-size: cover;
    // background-position:top;
    // background-size: 170%;
    // background-blend-mode: color;

  }

  .aPageTwoDesktop_bg {
    display: none;
  }

  .aPageTwo_heading {
    margin-top: 0px;
    font-size: 30px;
    line-height: 1.2;
    margin-bottom: 10px;
    display: none;
  }

  .aPageTwo_headingMobile {
    margin-top: 0px;
    font-size: 23px;
    line-height: 1.2;
    margin-bottom: 5px;
    display: block;
  }

  .aPageTwo_hBar {
    width: 300px;
    height: 3px
  }

  .aPageTwo_hEl {
    height: 3px;
  }

  @keyframes aPageTwo {
    0% {
      left: 0px;
    }

    25% {
      left: 62.5px;
    }

    50% {
      left: 125px;
    }

    75% {
      left: 187.5px;
    }

    100% {
      left: 250px;
    }
  }

  .aPageTwo_img {
    max-width: 160px;
    max-height: 200px;
  }

  .aPageTwo_text_one {
    display: none;
  }

  .aPageTwo_text_two {
    display: none;
  }

  .aPageTwo_text {
    font-size: 11px;
    margin-bottom: -40px;
    hyphens: auto;
    line-height: 1.4;
    padding-right: 14px;
  }
  
  .aPageTwo_text_three {
    display: inline;
    font-size: 11px;
    hyphens: auto;
  }
  
  .aPageTwo_text_four {
    display: inline;
    font-size: 11px;
    hyphens: auto;
    // padding: 0px 14px;
    // line-height: 1;
  }
  
  .aPageTwo_Col {
    margin-bottom: 7px;
    margin-top: 7px;
    line-height: 1.3;
    padding: 0px 14px;
  }
  
  .aPageTwo_hBar_wrapper {
    margin-bottom: -10px;
  }

}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;