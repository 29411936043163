@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;

.contactUsInputBox {
  margin-bottom: 2px;
}

.connectUs_bg_Licensing {
  position: relative;
  z-index: 10;
  margin-bottom: -120px;
  margin-top: -40px;
  width: 400px;
  min-height: 300px;
  padding: 20px 30px 20px 30px;
  background-image: url("../../Assets/form layout new.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  // background: red;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  // box-shadow: 0 4px 4px 0px rgba(0, 0, 0, 0.2),
  //   0 4px 4px 4px rgba(0, 0, 0, 0.08), 0 4px 4px 3px rgba(0, 0, 0, 0.06),
  //   0 4px 4px 3px rgba(0, 0, 0, 0.04);
}

.connectUs_bg_RevenueSharing {
  position: relative;
  z-index: 10;
  margin-bottom: -120px;
  max-width: 470px;
  min-height: 300px;
  padding: 20px 40px 20px 40px;
  background: @brand-white;
  box-shadow: 0 4px 4px 0px rgba(0, 0, 0, 0.2),
    0 4px 4px 4px rgba(0, 0, 0, 0.08), 0 4px 4px 3px rgba(0, 0, 0, 0.06),
    0 4px 4px 3px rgba(0, 0, 0, 0.04);
}

.connectUs_bg_ContactUs {
  // position:relative;
  z-index: 10;
  // margin-bottom: 120px;
  width: 500px;
  min-height: 300px;
  padding: 20px 40px 0px 40px;
  background: @brand-white;
  // box-shadow: 0 4px 4px 0px rgba(0, 0, 0, 0.2),
  //   0 4px 4px 4px rgba(0, 0, 0, 0.08), 0 4px 4px 3px rgba(0, 0, 0, 0.06),
  //   0 4px 4px 3px rgba(0, 0, 0, 0.04);
}

.connectUs_heading {
  font-size: 27px;
  font-weight: 900;
  color: @brand-primary;
  margin-bottom: 6px;
}

.ant-form-vertical .ant-form-item-label {
  padding: 0;
}

.ant-form-item-label>label {
  color: #6c6d6c;
  font-size: 16px;
  font-weight: 600;
}

.ant-input {
  border: 2px solid @brand-primary;
}

.formButton {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: @brand-primary;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  cursor: pointer;
}

.formButton_text {
  font-size: 18px;
  font-weight: 600;
  color: @brand-white;
}

.formButton_text:hover {
  color: #f1f1f1;
  transition: 0.5s;
}

// @media (min-width: 768px) and (max-width: 992px) {
// }

// @media (min-width: 576px) and (max-width: 768px) {
// }

@media screen and (max-width: 576px) {
  .connectUs_bg {
    padding: 15px;
  }

  .connectUs_bg_Licensing {
    position: relative;
    z-index: 10;
    margin-bottom: -120px;
    width: auto;
    min-height: 300px;
    padding: 50px 60px 50px 60px;
    background-image: url("../../Assets/form layout.webp");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    // background: red;
    // box-shadow: 0 4px 4px 0px rgba(0, 0, 0, 0.2),
    //   0 4px 4px 4px rgba(0, 0, 0, 0.08), 0 4px 4px 3px rgba(0, 0, 0, 0.06),
    //   0 4px 4px 3px rgba(0, 0, 0, 0.04);
  }
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;