@brand-primary: #21ce86;
@brand-secondary: #4e7884;
@brand-ternary: #185563;
@brand-quaternary: #029989;
@brand-white: #ffffff;
@min: 1;
@max: 1.75;

.pageSix_card_text {
  text-align: justify;
  padding: 10px 35px 20px 35px;
  font-weight: 500;
  color: #4e7884;
  font-size: 15px
}

.pageSix_bg {
  position: relative;
  background: #eafdf5;
  width: 100%;
  height: auto;
  background-size: auto;
  padding: 24px 24px 70px;
}

.page_six_heading {
  font-weight: 900;
  font-size: 42px;
  margin-top: 5px;
}

.pageSix_hBar_wrapper {
  padding: 0 15px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 10px;
  margin-bottom: 200px;
}

.pageSix_timeline {
  position: relative;
  height: auto;
  margin: 0 auto;
  max-width: 1300px;
  margin-bottom: -40px;
}

.pageSix_timeline::after {
  margin: 140px 0 140px 0;
  content: "";
  position: absolute;
  width: 6px;
  background-color: @brand-primary;
  top: 0;
  bottom: 15px;
  left: 50%;
  // margin: 120px 0;
}

.pageSix_tl_container {
  width: 50%;
  height: auto;
  display: grid;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: inherit;
  padding: 25px 45px;
}

.pageSix_tl_container::after {
  content: "";
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: radial-gradient(circle at center,
      rgba(33, 206, 134, 1) 25%,
      rgba(33, 206, 134, 0.4) 40%,
      rgba(33, 206, 134, 0.2) 60%,
      rgba(33, 206, 134, 0.05) 75%,
      rgba(33, 206, 134, 0.01) 90%,
      rgba(33, 206, 134, 0.001) 100%);
  right: -17.5px;
  animation: zoom-in-zoom-out 3s ease infinite;
  // transform: scale(2);
}

.pageSix_tl_container:nth-child(1)::after {
  // -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.pageSix_tl_container:nth-child(2)::after {
  // -webkit-animation-delay: 2s;
  animation-delay: 2s;
  // background-color: red;
}

.pageSix_tl_container:nth-child(3)::after {
  // -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.pageSix_tl_container:nth-child(4)::after {
  // -webkit-animation-delay: 4s;
  animation-delay: 4s;
}

.pageSix_tl_container:nth-child(5)::after {
  // -webkit-animation-delay: 2s;
  animation-delay: 5s;
}

.pageSix_tl_container:nth-child(6)::after {
  // -webkit-animation-delay: 2s;
  animation-delay: 6s;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(2, 2);
  }

  100% {
    transform: scale(1, 1);
  }
}

.pageSix_tl_container:hover {
  // transform: scale(1.1);
}

.pageSix_card_content {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 450px;
  padding: 24px 24px 7px 24px;
  min-height: 200px;
  // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: @brand-white;
}

.pageSix_card_content_left::after {
  content: "";
  position: absolute;
  display: none;
  right: -10px;
  border: medium solid @brand-primary;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent @brand-primary;
}

.pageSix_tl_container_right {
  right: -50%;
}

.pageSix_card_content_right::before {
  content: "";
  display: none;
  position: absolute;
  left: -10px;
  border: medium solid @brand-primary;
  border-width: 10px 10px 10px 0;
  border-color: transparent @brand-primary;
}

.pageSix_tl_container_right::after {
  left: -12.5px;
}

.page_six_title_one {
  font-size: 24px;
  font-weight: 700;
  color: @brand-primary;
  margin-right: 6px;
}

.page_six_title_two {
  font-size: 24px;
  font-weight: 700;
  color: @brand-ternary;
}

.pageSix_card_sNumber {
  position: absolute;
  font-size: 110px;
  font-weight: 800;
  top: -105px;
  color: #96d9bd;
  margin-left: -10px;
}

.pageSix_img1 {
  max-width: 380px;
  max-height: 250px;
  object-fit: contain;
}

.pageSix_card_img1 {
  position: absolute;
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
  top: -249px;
  left: 160px;
}

.pageSix_img2 {
  max-width: 330px;
  max-height: 260px;
  object-fit: contain;
}

.pageSix_card_img2 {
  position: absolute;
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
  top: -235px;
  right: -30px;
}

.pageSix_img3 {
  max-width: 330px;
  max-height: 260px;
  object-fit: contain;
}

.pageSix_card_img3 {
  position: absolute;
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
  top: -245px;
  right: -15px;
}

.pageSix_img4 {
  max-width: 330px;
  max-height: 260px;
  object-fit: contain;
}

.pageSix_card_img4 {
  position: absolute;
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
  top: -245px;
  right: -15px;
}

.pageSix_img5 {
  max-width: 330px;
  max-height: 260px;
  object-fit: contain;
}

.pageSix_card_img5 {
  position: absolute;
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
  top: -255px;
  right: -15px;
}

.pageSix_img6 {
  max-width: 330px;
  max-height: 260px;
  object-fit: contain;
}

.pageSix_card_img6 {
  position: absolute;
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
  top: -235px;
  right: -5px;
}

.pageSix_hBar {
  width: 430px;
  height: 5px;
  background-color: #9fe1c6;
  margin-top: -14px;
  margin-bottom: 46px;
}

.page_six_hEl {
  width: 50px;
  height: 5px;
  position: relative;
  background-color: @brand-primary;
  animation: pageSix 2s infinite alternate linear;
}

@keyframes pageSix {
  0% {
    left: 0;
  }

  25% {
    left: 95px;
  }

  50% {
    left: 190px;
  }

  75% {
    left: 285px;
  }

  100% {
    left: 380px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .pageSix_timeline::after {
    margin: 150px 0 150px 0;
  }
}

@media (min-width: 568px) and (max-width: 992px) {
  .pageSix_timeline::after {
    margin: 195px 0 195px 0;
  }

  .pageSix_card_img1 {
    align-self: center;
    justify-content: center;
    top: -260px;
    right: 0;
  }

  .pageSix_img1 {
    max-width: 340px;
    max-height: 300px;
  }

  .pageSix_card_img2 {
    align-self: center;
    justify-content: center;
    top: -192px;
    right: 0;
  }

  .pageSix_img2 {
    max-width: 300px;
    max-height: 300px;
  }

  .pageSix_card_img3 {
    top: -160px;
    right: 0;
  }

  .pageSix_img3 {
    align-self: center;
    justify-content: center;
    max-width: 300px;
    max-height: 300px;
  }
}

@media screen and (max-width: 992px) {
  .pageSix_timeline::after {
    margin: 220px 0 220px 0;
    left: 10px;
  }

  .pageSix_tl_container {
    width: 100%;
    padding: 100px 0 100px 45px;
  }

  .pageSix_tl_container::after {
    left: -2px;
  }

  .pageSix_card_content_left::after {
    content: "";
    display: none;
    position: absolute;
    // display: flex;
    justify-content: center;
    align-items: center;
    left: -10px;
    right: auto;
    border: medium solid @brand-primary;
    border-width: 10px 10px 10px 0px;
    border-color: transparent @brand-primary;
  }

  .pageSix_tl_container_right {
    left: 0;
  }

  // .pageSix_hBar_wrapper {
  //   margin-bottom: 200px;
  // }
}

@media screen and (max-width: 576px) {

  .pageSix_card_sNumber {
    font-size: 80px;
    font-weight: 800;
    top: -75px;
    color: #96d9bd;
    margin-left: -20px;
  }

  .pageSix_hBar_wrapper {
    margin-bottom: 75px;
  }

  .page_six_title_one {
    font-size: 18px;
  }

  .page_six_title_two {
    font-size: 18px;
  }

  .pageSix_card_text {
    padding: 10px 0px 20px 0px;
    font-size: 13.5px;
    hyphens: auto;
    font-weight: 600;
  }

  .pageSix_hBar {
    width: 270px;
    height: 3px;
    margin-top: -13px;
  }

  .page_six_hEl {
    height: 3px;
  }

  .page_six_heading {
    font-size: 27px;
  }

  .pageSix_timeline::after {
    margin: 195px 0 190px 0;
  }

  .pageSix_img1 {
    max-width: 180px;
    max-height: 150px;
    object-fit: contain;
  }

  .pageSix_card_img1 {
    position: absolute;
    display: flex;
    align-self: flex-end;
    justify-content: flex-end;
    top: -150px;
    right: 5px;
  }

  .pageSix_img2 {
    max-width: 180px;
    max-height: 150px;
    object-fit: contain;
  }

  .pageSix_card_img2 {
    position: absolute;
    display: flex;
    align-self: flex-end;
    justify-content: flex-end;
    top: -135px;
    right: 5px;
  }

  .pageSix_img3 {
    max-width: 180px;
    max-height: 150px;
    object-fit: contain;
  }

  .pageSix_card_img3 {
    position: absolute;
    display: flex;
    align-self: flex-end;
    justify-content: flex-end;
    top: -140px;
    right: 5px;
  }

  .pageSix_img4 {
    max-width: 180px;
    max-height: 150px;
    object-fit: contain;
  }

  .pageSix_card_img4 {
    position: absolute;
    display: flex;
    align-self: flex-end;
    justify-content: flex-end;
    top: -135px;
    right: 5px;
  }

  .pageSix_img5 {
    max-width: 180px;
    max-height: 150px;
    object-fit: contain;
  }

  .pageSix_card_img5 {
    position: absolute;
    display: flex;
    align-self: flex-end;
    justify-content: flex-end;
    top: -148px;
    right: 5px;
  }

  .pageSix_img6 {
    max-width: 180px;
    max-height: 150px;
    object-fit: contain;
  }

  .pageSix_card_img6 {
    position: absolute;
    display: flex;
    align-self: flex-end;
    justify-content: flex-end;
    top: -130px;
    right: 5px;
  }

  .pageSix_tl_container {
    width: 100%;
    padding: 60px 0 80px 45px;
  }

  .pageSix_bg {
    padding-bottom: 0px;
    margin-bottom: -20px;
  }

  @keyframes pageSix {
    0% {
      left: 0px;
    }

    25% {
      left: 55px;
    }

    50% {
      left: 110px;
    }

    75% {
      left: 165px;
    }

    100% {
      left: 220px;
    }
  }

  @keyframes zoom-in-zoom-out {
    0% {
      transform: scale(@min, @min);
    }

    50% {
      transform: scale(@max, @max);
    }

    100% {
      transform: scale(@min, @min);
    }
  }
}

@media screen and (max-width: 440px) {
  .pageSix_timeline::after {
    // margin: 200px 0 245px 0;
  }
}

@media screen and (max-width: 390px) {
  .pageSix_timeline::after {
    // margin: 250px 0 270px 0;
  }
}
@primary-color: #21ce86;@success-color: #21ce86;@heading-color: #4e7884;@text-color: #4e7884;@text-color-secondary: red;@border-color-base: #21ce86;